import React from "react"
import { Container, Row, Col } from "reactstrap"
import blz_logo from "../../assets/images/blaze_white_logo.png";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © DeriSnap.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
              Developed @ <a target="_blank" rel="noreferrer" href="https://blaze.ws"><img src={blz_logo} style={{width:'13px'}} ></img></a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
