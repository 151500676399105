
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Form,
  } from "reactstrap";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//import { Badge } from "react-bootstrap";
import api from '../../services/api.service';
import Switch from "react-switch";
import { formatUTCDate, CustomParseFloat } from "../../services/common.service";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

const UserList = () => {
    firebase.auth();
    const { SearchBar } = Search;

    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    const [deletemodal, setDeletemodal] = useState(false);
    const [IsLoadingDelete, setIsLoadingDelete] = useState(false);
    const [getAllUsers, setGetAllUsers] = useState();
    const [form1_loader, setForm1Loader] = useState({ loading: false });
    const [user_id,setuser_id] = useState();
    // const [nextPage, setNextPage] = useState(1);
    // const [rowPerPage, setRowPerPage] = useState(10);

    const defaultSorted = [
        {
            dataField: "email",
            order: "asc",
        },
    ];
    const emptyDataMessage = () => { return form1_loader.loading ? 'Loading...' : 'No Data Found' }
    const get_users = async () => {
        setForm1Loader({
            loading: true,
        });
        await api({ contentType: true, auth: true }).get('/abotex/user/all-users').then((res) => {
            setGetAllUsers(res.data.response.users);
            setForm1Loader({
                loading: false,
            });
        }).catch(err => {
            setForm1Loader({
                loading: false,
            });
            throw err;
        });
    }

    const handleSwitchChange = async (status, updateid) => {

        let send_data = {
            uid: updateid,
            data: {status: status == 'active' ? 'inactive' : 'active'}
        }
                
        // var firestoreDb1 = firebase.firestore().collection("users").doc(updateid);
        // var docRef = firestoreDb1.collection("profile").doc('data')
        //     .update({
        //         status: status == 'active' ? 'inactive' : 'active'
        //     })
        await api({ contentType: true, auth: true }).post('/abotex/user/update-user',send_data)
        .then(async () => {
                await api({ contentType: true, auth: true }).get('/abotex/user/all-users').then((res) => {
                    setGetAllUsers(res.data.response.users);
                    toast.success('Status Updated Successully');
                }).catch(err => {
                    toast.error('Status Not Updated');
                    throw err;
                });
            })
            .catch((e) => {
                toast.error('Status Not Updated');
            })

    }


    const handleTradeStatusChange = async (status, updateid) => {
        // var firestoreDb1 = firebase.firestore().collection("users").doc(updateid);
        // var docRef = firestoreDb1.collection("profile").doc('data')
        //     .update({
        //         trade_status: status == 1 ? 0 : 1
        //     })
        let send_data = {
            uid: updateid,
            data: {trade_status: status == 1 ? 0 : 1}
        }
        await api({ contentType: true, auth: true }).post('/abotex/user/update-user',send_data)
            .then(async () => {
                await api({ contentType: true, auth: true }).get('/abotex/user/all-users').then((res) => {
                    setGetAllUsers(res.data.response.users);
                    toast.success('Trade Status Updated Successully');
                }).catch(err => {
                    toast.error('Trade Status Not Updated');
                    throw err;
                });
            })
            .catch((e) => {
                toast.error('Trade Status Not Updated');
            })

    }

    const sendMsg = async (user_id) => {
        const { value: text } = await Swal.fire({
            input: 'textarea',
            title:'Message',            
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,            
            inputPlaceholder: 'Type your message here...',
            inputAttributes: {
                'aria-label': 'Type your message here'
            },            
            showCancelButton: true
        })

        if (text) {
            const bodyparams = {
                user_id: user_id,
                msg: text
            }
            await api({ contentType: true, auth: true }).post('/abotex/user/send-user-msg', bodyparams).then((res) => {
                toast.success('Message sent successfully');
            }).catch(err => {
                toast.error('Chat Not Found');
                throw err;
            });
        }


    }

    const loginAsUser = async (uid, name = "user") => {

        try {
            if (uid) {
                await api({ contentType: true, auth: true }).get('/abotex/auth-token/' + uid).then((res) => {
                    //console.log(res);
                    if (res && res.data && res.data.response && res.data.response.customToken) {
                        window.open(process.env.REACT_APP_FRONTEND_URL + "/admin-session?custom-token=" + btoa(res.data.response.customToken) + "&name=" + name, '_blank');
                    } else {
                        throw Error('Unable to login as user now, Please try again');
                    }
                }).catch(err => {
                    throw err;
                });
            }
        } catch (e) {
            toast.error('Unable to login as user now, Please try again');
            //console.log(e);
        }

    }


    const DeleteUser = async (uid) => {
        try {
            if (uid) {
                await api({ contentType: true, auth: true }).post('/abotex/user/delete-user-alldata',{user_id:uid}).then((res) => {
                    
                    if (res.status == 200) {
                        if (res.data.response.message === 'Deleted successfully'){
                        setIsLoadingDelete(false)
                        setDeletemodal(false);
                        toast.success('User Deleted Successfully');
                        get_users();
                        }else{
                            setIsLoadingDelete(false)
                            setDeletemodal(false);
                            toast.error(res.data.response.message);
                            get_users();
                        }
                    } else {
                        throw Error('Unable to delete user now, Please try again');
                    }
                }).catch(err => {
                    throw err;
                });
            }
        } catch (e) {
            toast.error('Unable to delete user now, Please try again');
            //console.log(e);
        }
    }
    useEffect(() => {
        get_users()

    }, []);

    const columns = [
        {
            dataField: 'first_name',
            text: 'Name', sort: true, headerStyle: { width: "10%" },
            formatter: (value, row) =><><Link to={"/user-transaction?id=" + btoa(row.uid)} style={{ 'textDecoration': 'none' }}>{
                ((row.first_name) ? row.first_name.charAt(0).toUpperCase() + row.first_name.slice(1) + " " : "") + ((row.last_name) ? row.last_name : "")
            }</Link><div className="font-size-10"><b>UID </b> : {row.uid}</div></>
        },
        { dataField: 'email', text: 'Email', sort: true, headerStyle: { width: "10%" } },
        {
            dataField: 'role', text: 'Role', sort: true, headerStyle: { width: "10%" },
            formatter: (value, row) => row.role == 'ADMIN' ? <span style={{fontWeight: 'bold' }} className="text-warning">{row.role}</span> : row.role
        },
        {
            dataField: 'status', text: 'Status', sort: false, headerStyle: { width: "10%" },
            formatter: (value, row) => <Switch onChange={() => handleSwitchChange(row.status, row.uid)} checked={row.status == 'active' ? true : false} disabled={row.role == 'ADMIN' ? true : false} />
        },
        {
            dataField: 'balance.stray_positions', text: <><text style={{position:"relative",top:"10px"}}>Stray Positions</text><br/><small style={{ fontSize:"8px",position:"relative",top:"10px" }}>Delta exchange</small></>, sort: false, headerStyle: { width: "10%" },
            formatter: (value, row) => row && row.stray_positions ? <span style={{ 'color': 'red', fontWeight: 'bold' }}>{row.stray_positions}</span> : '-'
        },
        {
            dataField: 'row.balance.usdt.balance', text: <><text style={{position:"relative",top:"10px"}}>Margin (USDT)</text><br/><small style={{ fontSize:"8px",position:"relative",top:"10px" }}>Delta exchange</small></>, sort: false, headerStyle: { width: "10%" },
            formatter: (value, row) => row.balance && row.balance.usdt.balance ? <span className="text-primary" title={row.balance.usdt.balance}>Total Balance: {CustomParseFloat(row.balance.usdt.balance, 2)} <br /><span className="text-success" title={row.balance.usdt.available_balance}>Avbl Margin: {CustomParseFloat(row.balance.usdt.available_balance, 2)}</span> </span> : '-'
        },
        {
            dataField: 'row.admin_diagonal_quantity', text: <><text style={{position:"relative",top:"10px"}}>Preferred Qty</text><br/><small style={{ fontSize:"8px",position:"relative",top:"10px" }}>Delta exchange</small></>, sort: false, headerStyle: { width: "10%" },
           // formatter: (value, row) => row.admin_diagonal_quantity || row.admin_strangle_quantity || row.trend_following_qty ? <span  className="text-info" >Strangle:&nbsp;{row.admin_strangle_quantity ? row.admin_strangle_quantity : '-'}<br /><span  className="text-info" >Diagonal:&nbsp;{row.admin_diagonal_quantity ? row.admin_diagonal_quantity : '-'}</span> <br /><span  className="text-info" >Trend following:&nbsp;{row.trend_following_qty ? row.trend_following_qty : '-'}</span></span> : '-'
           formatter: (value, row) => row.admin_diagonal_quantity || row.admin_strangle_quantity|| row.admin_trend_following_qty ? <span  className="text-info" >Strangle:&nbsp;{row.admin_strangle_quantity ? row.admin_strangle_quantity : '-'}<br /><span  className="text-info" >Diagonal:&nbsp;{row.admin_diagonal_quantity ? row.admin_diagonal_quantity : '-'}</span><br /><span  className="text-info" >Trend following:&nbsp;{row.admin_trend_following_qty ? row.admin_trend_following_qty : '-'}</span></span> : '-'
        },
        {
            dataField: 'created_at', text: 'Created Date', sort: false, headerStyle: { width: "10%" },
            formatter: (value, row) => formatUTCDate(row.created_at)
        },
        {
            dataField: 'trade_status', text: 'Trade Status', sort: false, headerStyle: { width: "10%" },
            formatter: (value, row) => <Switch onChange={() => handleTradeStatusChange(row.trade_status, row.uid)} checked={row.trade_status == 1 ? true : false} disabled={row.role == 'ADMIN' ? true : false} />
        },
        {
            dataField: 'menu',isDummyField: true, text: 'Action', sort: false, headerStyle: { width: "10%" },
            formatter: (value, row) => { return (
              <div className="d-flex gap-3">
              <Link className="text-success" to="#">
               <i className="mdi mdi-account-key font-size-18" size="sm"  title={"Login as "+(row.first_name? row.first_name : "user")} onClick={() => loginAsUser(row.uid,(row.first_name? row.first_name : "user"))} ></i></Link> {row.role == 'USER' ? <Link className="text-info" to="#"><i className="mdi mdi-telegram font-size-18" onClick={() => sendMsg(row.uid)} hidden={row.telegram_updated == 1 ? false : true} size="xxl" style={{ cursor: "pointer" }} ></i>
              </Link> : ''}            
              </div>
            ); }
        },
        {
            dataField: 'menu',isDummyField: true, text: 'Delete', sort: true, headerStyle: { width: "10%" },
            formatter: (value, row) => { return (
              <div className="d-flex gap-3">
              <Link className="text-danger" to="#">
               <i className="mdi mdi-delete font-size-18" size="sm"  title={"Delete User"} onClick={() => {setDeletemodal(true),setuser_id(row.uid)} } ></i>
               </Link>
               {/* </Link> {row.role == 'USER' ? <Link className="text-info" to="#"><i className="mdi mdi-telegram font-size-18" onClick={() => sendMsg(row.uid)} hidden={row.telegram_updated == 1 ? false : true} size="xxl" style={{ cursor: "pointer" }} ></i>
              </Link> : ''}             */}
              </div>
            ); }
        },
    ];


    const data = getAllUsers;
    const options = {
        page: 1,
        sizePerPage: 50,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        hideSizePerPage: true,
        custom: true
    };

    return (

        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="DeriSnap" breadcrumbItem="Users" />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {getAllUsers && getAllUsers.length > 0 ? (
                                        <PaginationProvider
                                            pagination={paginationFactory(options)}
                                            keyField="uid"
                                            columns={columns}
                                            data={data}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="uid"
                                                    columns={columns}
                                                    data={data}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            responsive
                                                                            defaultSorted={defaultSorted}
                                                                            bordered={true}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-check"
                                                                            }
                                                                            headerWrapperClasses={"table-light"}
                                                                            noDataIndication={emptyDataMessage}
                                                                        />

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto pagination pagination-rounded justify-content-end">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    ) : <Row>
                                    <Col xs="12">
                                      <div className="text-center my-3">
                                        <Link to="#" className="text-success">
                                          <i className="bx bx-hourglass bx-spin me-2" />
                                          Loading
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
      <Modal isOpen={deletemodal} toggle={() => { setDeletemodal(false),setIsLoadingDelete(false) }} centered>
        <ModalHeader toggle={() => { setDeletemodal(false),setIsLoadingDelete(false) }} tag="h4" className="text-capitalize">
          {"Delete User"}
        </ModalHeader>
        <ModalBody>
            <p className="text-white">Are You Sure Do you Want to Delete User?</p>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              DeleteUser(user_id);
              return false;
            }}
          >
            <Row>
              <Col className="col-12">

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    onClick={() => {setIsLoadingDelete(true) }}
                    className={IsLoadingDelete ? "btn btn-danger save-user disabled" : "btn btn-danger save-user"}
                  >
                    {IsLoadingDelete ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Delete'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
        </React.Fragment>

    );

}

export default UserList;