import React from "react";
import { Redirect } from "react-router-dom";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import NotFound from "../pages/NotFound/index";
import CreateStrangle from "../pages/AdminStrangles/CreateStrangle/index";
import ActiveStrangles from "../pages/AdminStrangles/ActiveStrangles/index";
import CompletedStrangles from "../pages/AdminStrangles/CompletedStrangles/index";
import Transaction from "../pages/Transaction/index";
import Settings from "../pages/Settings/Settings";
import Users from "../pages/Users/index";
import UserTransaction from "../pages/UserTransaction/index";
import Trendview from "../pages/Trendview/index";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import DeltaSetup from "pages/Authentication/DeltaSetup";
import CreateDiagonal from "pages/AdminDiagonals/CreateDiagonal";
import ActiveDiagonals from "pages/AdminDiagonals/ActiveDiagonals";
import CompletedDiagonals from "pages/AdminDiagonals/CompletedDiagonals";
import CreateStraddle from "pages/AdminStraddles/CreateStraddle";
import ActiveStraddles from "pages/AdminStraddles/ActiveStraddles";
import CompletedStraddles from "pages/AdminStraddles/CompletedStraddles";
//import Diagonals from "pages/Diagonals";

const authProtectedRoutes = [
  { path: "/delta-setup", component: DeltaSetup, isNonAuthLayout: true },
  { path: "/dashboard", component: Dashboard },
  { path: "/diagonals/create", component: CreateDiagonal },
  { path: "/diagonals/active", component: ActiveDiagonals },
  { path: "/diagonals/completed", component: CompletedDiagonals },
  { path: "/straddles/create", component: CreateStraddle },
  { path: "/straddles/active", component: ActiveStraddles },
  { path: "/straddles/completed", component: CompletedStraddles },
  { path: "/trendview", component: Trendview },
  //profile
  { path: "/profile", component: UserProfile },
  { path: "/strangles/create", component: CreateStrangle },
  { path: "/strangles/active", component: ActiveStrangles },
  { path: "/strangles/completed", component: CompletedStrangles },
  { path: "/transaction", component: Transaction },
  { path: "/settings", component: Settings },
  { path: "/users", component: Users },
  { path: "/user-transaction", component: UserTransaction },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },  
  { path: "*", component: NotFound, isNonAuthLayout: true },  
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register }  
];

export { authProtectedRoutes, publicRoutes };