import React, { useCallback, useEffect, useState } from "react"

import {
  Alert,
  Card,
  CardBody,  
  CardTitle,
  Col,
  Container,
  Form,  
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,  
  Row,  
  TabContent,
  TabPane,
  Table,  
  UncontrolledTooltip
} from "reactstrap"
// import ToolTipData from '../../Settings/Settings.json'
const { default: axios } = require("axios");
import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getDiagonalCommonStrikes, getDiagonalExpiryDates, startBot, getUsersList } from "helpers/diagonal_helper"
import { formatUTCDate, CustomParseFloat, getCalendarTypeName, getFirstPositionName, getFillTypeName, capitalizeFirst } from "services/common.service"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Select from "react-select"
import api from 'helpers/api_helper';
const CreateDiagonal = () => {  
  
  firebase.auth();
   //tooltip
   const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
   const [ToolTipData, setToolTipData] = useState([]);
   useEffect(()=>{
     try{
       const gettoottipData = async () => {
       
           const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
           // console.log(response)
             setToolTipData(JSON.parse(JSON.stringify(response.data)))
         
         }
 
         gettoottipData()
       }catch(error){
           console.log(error)
       }
 
   },[apiUrl])
   //tooltip
  //Current User  
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  //meta title
  // document.title="Create Diagonal";
  const firestoreDb = firebase.firestore();
  const [pageLoader, setPageLoader] = useState(false);
  const [formLoader, setformLoader] = useState(false);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [startBotParams, setStartBotParams] = useState({option: 'calls'});  

  const [isDiagonalbotEnabled, setIsDiagonalbotEnabled] = useState(false);
  const [diagonalExpiryDates, setDiagonalExpiryDates] = useState([]);
  const [longExpiryDates, setLongExpiryDates] = useState([]);
  const [shortExpiryDates, setShortExpiryDates] = useState([]);
  const [selectedLongExpiry, setselectedLongExpiry] = useState('');

  const firestoreDbUser = firestoreDb.collection("users").doc(uid);
  
  //Get Expiry dates list and set in options
  const getExpiryDates = async () => {
    try {
      const bodyParams = {
        currency: 'BTC'      
      };
      await getDiagonalExpiryDates(bodyParams).then((res) => {
        setDiagonalExpiryDates(res.data.response.expiry_dates);
        setShortExpiryDates(res.data.response.expiry_dates.slice(0, -1));
        let longExpiryDates = res.data.response.expiry_dates.slice(1)
        setLongExpiryDates(longExpiryDates);
        setselectedLongExpiry(longExpiryDates[0]?longExpiryDates[0]:'')
      }).catch(e => {
        throw e
      })      
    } catch (e) {
      handleError(e);
    }
  }  
  
  //Set long expiry date options based on short expiry selection
  const setLongExpiryDatesFunc = async (key_value) => {
    const selected_date = diagonalExpiryDates.findIndex(m => m == key_value.target.value);
    setLongExpiryDates(diagonalExpiryDates.slice(selected_date + 1))
  }  

  //Get Site Settings
  const getSiteSettings = async () => {
    setPageLoader(true);
    await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
      if (res && res.data && res.data.response && res.data.response.settings) {
        setIsDiagonalbotEnabled(res.data.response.settings.enable_diagonal);
      }
    });
    setPageLoader(false);
    // var site_settings = firestoreDb.collection("site-settings").doc('data');
    // await site_settings.get().then((res) => {
    //   if (res.exists) {
    //     const site_setting = res.data();
    //     setIsDiagonalbotEnabled(site_setting.enable_diagonal);        
    //   }
    //   setPageLoader(false);
    // });
  }

  //Get Users List
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectionType, setSelectionType] = useState('all-users');
  const [failedUserDetails, setFailedUserDetails] = useState([]);
  const getAllUsersList = async()=>{  
    try {      
      const res = await getUsersList();      
      if (res.data.response.users_list) {  
        const usersListResponse = res.data.response.users_list;
        const result = usersListResponse.map(item => {
          return {
            label: item.display_text?capitalizeFirst(item.display_text):"",
            value: item.uid
          }
        })        
        setUsersList(result);
        setSelectedUsers(result);        
      } 
      
    } catch (e) {
      handleError(e);
    }    
  }

  const handleSelectionType = (e) => {
    if (e.target.value === 'all-users') {
      setSelectedUsers(usersList);
    } else {
      setSelectedUsers([])
    }
    setSelectionType(e.target.value);
  }
  
  const handleSelect = (selectedList, selectedItem) => {
    setSelectedUsers(selectedList);
  }  

  //Choose Expiry & Get Strikes 
  const [activeOption, setActiveOption] = useState("1"); // Tabs
  const [commonStrikes, setCommonStrikes] = React.useState('');
  const [filteredCommonStrikes, setFilteredCommonStrikes] = React.useState('');
  const processStep1 = async() => {
    try {
      setformLoader(true);
      setCommonStrikes("");
      setFilteredCommonStrikes("");
      
      if (!selectedUsers || !selectedUsers.length) {        
        toast.error('Please select users', { toastId: 'diagonal_step1_select_user_error' });
        setformLoader(false);
        return false;
      }
      
      //Selected users
      let user_selection_type = selectionType;
      let selected_user_uids;
      if(user_selection_type === 'selected-users') {
        let selected_user_uids_arr = selectedUsers.map(selected_user => selected_user.value);
        if (selected_user_uids_arr.length) {
          selected_user_uids = selected_user_uids_arr.join(",")
        }          
      }

      const short_expiry = document.getElementById('short_expiry').value;
      const long_expiry = document.getElementById('long_expiry').value;
      if (short_expiry === long_expiry) {
        toast.error('Short expiry and Long expiry should not be same', { toastId: 'diagonal_step1_1' });      
        setformLoader(false);
        return false;
      }
  
      if (short_expiry > long_expiry) {
        toast.error('Short expiry should be lower than long expiry', { toastId: 'diagonal_step1_2' });
        setformLoader(false);
        return false;
      }      
      
      const bodyParams = {
        short_expiry: short_expiry,
        long_expiry: long_expiry
      };
  
      await getDiagonalCommonStrikes(bodyParams).then(res => {
        setformLoader(false);
        setActiveOption("1");
        setStartBotParams({ ...startBotParams, 'user_selection_type': user_selection_type,
          'selected_user_uids': selected_user_uids, 'short_expiry': short_expiry, 'long_expiry': long_expiry, option: 'calls', option_index: undefined });
        setCommonStrikes(res.data.response.common_strikes);
        setFilteredCommonStrikes(res.data.response.common_strikes);                
      }).catch(err => {    
        throw err
      });           
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  const validateStrikeFilterForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      delta_condition: '==',
      markiv_condition: '==',
      price_condition: '==',
      delta_diff: '',
      markiv_diff: '',
      price_diff: ''
    },
    validationSchema: Yup.object().shape({
      delta_diff: Yup.number(),
      markiv_diff: Yup.number(),
      price_diff: Yup.number()
    }),
    onSubmit: async (data) => {
      try{        
        let condition = '';
        if (data.delta_condition && data.delta_diff) {          
          condition += "strike.delta_difference " + data.delta_condition + " " + CustomParseFloat(data.delta_diff,2);           
        }

        if (data.markiv_condition && data.markiv_diff) {
          condition = (condition !== '') ? condition + " && " : '';
          condition += "strike.mark_iv_difference " + data.markiv_condition + " " + parseFloat(data.markiv_diff).toFixed(1); 
        }

        if (data.price_condition && data.price_diff) {
          condition = (condition !== '') ? condition + " && " : '';
          condition += "strike.price_difference " + data.price_condition + " " + parseFloat(data.price_diff).toFixed(1); 
        }  
        
        let filtered_strikes = { ...commonStrikes };  
        if (condition) {
          let filteredStrikesCalls = commonStrikes.calls.filter(strike => eval(condition)); 
          let filteredStrikesPuts = commonStrikes.puts.filter(strike => eval(condition));  
          filtered_strikes.calls = filteredStrikesCalls; 
          filtered_strikes.puts = filteredStrikesPuts; 
        }
        setFilteredCommonStrikes(filtered_strikes);     
      }
      catch(err){
        toast.error('Unable to filter the results. Please try again', { toastId: 'diagonal_step2_filter' });              
      }
    }    
  });

  //Choose a Strike
  const processStep2 = async() => {
    try {
      
      setformLoader(true);
      const option = startBotParams.option;
      const option_index = startBotParams.option_index;
      if (option_index === undefined) {
        setformLoader(false);
        toast.error('Please select a strike in ' + option + ' option', { toastId: 'diagonal_step2_1' });
        return false;
      }

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Default bot setup settings
  const [botSettings, setBotSettings] = useState({
    hours_before_to_market_close: 4,
    take_profit_percentage: 0    
  });

  const formatPercentage = value => value + " %"

  const handleRangeInput = (e, setting_name, isInteger = true) => {    
    if ((e.target.value === null || e.target.value === undefined || e.target.value === '')) {            
      setBotSettings({...botSettings, [setting_name]: '' });
      return false;
    }

    let newvalue = Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
    setBotSettings({...botSettings, [setting_name]: (isInteger)?parseInt(newvalue):newvalue });
  }

  //Bot setup / settings
  const processStep3 = async() => {
    try {      

      setformLoader(true);      
      let calendar_option = document.getElementById('calendar_type');
      let calendar_type = calendar_option.options[calendar_option.selectedIndex].value;
      let expiry_position_option = document.getElementById('expiry_position_to_begin');
      let expiry_position_to_begin = expiry_position_option.options[expiry_position_option.selectedIndex].value;      
      let hours_before_to_market_close = Number(document.getElementById('hours_before_to_market_close').value);
      let take_profit_percentage = Number(document.getElementById('take_profit_percentage').value);      

      if (!calendar_type) {
        toast.error('Please select calendar type', { toastId: 'diagonal_step3_1' });
        setformLoader(false);
        return false;        
      }

      if (!expiry_position_to_begin) {
        toast.error('Please select expiry position to enter at first', { toastId: 'diagonal_step3_2' });
        setformLoader(false);
        return false;        
      }

      if (isNaN(hours_before_to_market_close) || hours_before_to_market_close <= 0) {
        toast.error('Please enter valid hours', { toastId: 'diagonal_step3_4' });
        setformLoader(false);
        return false;        
      }

      if (isNaN(take_profit_percentage) || take_profit_percentage < 0) {
        toast.error('Please enter valid take profit', { toastId: 'diagonal_step3_5' });
        setformLoader(false);
        return false;        
      }       
      
      setStartBotParams({
        ...startBotParams,
        calendar_type: calendar_type,
        expiry_position_to_begin: expiry_position_to_begin,        
        take_profit_percentage: take_profit_percentage,
        hours_before_to_market_close: hours_before_to_market_close        
      });
      
      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Bot setup / settings
  const processStep4 = async() => {
    try {      

      setformLoader(true);      
      const params = {
        user_selection_type: startBotParams.user_selection_type,
        selected_user_uids: startBotParams.selected_user_uids,
        short_expiry: startBotParams.short_expiry,
        long_expiry: startBotParams.long_expiry,
        option: startBotParams.option,
        strike_price: startBotParams.strike_price,
        short_expiry_symbol: startBotParams.short_expiry_symbol, 
        short_expiry_product_id: startBotParams.short_expiry_product_id,
        long_expiry_symbol: startBotParams.long_expiry_symbol,
        long_expiry_product_id: startBotParams.long_expiry_product_id,
        calendar_type: startBotParams.calendar_type,
        expiry_position_to_begin: startBotParams.expiry_position_to_begin,                
        take_profit_percentage: startBotParams.take_profit_percentage,
        hours_before_to_market_close: startBotParams.hours_before_to_market_close        
      };     

      //Calls sell Order
      await startBot(params).then(async (res) => {  
        if (res.data.response.failed_user_details && res.data.response.failed_user_details.length) { 
          setFailedUserDetails(res.data.response.failed_user_details);
        }
        setformLoader(false);
        toast.success(res.data.message);                
      }).catch(err => {
        throw err;
      });
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }
  
  //Handle Form Wizard
  const toggleTab = async (tab) => {
    
    if (!isDiagonalbotEnabled) {
      toast.error('Diagonal bot creation disabled by admin', { toastId: 'diagonal_bot_disabled' });
      return false;
    }

    let modifiedSteps = passedSteps;
    if (activeTab !== tab) {
      if (tab > activeTab) {
        let is_processed = false;            
        switch (activeTab) {          
          case 1:
            //Choose Expiry
            is_processed = await processStep1();            
          break;
          case 2:
            //Choose Strike
            is_processed = await processStep2();
          break;
          case 3:
            //Bot Setup
            is_processed = await processStep3();
          break;
          case 4:
            //Start Bot
            is_processed = await processStep4();
          break;        
        }

        if(activeTab != 2) { validateStrikeFilterForm.resetForm({ values:{}}) }
                
        const index = modifiedSteps.indexOf(tab);
        if (index > -1) {
          modifiedSteps = modifiedSteps.slice(0, index);
        }

        if (is_processed) {
          modifiedSteps.push(tab);
          if (tab >= 1 && tab <= 5) {
            setactiveTab(tab)
            setPassedSteps(modifiedSteps)
            window.scrollTo(0, 0);
          }
        } else {
          setPassedSteps(modifiedSteps)
        }
      } else {        
        if (tab >= 1 && tab <= 5) {
          setactiveTab(tab)
          window.scrollTo(0, 0);
        }
      }          
    }
  }

  useEffect(() => {
    getSiteSettings();
    getExpiryDates();
    getAllUsersList();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Create Diagonal" />
          {pageLoader ?
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin me-2" />
                        Loading
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            :
            <>
              {isDiagonalbotEnabled ?
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Create Diagonal Bot</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> Choose Expiry
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span> Choose Strike
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">3.</span> Bot Setup
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">4.</span> Bot Summary
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 5 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 5 })}
                              onClick={() => {
                                setactiveTab(5)
                              }}
                              disabled={!(passedSteps || []).includes(5)}
                            >
                              <span className="number">5.</span> Bot Success
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                            <div className="p-4 border">
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label htmlFor="users_selection_type">Users</Label>{" "}<i className="bx bx-info-circle" id ="user" />
                                        <UncontrolledTooltip placement="top" target="user"> 
                                            {ToolTipData.users}
                                        </UncontrolledTooltip>
                                      <select id="users_selection_type" className="form-select" onChange={(e) => handleSelectionType(e)} value={selectionType} >
                                        <React.Fragment key='all-users'><option value='all-users'> All Users </option>
                                        </React.Fragment>
                                        <React.Fragment key='selected-users'>
                                          <option value='selected-users'> Specific Users </option>
                                        </React.Fragment>
                                      </select>
                                    </div>
                                  </Col>
                                  {selectionType === 'selected-users' ?
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label>Select users</Label>
                                        <Select id="selected_users" value={selectedUsers} isMulti={true} onChange={handleSelect} options={usersList} classNamePrefix="select2-selection"></Select>
                                      </div>
                                    </Col>
                                    : ""
                                  }
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label htmlFor="short_expiry">Short Expiry</Label>{" "}<i className="bx bx-info-circle" id ="Short_Expiry" />
                                        <UncontrolledTooltip placement="top" target="Short_Expiry"> 
                                            {ToolTipData.short_expiry}
                                        </UncontrolledTooltip>
                                      <select id="short_expiry" className="form-select" onChange={(e) => setLongExpiryDatesFunc(e)} >
                                        {shortExpiryDates.map((index) => (
                                          <React.Fragment key={index}>
                                            <option value={index} > {formatUTCDate(index)} </option>
                                          </React.Fragment>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label>Long Expiry</Label>{" "}<i className="bx bx-info-circle" id ="Long_Expiry" />
                                        <UncontrolledTooltip placement="top" target="Long_Expiry"> 
                                            {ToolTipData.long_expiry}
                                        </UncontrolledTooltip>
                                      <select id="long_expiry" className="form-select" value={selectedLongExpiry} onChange={e => setselectedLongExpiry(e.target.value)} >
                                        {longExpiryDates.map((index) => (
                                          <React.Fragment key={index}>
                                            <option value={index} > {formatUTCDate(index)} </option>
                                          </React.Fragment>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                          <TabPane tabId={2}>
                            {filteredCommonStrikes ?
                              <>
                                <div className="p-4 border">
                                  <Form className="row gy-2 gx-3 align-items-center" onSubmit={(e) => {
                                    e.preventDefault();
                                    validateStrikeFilterForm.handleSubmit();
                                    return false;
                                  }}>
                                    <div className="col-lg-3">
                                      <Row>
                                        <Col md={4}>
                                          <div className="mb-3">
                                            <Label htmlFor="delta_condition">Delta</Label>{" "}<i className="bx bx-info-circle" id ="Delta" />
                                            <UncontrolledTooltip placement="top" target="Delta"> 
                                                {ToolTipData.delta}
                                            </UncontrolledTooltip>
                                            <select name="delta_condition" defaultValue="==" className="form-select" onChange={validateStrikeFilterForm.handleChange} onBlur={validateStrikeFilterForm.handleBlur}>
                                              <option value="==">{'='}</option>
                                              <option value=">">{'>'}</option>
                                              <option value="<">{'<'}</option>
                                            </select>
                                          </div>
                                        </Col>
                                        <Col md={8}>
                                          <div className="mb-3">
                                            <Label className="invisible" htmlFor="delta_diff">Delta Value</Label>
                                            <Input
                                              type="text"
                                              name="delta_diff"
                                              className="form-control"
                                              id="delta_diff"
                                              placeholder="Delta"
                                              onChange={validateStrikeFilterForm.handleChange}
                                              onBlur={validateStrikeFilterForm.handleBlur}
                                              value={validateStrikeFilterForm.values.delta_diff || ""}
                                              invalid={
                                                validateStrikeFilterForm.touched.delta_diff && validateStrikeFilterForm.errors.delta_diff ? true : false
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                          
                                    <div className="col-lg-3">
                                      <Row>
                                        <Col md={4}>
                                          <div className="mb-3">
                                            <Label htmlFor="markiv_condition">Mark IV</Label>{" "}<i className="bx bx-info-circle" id ="Mark_IV" />
                                            <UncontrolledTooltip placement="top" target="Mark_IV"> 
                                                {ToolTipData.mark_iv}
                                            </UncontrolledTooltip>
                                            <select name="markiv_condition" defaultValue="==" className="form-select" onChange={validateStrikeFilterForm.handleChange} onBlur={validateStrikeFilterForm.handleBlur}>
                                              <option value="==">{'='}</option>
                                              <option value=">">{'>'}</option>
                                              <option value="<">{'<'}</option>
                                            </select>
                                          </div>
                                        </Col>
                                        <Col md={8}>
                                          <div className="mb-3">
                                            <Label className="invisible" htmlFor="markiv_diff">Delta Value</Label>
                                            <Input
                                              type="text"
                                              name="markiv_diff"
                                              className="form-control"
                                              id="markiv_diff"
                                              placeholder="Mark IV"
                                              onChange={validateStrikeFilterForm.handleChange}
                                              onBlur={validateStrikeFilterForm.handleBlur}
                                              value={validateStrikeFilterForm.values.markiv_diff || ""}
                                              invalid={
                                                validateStrikeFilterForm.touched.markiv_diff && validateStrikeFilterForm.errors.markiv_diff ? true : false
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                          
                                    <div className="col-lg-3">
                                      <Row>
                                        <Col md={4}>
                                          <div className="mb-3">
                                            <Label htmlFor="price_condition">Price</Label>{" "}<i className="bx bx-info-circle" id ="price" />
                                              <UncontrolledTooltip placement="top" target="price"> 
                                                  {ToolTipData.price}
                                              </UncontrolledTooltip>
                                            <select name="price_condition" defaultValue="==" className="form-select" onChange={validateStrikeFilterForm.handleChange} onBlur={validateStrikeFilterForm.handleBlur}>
                                              <option value="==">{'='}</option>
                                              <option value=">">{'>'}</option>
                                              <option value="<">{'<'}</option>
                                            </select>
                                          </div>
                                        </Col>
                                        <Col md={8}>
                                          <div className="mb-3">
                                            <Label className="invisible" htmlFor="price_diff">Delta Value</Label>
                                            <Input
                                              type="text"
                                              name="price_diff"
                                              className="form-control"
                                              id="price_diff"
                                              placeholder="Price"
                                              onChange={validateStrikeFilterForm.handleChange}
                                              onBlur={validateStrikeFilterForm.handleBlur}
                                              value={validateStrikeFilterForm.values.price_diff || ""}
                                              invalid={
                                                validateStrikeFilterForm.touched.price_diff && validateStrikeFilterForm.errors.price_diff ? true : false
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>

                                    <div className="col-lg-3 mt-3">
                              
                                      <button type="submit" className="btn btn-outline-secondary w-lg m-2"><i className="ml-2 bx bx-filter font-size-16 align-middle me-2"></i>Filter</button>
                              
                                      <button type="buttton" className="btn btn-outline-danger w-lg m-2" onClick={() => validateStrikeFilterForm.resetForm({ values: validateStrikeFilterForm.initialValues })}><i className="ml-2 bx bx-reset font-size-16 align-middle me-2"></i>Reset</button>
                          
                                    </div>
                                  </Form>
                                </div>
                                <div className="p-4 border mt-2">
                                  <Nav tabs className="nav-tabs-custom">
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          'active': activeOption === "1"
                                        })}
                                        onClick={() => {
                                          setActiveOption("1"); setStartBotParams({ ...startBotParams, option: 'calls', option_index: undefined })
                                        }}
                                      >
                                        Calls
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          'active': activeOption === "2"
                                        })}
                                        onClick={() => {
                                          setActiveOption("2"); setStartBotParams({ ...startBotParams, option: 'puts', option_index: undefined })
                                        }}
                                      >
                                        Puts
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                  <TabContent activeTab={activeOption} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                      <div className="table-responsive border">
                                        <Table className="table table-sm table-hover align-middle mb-0">
                                          <thead>
                                            <tr className="text-center">
                                              <th></th>
                                              <th className="table-success" colSpan="3">Differences</th>
                                              <th colSpan="4">Short Expiry: <small>{formatUTCDate(filteredCommonStrikes.settings.short_expiry)}</small></th>
                                              <th className="table-success">Common Strikes</th>
                                              <th colSpan="4">Long Expiry:  <small>{formatUTCDate(filteredCommonStrikes.settings.long_expiry)}</small></th>
                                            </tr>
                                            <tr className="text-center">
                                              <th width="3%">#</th>
                                              <th className="table-success" width="5%">Delta</th>
                                              <th className="table-success" width="5%">Mark IV</th>
                                              <th className="table-success" width="5%">Price</th>
                                              <th width="7%">Bid Qty</th>
                                              <th className="text-end" width="7%">Bid (Price / IV)</th>
                                              <th className="text-end" width="7%">Ask (Price / IV)</th>
                                              <th width="7%">Ask Qty</th>
                                              <th className="table-success" width="10%">Strike Price</th>
                                              <th width="7%">Bid Qty</th>
                                              <th className="text-end" width="7%">Bid (Price / IV)</th>
                                              <th className="text-end" width="7%">Ask (Price / IV)</th>
                                              <th width="7%">Ask Qty</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {filteredCommonStrikes.calls && filteredCommonStrikes.calls.length ?
                                              filteredCommonStrikes.calls.map((strike, index, arr) => {
                                                return (
                                                  <tr key={`calls_strike_${index}`} className="text-center">
                                                    <td><input type="radio" name="selected_strike" id={`flexRadioDefault_calls_${index}`} className="cursor-pointer form-check-input border-primary" value={index} checked={startBotParams.option === 'calls' && startBotParams.option_index === index} onChange={(e) => { setStartBotParams({ ...startBotParams, option_index: Number(e.target.value), 'strike_price': filteredCommonStrikes.calls[Number(e.target.value)].short.strike_price, 'short_expiry_symbol': filteredCommonStrikes.calls[Number(e.target.value)].short.symbol, 'short_expiry_product_id': filteredCommonStrikes.calls[Number(e.target.value)].short.product_id, 'long_expiry_symbol': filteredCommonStrikes.calls[Number(e.target.value)].long.symbol, 'long_expiry_product_id': filteredCommonStrikes.calls[Number(e.target.value)].long.product_id }) }} /></td>
                                                    <td className="table-success">{strike.delta_difference}</td>
                                                    <td className="table-success">{strike.mark_iv_difference}%</td>
                                                    <td className="table-success">{strike.price_difference}%</td>
                                                    <td>{Number(strike.short.quotes.bid_size) / 1000}</td>
                                                    <td className="text-end text-success">{Number(strike.short.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                    </td>
                                                    <td className="text-end text-danger">{Number(strike.short.quotes.best_ask).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.ask_iv) * 100).toFixed(1)}%</span></td>
                                                    <td>{Number(strike.short.quotes.ask_size) / 1000}</td>
                                                    <td className="table-success">{strike.strike_price}</td>
                                                    <td>{Number(strike.long.quotes.bid_size) / 1000}</td>
                                                    <td className="text-end text-success">{Number(strike.long.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.long.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                    </td>
                                                    <td className="text-end text-danger">{Number(strike.long.quotes.best_ask).toFixed(1)}
                                                      <span className="d-block text-secondary">{(Number(strike.long.quotes.ask_iv) * 100).toFixed(1)}%</span>
                                                    </td>
                                                    <td>{Number(strike.long.quotes.ask_size) / 1000}</td>
                                                  </tr>
                                                )
                                              })
                                              :
                                              <tr className="text-center">
                                                <td colSpan="13">No strikes found</td>
                                              </tr>
                                            }
                                          </tbody>
                                        </Table>
                                      </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                      <div className="table-responsive border">
                                        <Table className="table table-sm table-hover align-middle mb-0">
                                          <thead>
                                            <tr className="text-center">
                                              <th></th>
                                              <th className="table-success" colSpan="3">Differences</th>
                                              <th colSpan="4">Short Expiry: <small>{formatUTCDate(filteredCommonStrikes.settings.short_expiry)}</small></th>
                                              <th className="table-success">Common Strikes</th>
                                              <th colSpan="4">Long Expiry:  <small>{formatUTCDate(filteredCommonStrikes.settings.long_expiry)}</small></th>
                                            </tr>
                                            <tr className="text-center">
                                              <th width="3%">#</th>
                                              <th className="table-success" width="5%">Delta</th>
                                              <th className="table-success" width="5%">Mark IV</th>
                                              <th className="table-success" width="5%">Price</th>
                                              <th width="7%">Bid Qty</th>
                                              <th className="text-end" width="7%">Bid (Price / IV)</th>
                                              <th className="text-end" width="7%">Ask (Price / IV)</th>
                                              <th width="7%">Ask Qty</th>
                                              <th className="table-success" width="10%">Strike Price</th>
                                              <th width="7%">Bid Qty</th>
                                              <th className="text-end" width="7%">Bid (Price / IV)</th>
                                              <th className="text-end" width="7%">Ask (Price / IV)</th>
                                              <th width="7%">Ask Qty</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {filteredCommonStrikes.puts && filteredCommonStrikes.puts.length ?
                                              filteredCommonStrikes.puts.map((strike, index, arr) => {
                                                return (
                                                  <tr key={`puts_strike_${index}`} className="text-center">
                                                    <td><input type="radio" name="selected_strike" id={`flexRadioDefault_puts_${index}`} className="cursor-pointer form-check-input border-primary" value={index} checked={startBotParams.option === 'puts' && startBotParams.option_index === index} onChange={(e) => { setStartBotParams({ ...startBotParams, option_index: Number(e.target.value), 'strike_price': filteredCommonStrikes.puts[Number(e.target.value)].short.strike_price, 'short_expiry_symbol': filteredCommonStrikes.puts[Number(e.target.value)].short.symbol, 'short_expiry_product_id': filteredCommonStrikes.puts[Number(e.target.value)].short.product_id, 'long_expiry_symbol': filteredCommonStrikes.puts[Number(e.target.value)].long.symbol, 'long_expiry_product_id': filteredCommonStrikes.puts[Number(e.target.value)].long.product_id }) }} /></td>
                                                    <td className="table-success">{strike.delta_difference}</td>
                                                    <td className="table-success">{strike.mark_iv_difference}%</td>
                                                    <td className="table-success">{strike.price_difference}%</td>
                                                    <td>{Number(strike.short.quotes.bid_size) / 1000}</td>
                                                    <td className="text-end text-success">{Number(strike.short.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                    </td>
                                                    <td className="text-end text-danger">{Number(strike.short.quotes.best_ask).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.ask_iv) * 100).toFixed(1)}%</span></td>
                                                    <td>{Number(strike.short.quotes.ask_size) / 1000}</td>
                                                    <td className="table-success">{strike.strike_price}</td>
                                                    <td>{Number(strike.long.quotes.bid_size) / 1000}</td>
                                                    <td className="text-end text-success">{Number(strike.long.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.long.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                    </td>
                                                    <td className="text-end text-danger">{Number(strike.long.quotes.best_ask).toFixed(1)}
                                                      <span className="d-block text-secondary">{(Number(strike.long.quotes.ask_iv) * 100).toFixed(1)}%</span>
                                                    </td>
                                                    <td>{Number(strike.long.quotes.ask_size) / 1000}</td>
                                                  </tr>
                                                )
                                              })
                                              :
                                              <tr className="text-center">
                                                <td colSpan="13">No strikes found</td>
                                              </tr>
                                            }
                                          </tbody>
                                        </Table>
                                      </div>
                                    </TabPane>
                                  </TabContent>
                                </div>
                              </>
                              :
                              <>
                            
                              </>
                            }
                          </TabPane>
                          <TabPane tabId={3}>
                            <div className="p-4 border">
                              <Form>
                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3">
                                      <Label htmlFor="calendar_type">Calendar type</Label>{" "}<i className="bx bx-info-circle" id ="Calendar_type" />
                                        <UncontrolledTooltip placement="top" target="Calendar_type"> 
                                            {ToolTipData.calendar_type}
                                        </UncontrolledTooltip>
                                      <select id="calendar_type" name="calendar_type" className="form-select">
                                        <option value="">Select calendar type</option>
                                        <option value="short_calendar">Short Calendar</option>
                                        <option value="long_calendar">Long Calendar</option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col lg="4">
                                    <div className="mb-3">
                                      <Label htmlFor="expiry_position_to_begin">Expiry position to enter at first</Label>{" "}<i className="bx bx-info-circle" id ="Expriy_position" />
                                        <UncontrolledTooltip placement="top" target="Expriy_position"> 
                                            {ToolTipData.expiry_position}
                                        </UncontrolledTooltip>
                                      <select id="expiry_position_to_begin" name="expiry_position_to_begin" className="form-select">
                                        <option value="">Select expiry position to enter at first</option>
                                        <option value="short_expiry_position">Short Expiry Position</option>
                                        <option value="long_expiry_position">Long Expiry Position</option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col lg="4">
                                    <div className="mb-3">
                                      <Label htmlFor="fill_type">Fill type</Label>{" "}<i className="bx bx-info-circle" id ="Fill_type" />
                                        <UncontrolledTooltip placement="top" target="Fill_type"> 
                                            {ToolTipData.fill_type}
                                        </UncontrolledTooltip>
                                      <Input type="text" className="form-control" id="fill_type" value="Instant Fill" readOnly />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3">
                                      <Label for="hours_before_to_market_close">
                                        Hours before to market close
                                      </Label>{" "}<i className="bx bx-info-circle" id ="Hours_before_to_market_close" />
                                        <UncontrolledTooltip placement="top" target="Hours_before_to_market_close"> 
                                            {ToolTipData.hours_before_to_market_close}
                                        </UncontrolledTooltip>
                                      <Slider value={botSettings.hours_before_to_market_close}
                                        min={1} max={24} step={1}
                                        onChange={(value) => setBotSettings({ ...botSettings, hours_before_to_market_close: value })}
                                      />
                                    
                                      <Input type="number" className="form-control" id="hours_before_to_market_close"
                                        min="1" max="24" step="1"
                                        value={botSettings.hours_before_to_market_close}
                                        onChange={(e) => handleRangeInput(e, 'hours_before_to_market_close')}
                                        onInput={(e) => handleRangeInput(e, 'hours_before_to_market_close')}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="4">
                                    <div className="mb-3">
                                      <Label for="take_profit_percentage">
                                        Take Profit Percentage
                                      </Label>{" "}<i className="bx bx-info-circle" id ="Take_Profit" />
                                        <UncontrolledTooltip placement="top" target="Take_Profit"> 
                                            {ToolTipData.take_profit}
                                        </UncontrolledTooltip>
                                      <Slider value={botSettings.take_profit_percentage?botSettings.take_profit_percentage:0}
                                        min={0} max={100} step={1}
                                        onChange={(value) => setBotSettings({ ...botSettings, take_profit_percentage: value })}
                                        format={formatPercentage}
                                      />
                                      <div className="input-group">
                                        <Input type="number" className="form-control" id="take_profit_percentage"
                                          min="0" max="100" step="1"
                                          value={botSettings.take_profit_percentage}
                                          onChange={(e) => handleRangeInput(e, 'take_profit_percentage')}
                                          onInput={(e) => handleRangeInput(e, 'take_profit_percentage')}
                                        />
                                        <Label className="input-group-text" htmlFor="take_profit_percentage">%</Label>
                                      </div>
                                    </div>
                                  </Col>                                  
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                          <TabPane tabId={4}>
                            <div className="p-4 border">
                              <Row>
                                <Col md="4">
                                  <Card className="mini-stats-wid border">
                                    <CardBody>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <p className="text-muted fw-medium">
                                            Short Expiry
                                          </p>
                                          <h4 className="mb-0">{startBotParams.short_expiry_symbol}</h4>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                  <Card className="mini-stats-wid border">
                                    <CardBody>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <p className="text-muted fw-medium">
                                            Long Expiry
                                          </p>
                                          <h4 className="mb-0">{startBotParams.long_expiry_symbol}</h4>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                  <Card className="mini-stats-wid border">
                                    <CardBody>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <p className="text-muted fw-medium">
                                            Strike Price
                                          </p>
                                          <h4 className="mb-0">{startBotParams.strike_price}</h4>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col md="8">
                                  <Card className="border">
                                    <CardBody>
                                      <CardTitle className="mb-3">
                                        Bot Settings
                                      </CardTitle>
                                      <div className="table-responsive">
                                        <Table className="table table-border mb-0 align-middle">
                                          <tbody>
                                            <tr>
                                              <td>Calendar Type : </td>
                                              <td>{getCalendarTypeName(false, startBotParams.calendar_type)}</td>
                                            </tr>
                                            <tr>
                                              <td>Expiry Position To Enter At First : </td>
                                              <td>{getFirstPositionName(false, startBotParams.expiry_position_to_begin)}</td>
                                            </tr>
                                            <tr>
                                              <td>Fill type : </td>
                                              <td>Instant Fill</td>
                                            </tr>
                                            <tr>
                                              <td>Market Close Time (Hours before short expiry) :</td>
                                              <td>{startBotParams.hours_before_to_market_close}</td>
                                            </tr>
                                            <tr>
                                              <td>Take Profit Percentage :</td>
                                              <td>{startBotParams.take_profit_percentage}%</td>
                                            </tr>                                            
                                          </tbody>
                                        </Table>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                          <TabPane tabId={5}>
                            <div className="p-4 border">
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>Diagonal Bot intitiated successfully</h5>
                                      {failedUserDetails.length > 0 ?
                                        <p>
                                          <Alert className="alert-danger" role="alert">
                                            <h4 className="alert-heading">Orders failed for below user(s):</h4>
                                            <ul className="list-unstyled fw-medium">                                                                         {failedUserDetails.map((failedUser, index) => {
                                              return (
                                                <li key={index}><span className="py-2 d-block"><i className="mdi mdi-chevron-right me-1"></i> {failedUser.profile.first_name + ' ' + failedUser.profile.last_name} - <a className="text-danger">{failedUser.error}</a></span></li>
                                              )
                                            })}
                                              <span className="badge badge-soft-danger badge-pill float-end ms-1 font-size-12">{failedUserDetails.length > 9 ? failedUserDetails.length : '0' + failedUserDetails.length}</span>
                                            </ul>
                                          </Alert>
                                        </p>
                                        : ""
                                      }
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix mt-4">
                        <ul className={formLoader ? "disabled" : ""}>
                          {activeTab !== 1 ?
                            <li
                              className={activeTab === 1 ? "previous disabled" : "previous"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  { !formLoader ? toggleTab(activeTab - 1) : "" }
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            : ""
                          }
                          {activeTab !== 5 ?
                            <li
                              className="next"
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  { !formLoader ? toggleTab(activeTab + 1) : "" }
                                }}
                              >
                                {formLoader ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Loading</span> : <span>{activeTab === 4 ? "Start Bot" : "Next"}</span>}
                              </Link>
                            </li>
                            : ""
                          }
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                :
                <Card>
                  <CardBody>
                    <span className="mb-4">Diagonal strategy is disabled now. You can enable it from <Link to="/settings">Settings</Link> page.</span>
                  </CardBody>
                </Card>
              }
            </>
          }
        </Container>        
      </div>
    </React.Fragment>
  )
}

export default CreateDiagonal