import React, { Component } from "react"
import { Row, Col } from "reactstrap"
import blz_logo from "../../assets/images/blaze_white_logo.png";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={6}>{new Date().getFullYear()} © DeriSnap.</Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block">
                  Developed @ <a target="_blank" rel="noreferrer" href="https://blaze.ws"><img src={blz_logo} style={{width:'13px'}} ></img></a>
              </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer;