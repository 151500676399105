import React, { useCallback, useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
  Tooltip,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  CardText,
  Modal,
  Alert,
  UncontrolledTooltip
} from "reactstrap"
// import ToolTipData from '../../Settings/Settings.json'
const { default: axios } = require("axios");
import { MinimumTakeProfit, TaillingTakeProfit } from "../../../helpers/help-msg";
import classnames from "classnames"
import { Link } from "react-router-dom"
import parse from 'html-react-parser';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import { getStraddleExpiryDates, getStraddleStrikes, getUsersList, startBot } from "helpers/straddle_helper"
import { capitalizeFirst, counterRenderer, formatUTCDate } from "services/common.service"
import Select from "react-select"
import api from 'helpers/api_helper';
import Switch from "react-switch";
import Flatpickr from "react-flatpickr";
import DatePicker from "react-flatpickr";
const CreateStraddle = () => {

  firebase.auth();
  //Current User  
  //tooltip
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
  const [ToolTipData, setToolTipData] = useState([]);
  useEffect(() => {
    try {
      const gettoottipData = async () => {

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
        // console.log(response)
        setToolTipData(JSON.parse(JSON.stringify(response.data)))

      }

      gettoottipData()
    } catch (error) {
      console.log(error)
    }

  }, [apiUrl])
  //tooltip
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;

  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  //meta title
  //document.title = "Create Straddle";
  const firestoreDb = firebase.firestore();
  const [pageLoader, setPageLoader] = useState(false);
  const [formLoader, setformLoader] = useState(false);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [visibleTailling, setVisibleTailling] = useState(false);
  const [visibleMin, setVisibleMin] = useState(false);
  const [MintakeProfitMsg, setMintakeProfitMsg] = useState();
  const [TaillingTakeProfitMsg, setTaillingTakeProfitMsg] = useState();
  const [isStraddlebotEnabled, setIsStraddlebotEnabled] = useState(false);
  const [straddleExpiryDates, setStraddleExpiryDates] = useState([]);
  const [SlTriggerStraddles, setSlTriggerStraddles] = useState(true);
  const [startBotParams, setStartBotParams] = useState({});
  const [TraillingStraddles, setTraillingStraddles] = useState(false);
  const firestoreDbUser = firestoreDb.collection("users").doc(uid);
  const [straddleswait_time_reentry, setstraddleswait_time_reentry] = useState(0);
  const [straddlereentry_enabled, setstraddlereentry_enabled] = useState(false);
  const [time_to_close_orders_before_expiry_straddles, settime_to_close_orders_before_expiry_straddles] = useState(0);

  //Get Expiry dates list and set in options
  const getExpiryDates = async () => {
    try {
      const bodyParams = {
        currency: 'BTC'
      };
      await getStraddleExpiryDates(bodyParams).then((res) => {
        setStraddleExpiryDates(res.data.response.expiry_dates);
      }).catch(e => {
        throw e
      })
    } catch (e) {
      handleError(e);
    }
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  //Get Site Settings
  const getSiteSettings = async () => {
    setPageLoader(true);
    await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
      if (res.data.response && res.data.response.settings) {
        setIsStraddlebotEnabled(res.data.response.settings.enable_straddle);
      }
    }).catch(err => {
      throw err;
    })
    setPageLoader(false);
    // var site_settings = firestoreDb.collection("site-settings").doc('data');
    // await site_settings.get().then((res) => {
    //   if (res.exists) {
    //     const site_setting = res.data();
    //     setIsStraddlebotEnabled(site_setting.enable_straddle);
    //   }
    //   setPageLoader(false);
    // });
  }

  const getStraddleOrderSettings = async () => {

    await api({ contentType: true, auth: true }).get('/delta/get-site-settings?type=straddles-settings').then(res => {
      if (res.data.response && res.data.response.settings) {
        const order_settings = res.data.response.settings;
        setBotSettings({
          ...botSettings,
          'stop_loss_percentage': (order_settings.stop_loss_percentage) ? order_settings.stop_loss_percentage : botSettings.stop_loss_percentage,
          'trigger_percentage': (order_settings.trigger_percentage) ? order_settings.trigger_percentage : botSettings.trigger_percentage,
          'stop_loss_index_percentage': (order_settings.stop_loss_index_percentage) ? order_settings.stop_loss_index_percentage : botSettings.stop_loss_index_percentage,
          'minimum_take_profit_percentage': (order_settings.minimum_take_profit_percentage) ? order_settings.minimum_take_profit_percentage : botSettings.minimum_take_profit_percentage,
          'trailing_take_profit_percentage': (order_settings.trailing_take_profit_percentage) ? order_settings.trailing_take_profit_percentage : botSettings.trailing_take_profit_percentage,
          'combined_sl': (order_settings.combined_sl) ? order_settings.combined_sl : botSettings.combined_sl,
          'combined_sl_percentage':(order_settings.combined_sl_percentage) ? order_settings.combined_sl_percentage : botSettings.combined_sl_percentage,
          'combined_trailing_take_profit_percentage':(order_settings.combined_trailing_take_profit_percentage) ? order_settings.combined_trailing_take_profit_percentage : botSettings.combined_trailing_take_profit_percentage,
          'combined_trailing_stop_price_percentage':(order_settings.combined_trailing_stop_price_percentage) ? order_settings.combined_trailing_stop_price_percentage : botSettings.combined_trailing_stop_price_percentage,
          'Sl_trigger_take_profit': (order_settings.Sl_trigger_take_profit) ? order_settings.Sl_trigger_take_profit : botSettings.Sl_trigger_take_profit
        });
        setSlTriggerStraddles(order_settings && order_settings.Sl_trigger_take_profit !== undefined ? order_settings.Sl_trigger_take_profit : true)
        setTraillingStraddles(order_settings && order_settings.combined_sl !== undefined ? (order_settings.combined_sl == 1 ? true : false) : false)
        setstraddleswait_time_reentry(order_settings.wait_time_reentry ? order_settings.wait_time_reentry : "0");
        setstraddlereentry_enabled(order_settings.reentry_enabled ? (order_settings.reentry_enabled == 1 ? true : false) : false);
        settime_to_close_orders_before_expiry_straddles(order_settings.time_to_close_orders_before_expiry ? order_settings.time_to_close_orders_before_expiry : "0");

      }
    }).catch(err => {
      throw err;
    })

    // var docRef = firestoreDb.collection("site-settings").doc('straddles-settings');    
    // await docRef.get().then((doc) => {
    //     if (doc.exists) {
    //       const order_settings = doc.data();
    //       setBotSettings({
    //         ...botSettings,
    //         'stop_loss_percentage': (order_settings.stop_loss_percentage) ? order_settings.stop_loss_percentage : botSettings.stop_loss_percentage,
    //         'minimum_take_profit_percentage': (order_settings.minimum_take_profit_percentage) ? order_settings.minimum_take_profit_percentage : botSettings.minimum_take_profit_percentage,
    //         'trailing_take_profit_percentage': (order_settings.trailing_take_profit_percentage) ? order_settings.trailing_take_profit_percentage : botSettings.trailing_take_profit_percentage
    //       });
    //     }
    // })
  }

  //Get Users List
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectionType, setSelectionType] = useState('all-users');
  const [failedUserDetails, setFailedUserDetails] = useState([]);
  const getAllUsersList = async () => {
    try {
      const res = await getUsersList();
      if (res.data.response.users_list) {
        const usersListResponse = res.data.response.users_list;
        const result = usersListResponse.map(item => {
          return {
            label: item.display_text ? capitalizeFirst(item.display_text) : "",
            value: item.uid
          }
        })
        setUsersList(result);
        setSelectedUsers(result);
      }

    } catch (e) {
      handleError(e);
    }
  }

  const handleSelectionType = (e) => {
    if (e.target.value === 'all-users') {
      setSelectedUsers(usersList);
    } else {
      setSelectedUsers([])
    }
    setSelectionType(e.target.value);
  }

  const handleSelect = (selectedList, selectedItem) => {
    setSelectedUsers(selectedList);
  }
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return hours + ":" + minutes;
  }
  const handleDH = (e, strategy = '') => {
    var time = e[0]
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var H_M = toSeconds(hours, minutes, 0);
    if (strategy == 'straddleswait_time_reentry') {
      setstraddleswait_time_reentry(H_M)
    } else if (strategy == 'time_to_close_orders_before_expiry_straddles') {
      settime_to_close_orders_before_expiry_straddles(H_M)
    }
  }
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  //Choose Expiry & Get Strikes   
  const [straddleStrikes, setStraddleStrikes] = useState('');

  const processStep1 = async () => {
    try {
      setformLoader(true);
      setStraddleStrikes("");

      if (!selectedUsers || !selectedUsers.length) {
        toast.error('Please select users', { toastId: 'straddle_step1_select_user_error' });
        setformLoader(false);
        return false;
      }

      //Selected users
      let user_selection_type = selectionType;
      let selected_user_uids;
      if (user_selection_type === 'selected-users') {
        let selected_user_uids_arr = selectedUsers.map(selected_user => selected_user.value);
        if (selected_user_uids_arr.length) {
          selected_user_uids = selected_user_uids_arr.join(",")
        }
      }

      const expiry_date = document.getElementById('expiry_date').value;
      const bodyParams = {
        expiry_date: expiry_date
      };

      await getStraddleStrikes(bodyParams).then(res => {
        setformLoader(false);
        setStartBotParams({
          ...startBotParams, 'user_selection_type': user_selection_type,
          'selected_user_uids': selected_user_uids, 'expiry_date': expiry_date
        });
        setStraddleStrikes(res.data.response.strikes);
      }).catch(err => {
        throw err
      });

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Confirm Strike
  const processStep2 = async () => {
    try {
      setformLoader(true);

      const required_margin = Number(straddleStrikes.calls.order_margin) + Number(straddleStrikes.puts.order_margin);
      setStartBotParams({
        ...startBotParams,
        'calls': {
          symbol: straddleStrikes.calls.symbol,
          product_id: straddleStrikes.calls.product_id,
          best_ask: straddleStrikes.calls.best_ask,
          best_bid: straddleStrikes.calls.best_bid,
        },
        'puts': {
          symbol: straddleStrikes.puts.symbol,
          product_id: straddleStrikes.puts.product_id,
          best_ask: straddleStrikes.puts.best_ask,
          best_bid: straddleStrikes.puts.best_bid,
        },
        'strike_price': straddleStrikes.calls.strike_price,
        'required_margin': required_margin,
        'current_index_price': straddleStrikes.index_price
      });
      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Bot setup / settings
  const [botSettings, setBotSettings] = useState({
    stop_loss_percentage: 30,
    stop_loss_index_percentage: 0,
    minimum_take_profit_percentage: 10,
    trailing_take_profit_percentage: 10,
    trigger_percentage: 0,
    quantity: 1,
    combined_sl: false,
    reentry_enabled: 0,
    wait_time_reentry: "0",
    time_to_close_orders_before_expiry: "0",
    combined_sl_percentage:0,
    combined_trailing_take_profit_percentage:0,
    combined_trailing_stop_price_percentage:0,
  });

  const handleRangeInput = (e, setting_name, isInteger = true) => {
    if ((e.target.value === null || e.target.value === undefined || e.target.value === '')) {
      setBotSettings({ ...botSettings, [setting_name]: '' });
      return false;
    }

    let newvalue = Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
    setBotSettings({ ...botSettings, [setting_name]: (isInteger) ? parseInt(newvalue) : newvalue });
  }
  const processStep3 = async () => {
    try {
      setformLoader(true);
      let stop_loss_percentage = Number(document.getElementById('stop_loss_percentage').value);
      let minimum_take_profit_percentage = Number(document.getElementById('minimum_take_profit_percentage').value);
      let stop_loss_index_percentage = Number(document.getElementById('stop_loss_index_percentage').value);
      let trailing_take_profit_percentage = Number(document.getElementById('trigger_percentage').value);
      let trigger_percentage = Number(document.getElementById('trigger_percentage').value);

      // if (!stop_loss_percentage) {
      //   toast.error('Please select Market Stop Loss Percentage', { toastId: 'straddle_step3_1' });
      //   setformLoader(false);
      //   return false;
      // }
      // if (trigger_percentage >= stop_loss_percentage) {
      //   toast.error("SL % must be more than Stop Loss Activate Percentage");
      //   setformLoader(false);
      //   return false;
      // }

      // if (!minimum_take_profit_percentage) {
      //   toast.error('Please select Trailing Take Profit Percentage', { toastId: 'straddle_step3_2' });
      //   setformLoader(false);
      //   return false;
      // }

      // if (!trailing_take_profit_percentage) {
      //   toast.error('Please select Trailing Stop Price Percentage', { toastId: 'straddle_step3_3' });
      //   setformLoader(false);
      //   return false;
      // }
      let botparams;
      setStartBotParams({ ...botSettings, ...startBotParams })
      botparams = { ...botSettings, ...startBotParams }
      botparams.stop_loss_percentage = stop_loss_percentage;
      botparams.stop_loss_index_percentage = stop_loss_index_percentage;
      botparams.minimum_take_profit_percentage = minimum_take_profit_percentage;
      botparams.trailing_take_profit_percentage = trailing_take_profit_percentage;
      botparams.combined_sl = botSettings.combined_sl;
      botparams.trigger_percentage = trigger_percentage;
      botparams.Sl_trigger_take_profit = document.getElementById('straddlesMin').checked;
      botparams.combined_trailing_profit = TraillingStraddles == true ? 1 : 0;
      botparams.wait_time_reentry = straddleswait_time_reentry;
      botparams.time_to_close_orders_before_expiry = time_to_close_orders_before_expiry_straddles;
      botparams.reentry_enabled = straddlereentry_enabled === true ? 1 : 0;

      //Calls sell Order

      await startBot(botparams).then(async (res) => {
        setformLoader(false);
        toast.success(res.data.message);
      }).catch(err => {
        throw err;
      });

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Handle Form Wizard
  const toggleTab = async (tab) => {

    if (!isStraddlebotEnabled) {
      toast.error('Straddle bot creation disabled by admin', { toastId: 'straddle_bot_disabled' });
      return false;
    }

    let modifiedSteps = passedSteps;
    if (activeTab !== tab) {
      if (tab > activeTab) {
        let is_processed = false;
        switch (activeTab) {
          case 1:
            //Choose Expiry
            is_processed = await processStep1();
            break;
          case 2:
            //Confirm Strikes
            is_processed = await processStep2();
            break;
          case 3:
            //Bot Setup and start bot
            is_processed = await processStep3();
            break;
        }

        const index = modifiedSteps.indexOf(tab);
        if (index > -1) {
          modifiedSteps = modifiedSteps.slice(0, index);
        }

        if (is_processed) {
          modifiedSteps.push(tab);
          if (tab >= 1 && tab <= 4) {
            setactiveTab(tab)
            setPassedSteps(modifiedSteps)
            window.scrollTo(0, 0);
          }
        } else {
          setPassedSteps(modifiedSteps)
        }
      } else {
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
          window.scrollTo(0, 0);
        }
      }
    }
  }

  useEffect(() => {
    setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit())
    getSiteSettings();
    getExpiryDates();
    getStraddleOrderSettings();
    getAllUsersList();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Straddles" />
          {pageLoader ?
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin me-2" />
                        Loading
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            :
            <>
              {isStraddlebotEnabled ?
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> Choose Expiry
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span> Confirm Strikes
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">3.</span> Bot Setup
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">4.</span> Bot Success
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                            <div className="p-4 border">
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label htmlFor="users_selection_type">Users</Label>{" "}<i className="bx bx-info-circle" id="Users" />
                                      <UncontrolledTooltip placement="top" target="Users">
                                        {ToolTipData.users}
                                      </UncontrolledTooltip>
                                      <select id="users_selection_type" className="form-select" onChange={(e) => handleSelectionType(e)} value={selectionType} >
                                        <React.Fragment key='all-users'><option value='all-users'> All Users </option>
                                        </React.Fragment>
                                        <React.Fragment key='selected-users'>
                                          <option value='selected-users'> Specific Users </option>
                                        </React.Fragment>
                                      </select>
                                    </div>
                                  </Col>
                                  {selectionType === 'selected-users' ?
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label>Select users</Label>
                                        <Select id="selected_users" value={selectedUsers} isMulti={true} onChange={handleSelect} options={usersList} classNamePrefix="select2-selection"></Select>
                                      </div>
                                    </Col>
                                    : ""
                                  }
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label htmlFor="expiry_date">Expiration Date</Label>{" "}<i className="bx bx-info-circle" id="Expiration_Date" />
                                      <UncontrolledTooltip placement="top" target="Expiration_Date">
                                        {ToolTipData.expiration_date}
                                      </UncontrolledTooltip>
                                      <select id="expiry_date" className="form-select">
                                        {straddleExpiryDates.map((index) => (
                                          <React.Fragment key={index}>
                                            <option value={index} > {formatUTCDate(index)} </option>
                                          </React.Fragment>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div className="p-4 border">
                              {straddleStrikes ?
                                <Row>
                                  <Col lg="4">
                                    <Card outline color="primary" className="border">
                                      <CardHeader className="bg-transparent">
                                        <h5 className="my-0 text-primary">
                                          <i className="mdi mdi-arrow-up-bold-outline me-1" />Calls
                                        </h5>
                                      </CardHeader>
                                      <CardBody>
                                        <CardTitle className="mt-0">{straddleStrikes.calls.symbol}</CardTitle>
                                        <ul className="list-group">
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{straddleStrikes.calls.best_ask}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{straddleStrikes.calls.best_bid}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {straddleStrikes.calls.sell_price}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : <span className="text-dark">{Number(straddleStrikes.calls.order_margin).toFixed(2)} (1 cont)</span></li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {straddleStrikes.calls.leverage} </li>
                                        </ul>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="4">
                                    <Card outline color="primary" className="border">
                                      <CardHeader className="bg-transparent">
                                        <h5 className="my-0 text-primary">
                                          <i className="mdi mdi-arrow-down-bold-outline me-1" />Puts
                                        </h5>
                                      </CardHeader>
                                      <CardBody>
                                        <CardTitle className="mt-0">{straddleStrikes.puts.symbol}</CardTitle>
                                        <ul className="list-group">
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{straddleStrikes.puts.best_ask}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{straddleStrikes.puts.best_bid}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {straddleStrikes.puts.sell_price}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(straddleStrikes.puts.order_margin).toFixed(2)} (1 cont)</li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {straddleStrikes.puts.leverage} </li>
                                        </ul>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="4">
                                    <Card outline color="primary" className="border" style={{ "height": "94%" }}>
                                      <CardHeader className="bg-transparent">
                                        <h5 className="my-0 text-primary">
                                          <i className="mdi mdi-information-outline me-1" />Other Informations
                                        </h5>
                                      </CardHeader>
                                      <CardBody>
                                        <CardTitle className="mt-0"></CardTitle>
                                        <ul className="list-group">
                                          <li className="list-group-item"><b className="text-body fw-bold">Contract Index</b> : {straddleStrikes.contract_index} </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Current Index Price</b>: {Number(straddleStrikes.index_price).toFixed(2)} </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Required Margin</b>: {Number(Number(straddleStrikes.puts.order_margin) + Number(straddleStrikes.calls.order_margin)).toFixed(2)} (1 cont)</li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Time to expire</b>:  <Countdown date={startBotParams.expiry_date} renderer={counterRenderer} /></li>
                                        </ul>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                :
                                <Alert color="danger" role="alert">
                                  No strikes found, please check with some other expiry date
                                </Alert>
                              }
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div className="p-4 border">
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label className="mt-3">Stop Loss Activate Percentage</Label>{" "}<i className="bx bx-info-circle" id="stoploss" />
                                    <UncontrolledTooltip placement="top" target="stoploss">
                                      {ToolTipData.trigger}
                                    </UncontrolledTooltip>
                                    <Slider value={botSettings.trigger_percentage ? botSettings.trigger_percentage : 0} min={0} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, trigger_percentage: value })} />
                                    <div className="input-group">
                                      <Input
                                        type="number" step="0.5" min="0" max="100"
                                        className="form-control"
                                        id="trigger_percentage" value={botSettings.trigger_percentage}
                                        onChange={(e) => handleRangeInput(e, 'trigger_percentage', false)}
                                        onInput={(e) => handleRangeInput(e, 'trigger_percentage', false)}
                                      />
                                      <Label className="input-group-text" htmlFor="trigger_percentage">%</Label>
                                    </div>
                                    {straddleStrikes && botSettings.trigger_percentage && botSettings.trigger_percentage > 0 ? 
                                      <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call stop loss activate value<span className="fw-bold"> {((straddleStrikes.calls.sell_price * (botSettings.trigger_percentage/100)) + Number(straddleStrikes.calls.sell_price)).toFixed(2)}</span></p>
                                      <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put stop loss activate value <span className="fw-bold">{((straddleStrikes.puts.sell_price * (botSettings.trigger_percentage/100)) + Number(straddleStrikes.puts.sell_price)).toFixed(2)}</span></p></>:null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <div className="row">
                                      <div className="col-md-8">
                                        <Label className="mt-3">Market Stop Loss Percentage{" "}<i className="bx bx-info-circle" id="Stop_loss" />
                                        </Label>
                                      </div>
                                    </div>
                                    <UncontrolledTooltip placement="top" target="Stop_loss">
                                      {ToolTipData.stop_loss}
                                    </UncontrolledTooltip>

                                    <Slider value={botSettings.stop_loss_percentage ? botSettings.stop_loss_percentage : 0} min={0} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, stop_loss_percentage: value })} />
                                    <div className="input-group">
                                      <Input
                                        type="number" step="0.5" min="0" max="100"
                                        className="form-control"
                                        id="stop_loss_percentage" value={botSettings.stop_loss_percentage}
                                        onChange={(e) => handleRangeInput(e, 'stop_loss_percentage', false)}
                                        onInput={(e) => handleRangeInput(e, 'stop_loss_percentage', false)}
                                      />
                                      <Label className="input-group-text" htmlFor="stop_loss_percentage">%</Label>
                                    </div>
                                    {straddleStrikes && botSettings.stop_loss_percentage && botSettings.stop_loss_percentage > 0 ? 
                                    <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call market stop loss value<span className="fw-bold"> {((straddleStrikes.calls.sell_price * (botSettings.stop_loss_percentage/100)) + Number(straddleStrikes.calls.sell_price)).toFixed(2)}</span></p>
                                    <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put market stop loss value <span className="fw-bold">{((straddleStrikes.puts.sell_price * (botSettings.stop_loss_percentage/100)) + Number(straddleStrikes.puts.sell_price)).toFixed(2)}</span></p></>:null}
                                  </div>
                                  {/* <div className="col-md-5">
                                    <div className="form-check form-checkbox-outline form-check-success mt-3">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="combined_sl"
                                        checked={botSettings.combined_sl ? botSettings.combined_sl : false}
                                        onChange={(event) => setBotSettings({ ...botSettings, combined_sl: event.target.checked })}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="combined_sl"
                                      >
                                        Combined Stop Loss
                                      </label>{" "}<i className="bx bx-info-circle" id="Stop_loss_combined" /> <UncontrolledTooltip placement="top" target="Stop_loss_combined">
                                        {ToolTipData.stop_loss_combined}
                                      </UncontrolledTooltip>
                                    </div>
                                  </div> */}
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label className="mt-3"> Index Price Range % for Stop Loss</Label>{" "}<i className="bx bx-info-circle" id="stoploss" />
                                    <UncontrolledTooltip placement="top" target="stoploss">
                                      {ToolTipData.index_price_range}
                                    </UncontrolledTooltip>
                                    <Slider value={botSettings.stop_loss_index_percentage ? botSettings.stop_loss_index_percentage : 0} min={0} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, stop_loss_index_percentage: value })} />
                                    <div className="input-group">
                                      <Input
                                        type="number" step="0.5" min="0" max="100"
                                        className="form-control"
                                        id="stop_loss_index_percentage" value={botSettings.stop_loss_index_percentage}
                                        onChange={(e) => handleRangeInput(e, 'stop_loss_index_percentage', false)}
                                        onInput={(e) => handleRangeInput(e, 'stop_loss_index_percentage', false)}
                                      />
                                      <Label className="input-group-text" htmlFor="stop_loss_index_percentage">%</Label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label className="mt-3">Trailing Take Profit Percentage</Label>{" "}<i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}></i>
                                    <Slider value={botSettings.minimum_take_profit_percentage ? botSettings.minimum_take_profit_percentage : 0} min={1} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, minimum_take_profit_percentage: value })} />
                                    <div className="input-group">
                                      <Input
                                        type="number" step="0.5" min="1" max="100"
                                        className="form-control"
                                        id="minimum_take_profit_percentage" value={botSettings.minimum_take_profit_percentage}
                                        onChange={(e) => handleRangeInput(e, 'minimum_take_profit_percentage', false)}
                                        onInput={(e) => handleRangeInput(e, 'minimum_take_profit_percentage', false)}
                                      />
                                      <Label className="input-group-text" htmlFor="minimum_take_profit_percentage">%</Label>
                                    </div>
                                    <p className="mx-1 font-size-11 mb-0">Note: At which the Trailing take profit will become active</p>
                                    {straddleStrikes && botSettings.minimum_take_profit_percentage && botSettings.minimum_take_profit_percentage > 0 ? 
                                    <><p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}>Call trailing profit value<span className="fw-bold"> {(Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (botSettings.minimum_take_profit_percentage/100))).toFixed(2)}</span></p>
                                    <p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}> Put trailing profit value <span className="fw-bold">{(Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (botSettings.minimum_take_profit_percentage/100))).toFixed(2)}</span></p></>:null}
                                    <div className="form-check form-checkbox-outline form-check-success mt-3">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="straddlesMin"
                                        checked={SlTriggerStraddles}
                                        onClick={(event) => { setSlTriggerStraddles(event.target.checked); handleRangeInput(event, 'Sl_trigger_take_profit', false) }}
                                      />
                                      <label className="form-check-label" htmlFor="straddlesMin">
                                        Activate trailing take profit when stop loss triggered on one leg
                                      </label>{" "}
                                      {/* <i className="bx bx-info-circle" id ="STSLTMTP" />
                                    <UncontrolledTooltip placement="top" target="STSLTMTP"> 
                                        {ToolTipData.stop_loss_trigger_market_take_profit}
                                    </UncontrolledTooltip>  */}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label className="mt-3">Trailing Stop Price Percentage</Label>{" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)}></i>
                                    <Slider value={botSettings.trailing_take_profit_percentage ? botSettings.trailing_take_profit_percentage : 0} min={1} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, trailing_take_profit_percentage: value })} />
                                    <div className="input-group">
                                      <Input
                                        type="number" step="0.5" min="1" max="100"
                                        className="form-control"
                                        id="trailing_take_profit_percentage" value={botSettings.trailing_take_profit_percentage}
                                        onChange={(e) => handleRangeInput(e, 'trailing_take_profit_percentage', false)}
                                        onInput={(e) => handleRangeInput(e, 'trailing_take_profit_percentage', false)}
                                      />
                                      <Label className="input-group-text" htmlFor="trailing_take_profit_percentage">%</Label>
                                    </div>
                                    <p className="mx-1 font-size-11 mb-0">Note: Active after minimum take profit is reached</p>
                                    {straddleStrikes && botSettings.trailing_take_profit_percentage && botSettings.trailing_take_profit_percentage > 0 ? 
                                            <><p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}>Call trailing stop value<span className="fw-bold"> {(Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (botSettings.trailing_take_profit_percentage/100))).toFixed(2)}</span></p>
                                            <p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}> Put trailing stop value <span className="fw-bold">{(Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (botSettings.trailing_take_profit_percentage/100))).toFixed(2)}</span></p></>:null}
                                  </div>

                                  {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="StarangleTrailling"
                                      checked={TraillingStraddles}
                                      onChange={(event) => { setTraillingStraddles(event.target.checked), setBotSettings({ ...botSettings, combined_trailing_profit: event == true ? 1 : 0 }) }}
                                    />
                                    <label className="form-check-label" htmlFor="StarangleTrailling">
                                      Combined Profit
                                    </label>{" "}
                                  </div> */}
                                </Col>
                                <Col lg="4">
                                <div className="mb-3">
                                    <Label htmlFor="starddlesrow-combined-sl" className="mt-3">
                                    Combined Stop Loss %
                                    {" "}
                                    <i className="bx bx-info-circle" id ="straddles-combinedsl-trigger" />
                                      <UncontrolledTooltip placement="top" target="straddles-combinedsl-trigger">
                                          {ToolTipData.stop_loss_combined}
                                      </UncontrolledTooltip>
                                      </Label>
                                    <Slider value={botSettings.combined_sl_percentage ? botSettings.combined_sl_percentage : 0} min={0} max={100} step={0.5} onChange={value => {setBotSettings({
                                      ...botSettings,
                                      combined_sl_percentage: value
                                    })}} />
                                    <div className="input-group">
                                    <Input  type="number" max={100} step={0.5} value={botSettings.combined_sl_percentage} onChange={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100; // Default value
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setBotSettings({
                                      ...botSettings,
                                      combined_sl_percentage: newValue
                                    });
                                  } } onBlur={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100; // Default value
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setBotSettings({
                                      ...botSettings,
                                      combined_sl_percentage: newValue
                                    });
                                  } } name="straddle_combined_sl_percentage" className="form-control" />
                                    <Label className="input-group-text" htmlFor="starddlesrow-combined-sl">%</Label>
                                    </div>
                                    {straddleStrikes && botSettings.combined_sl_percentage && botSettings.combined_sl_percentage > 0 ? 
                                        <p className="mb-0" style={{fontSize:"12px"}}>Combined stop loss value<span className="fw-bold"> {((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))+((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (botSettings.combined_sl_percentage/100))).toFixed(2)}</span></p>
                                        :null}
                                  </div>
                                </Col>

                                <Col lg="4">
                                <div className="mb-3">
                                <Label htmlFor="starddlesrow-combined-profit" className="mt-3">
                                    Combined Trailing Take Profit % { " "} 
                                    <i className="bx bx-info-circle" id ="straddles-combined-trail-pro-trigger" />
                                      <UncontrolledTooltip placement="top" target="straddles-combined-trail-pro-trigger">
                                          {ToolTipData.combined_trailing_profit_percentage}
                                      </UncontrolledTooltip>
                                  </Label>
                                    <Slider value={botSettings.combined_trailing_take_profit_percentage ? botSettings.combined_trailing_take_profit_percentage : 0} min={0} max={100} step={0.5} onChange={value => {setBotSettings({
                                      ...botSettings,
                                      combined_trailing_take_profit_percentage: value
                                    })}} />
                                    <div className="input-group">
                                    <Input  type="number" max={100} step={0.5} value={botSettings.combined_trailing_take_profit_percentage} onChange={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100; // Default value
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setBotSettings({
                                      ...botSettings,
                                      combined_trailing_take_profit_percentage: newValue
                                    });
                                  } } onBlur={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100; // Default value
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setBotSettings({
                                      ...botSettings,
                                      combined_trailing_take_profit_percentage: newValue
                                    });
                                  } } name="straddle_combined_trailing_take_profit_percentage" className="form-control" />
                                    <Label className="input-group-text" htmlFor="starddlesrow-combined-profit">%</Label>
                                    </div>
                                    {straddleStrikes && botSettings.combined_trailing_take_profit_percentage && botSettings.combined_trailing_take_profit_percentage > 0 ? 
                                        <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined Trailing profit value<span className="fw-bold"> {((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (botSettings.combined_trailing_take_profit_percentage/100))).toFixed(2)}</span></p>
                                        :null}
                                  </div>
                                </Col>


                                <Col lg="4">
                                <div className="mb-3">
                                <Label htmlFor="starddlesrow-combined-sp" className="mt-3">
                                          Combined Trailing Stop Price % { " "} 
                                          <i className="bx bx-info-circle" id ="straddles-combined-trail-stop-trigger" />
                                            <UncontrolledTooltip placement="top" target="straddles-combined-trail-stop-trigger">
                                                {ToolTipData.combined_trailing_stop_price_percentage}
                                            </UncontrolledTooltip>
                                        </Label>
                                    <Slider value={botSettings.combined_trailing_stop_price_percentage ? botSettings.combined_trailing_stop_price_percentage : 0} min={0} max={100} step={0.5} onChange={value => {setBotSettings({
                                      ...botSettings,
                                      combined_trailing_stop_price_percentage: value
                                    })}} />
                                    <div className="input-group">
                                    <Input  type="number" max={100} step={0.5} value={botSettings.combined_trailing_stop_price_percentage} onChange={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100; // Default value
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setBotSettings({
                                      ...botSettings,
                                      combined_trailing_stop_price_percentage: newValue
                                    });
                                  } } onBlur={(e) => { 
                                    const value = e.target.value;
                                    let newValue = 100; // Default value
                                    if (value === null || value === '' || value < 0) {
                                      newValue = '';
                                    } else if (value <= 100) {
                                      newValue = value;
                                    }
                                    setBotSettings({
                                      ...botSettings,
                                      combined_trailing_stop_price_percentage: newValue
                                    });
                                  } } name="straddle_combined_trailing_stop_price_percentage" className="form-control" />
                                    <Label className="input-group-text" htmlFor="starddlesrow-combined-sp">%</Label>
                                    </div>
                                    {straddleStrikes && botSettings.combined_trailing_stop_price_percentage && botSettings.combined_trailing_stop_price_percentage > 0 ? 
                                        <p className="mb-0" style={{fontSize:"12px"}}>Combined trailing stop value<span className="fw-bold"> {((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (botSettings.combined_trailing_stop_price_percentage/100))).toFixed(2)}</span></p>
                                        :null}
                                  </div>
                                </Col>

                              </Row>
                              <h5 className="mt-5 mb-3 font-size-15">
                                Re-entry Settings
                              </h5>
                              <div className="p-4 border mb-4">
                                <Row >
                                  <Col lg="2">
                                    <FormGroup className="mt-4 mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Enable Re-Entry
                                      </Label>{" "}
                                      <i className="bx bx-info-circle" id="DHOSLIOLreentry_enabled" />
                                      <UncontrolledTooltip placement="top" target="DHOSLIOLreentry_enabled">
                                        {ToolTipData.reentry_enabled}
                                      </UncontrolledTooltip>
                                      {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className=" mt-4 mb-0"
                                      onChange={(e) => setstraddlereentry_enabled(e)}
                                      checked={straddlereentry_enabled}
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup className="mt-4 mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Waiting time for Re-Entry (H : M)
                                      </Label> {" "}
                                      <i className="bx bx-info-circle" id="wait_time_reentry" />
                                      <UncontrolledTooltip placement="top" target="wait_time_reentry">
                                        {ToolTipData.wait_time_reentry}
                                      </UncontrolledTooltip>
                                      <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Select time"
                                        name="wait_time_reentry"
                                        onChange={(e) => handleDH(e, 'straddleswait_time_reentry')}
                                        value={toHoursAndMinutes(straddleswait_time_reentry)}
                                        options={{
                                          enableTime: true,
                                          noCalendar: true,
                                          dateFormat: "H:i",
                                          time_24hr: true,
                                        }}
                                      />
                                    </FormGroup>
                                  </Col><Col lg="4">
                                    <FormGroup className="mt-4 mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Time to Close Orders Before Expiry (H : M)
                                      </Label> {" "}
                                      <i className="bx bx-info-circle" id="time_to_close_orders_before_expiry" />
                                      <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry">
                                        {ToolTipData.time_to_close_orders_before_expiry}
                                      </UncontrolledTooltip>
                                      <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Select time"
                                        name="wait_time_reentry"
                                        onChange={(e) => handleDH(e, 'time_to_close_orders_before_expiry_straddles')}
                                        value={toHoursAndMinutes(time_to_close_orders_before_expiry_straddles)}
                                        options={{
                                          enableTime: true,
                                          noCalendar: true,
                                          dateFormat: "H:i",
                                          time_24hr: true,
                                          maxTime: "12:00"
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3" className="mt-4 mb-3">
                                    <FormGroup className="mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Date to stop reentry
                                      </Label> {" "}
                                      <i className="bx bx-info-circle" id="stopdate_reentry" />
                                      <UncontrolledTooltip placement="top" target="stopdate_reentry">
                                        {ToolTipData.stop_date_reentry}
                                      </UncontrolledTooltip>
                                      {/*must select only future date*/}
                                      <DatePicker
                                        className="form-control d-block"
                                        placeholder="Select date"
                                        name="stopdate_reentry"
                                        onChange={(val) => {
                                          const date = new Date(val);
                                          const dateOnly = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
                                          setBotSettings({
                                            ...botSettings,
                                            stopdate_reentry: dateOnly,
                                          });
                                        }}
                                        value={botSettings.stopdate_reentry}
                                        options={{
                                          minDate: tomorrow // Set to current date to disable past dates
                                        }}
                                      />

                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId={4}>
                            <div className="p-4 border">
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>Straddle Bot intitiated successfully</h5>
                                      {failedUserDetails.length > 0 ?
                                        <p>
                                          <Alert className="alert-danger" role="alert">
                                            <h4 className="alert-heading">Orders failed for below user(s):</h4>
                                            <ul className="list-unstyled fw-medium">                                                                         {failedUserDetails.map((failedUser, index) => {
                                              return (
                                                <li key={index}><span className="py-2 d-block"><i className="mdi mdi-chevron-right me-1"></i> {failedUser.profile.first_name + ' ' + failedUser.profile.last_name} - <a className="text-danger">{failedUser.error}</a></span></li>
                                              )
                                            })}
                                              <span className="badge badge-soft-danger badge-pill float-end ms-1 font-size-12">{failedUserDetails.length > 9 ? failedUserDetails.length : '0' + failedUserDetails.length}</span>
                                            </ul>
                                          </Alert>
                                        </p>
                                        : ""
                                      }
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix mt-4">
                        <ul className={formLoader ? "disabled" : ""}>
                          {activeTab !== 1 ?
                            <li
                              className={activeTab === 1 ? "previous disabled" : "previous"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  { !formLoader ? toggleTab(activeTab - 1) : "" }
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            : ""
                          }
                          {activeTab !== 4 ?
                            <li
                              className="next"
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  { !formLoader ? toggleTab(activeTab + 1) : "" }
                                }}
                              >
                                {formLoader ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing...</span> : <span>{activeTab === 3 ? "Start Bot" : "Next"}</span>}
                              </Link>
                            </li>
                            : ""
                          }
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                :
                <Card>
                  <CardBody>
                    <span className="mb-4">Straddle strategy is disabled now. You can enable it from <Link to="/settings">Settings</Link> page.</span>
                  </CardBody>
                </Card>
              }
            </>
          }
        </Container>
      </div>
      <Modal
        isOpen={visibleMin}
        toggle={() => {
          setVisibleMin(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            To set the Minimum profit for an order.
          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleMin(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleMin(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
          setVisibleTailling(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            To set the Minimum profit for an order.
          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleTailling(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleTailling(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CreateStraddle