import React, { useEffect, useState } from "react";
import  BootstrapTable   from 'react-bootstrap-table-next';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
//import { backgroundColor1 } from "../../constants/constant";
import { CustomParseFloat, formatUTCDate, formatUTCDateTime, getStrangleMarketAction, getOrderStatusStyle, getMarketActionName, getMarketActionTriggerName } from "../../services/common.service";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,Label
} from "reactstrap";
import api from '../../helpers/api_helper';
const ListOptions = (props) => {
firebase.auth();
//const [info , setInfo] = useState([]);
const [form1_loader, setForm1Loader] = useState({ loading: false });
//Filter options
const [filters, setFilters] = useState({strategy: 'all',created_by: 'all',status: 'all',expiry_date: ''});
	const [selectDate, setSelectDate] = useState();
const [filterStartDate, setFilterStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 6)).toISOString());
const [filterEndDate, setFilterEndDate] = useState(new Date(new Date()).toISOString());
const [filteredOrders, setFilteredOrders] = useState([]);
const currentUser = JSON.parse(localStorage.getItem('user'));
let uid = currentUser.uid;
useEffect(() => {
  setFilteredOrders([]); //Set empty before filter
  fetchFilteredOrders(uid); //Fetch orders
},[filters,filterStartDate,filterEndDate])
	 
const { SearchBar, ClearSearchButton } = Search;	 
const items = [];	  
//Filters
const handleFilterChange = (e) => {  
  setFilters({ ...filters, [e.target.name]: e.target.value });   
}
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
const handleExpiryDateChange = (date) => {   
  setSelectDate(date)
  if (date) {
    let converted_date = new Date(date);
    //Add 17 hours and 30 minutes to match with delta's default expiry time
    converted_date.setHours(converted_date.getHours() + 17);
    converted_date.setMinutes(converted_date.getMinutes() + 30);       
    setFilters({ ...filters, expiry_date: converted_date.toISOString() });   
  } else {
    setFilters({ ...filters, expiry_date: "" }); 
  }  
  
}
const handleCreatedDateChange = (selectedDates, datestr) => {    
  if (selectedDates && selectedDates.length) {
    let converted_date = new Date(convert(selectedDates[0]));
    converted_date.setHours(0, 0, 0, 0);
    setFilterStartDate(converted_date.toISOString()); 
    if(selectedDates[1]){
      let converted_end_date = new Date(convert(selectedDates[1]));
      converted_end_date.setHours(23, 59, 59, 999);
      setFilterEndDate(converted_end_date.toISOString()); 
    }
  } else {
    setFilterStartDate(defaultStartDate); 
    setFilterEndDate(defaultEndDate);       
  }
}
//Function to get user tranasctions
const fetchFilteredOrders = async (uid)=> {
  setForm1Loader({        
    loading: true,
  });
  
  // var firestoreDb = firebase.firestore().collection("users").doc(uid);
  var body =  {
    uid : uid
  }
  let order_info = [];
  let orders_info = [];
  let ListedPNL = [];
  await api({ contentType: true, auth: true }).post('/abotex/transaction/transaction-history-data',body).then(async(res)  => {
    var response = res.data.response.data;
  // firestoreDb.collection("history").orderBy("created_at", "desc").get().then((allOrders) => {
  //   setForm1Loader({
  //     loading: false,
  //   });
    response.forEach(function(doc) {
      var data = doc.data;
      //Filter data
      checkFilters(data).then(response => {
        if (!response) {
          return false;
        }
        data.id = doc.id;
        data.expiry_date = formatUTCDate(data.expiry_date);
        data.created_at = formatUTCDateTime(data.created_at);
        data.triggered_at = data.triggered_at ? formatUTCDateTime(data.triggered_at) : data.updated_at;
        setFilteredOrders(arr => [...arr, data]);
      }).catch(err => {
        setForm1Loader({
          loading: false,
        });
      });          
    });
  }).catch(err=>{          
    setForm1Loader({        
      loading: false,
    });
  }) 
}

//Function to filter user transactions
const checkFilters = async (order_data) => {  
  let is_filter_valid = true;
  if (filters) { 
    //Strategy filter
    if (is_filter_valid && filters.strategy) {
      if (filters.strategy === 'strangles') {        
        is_filter_valid =  order_data.strategy === 'strangles' ? true : false;
      }else if (filters.strategy === 'trend_following') { 
        is_filter_valid = order_data.strategy === 'trend_following' ? true : false;
      } else if (filters.strategy === 'diagonals') { 
        is_filter_valid = order_data.strategy === 'diagonals' ? true : false;
      } else {
        is_filter_valid = true;
      }
    }

    //Created By filter
    if (is_filter_valid && filters.created_by) {
      if (filters.created_by === 'admin') {        
        is_filter_valid =  order_data.admin_bot? true : false;
      } else if (filters.created_by === 'user') { 
        is_filter_valid = !order_data.admin_bot? true : false;
      } else {
        is_filter_valid = true;
      }
    }
    
    //Status filter
    if (is_filter_valid && filters.status) {
      if (filters.status === 'closed') {        
        is_filter_valid =  order_data.status === 'closed'? true : false;
      } else if (filters.status === 'cancelled') { 
        is_filter_valid = order_data.status === 'cancelled'? true : false;
      } else {
        is_filter_valid = true;
      }
    }
    
    //Expiry date filter
    if (is_filter_valid && filters.expiry_date) {       
      if (new Date(filters.expiry_date).getTime() === new Date(order_data.expiry_date).getTime()) {        
        is_filter_valid = true;
      } else {        
        is_filter_valid = false;
      }      
    }

    //Created Date range filter
    if (is_filter_valid && filterStartDate && filterEndDate) {  
      let created_at = order_data.created_at;
      if (new Date(filterStartDate).getTime() === new Date(filterEndDate).getTime()) {        
        if(formatUTCDate(created_at) === formatUTCDate(filterStartDate)) {      
          is_filter_valid = true;
        } else {        
          is_filter_valid = false;
        }
      } else {        
        if(new Date(created_at).getTime() >= new Date(filterStartDate).getTime() && new Date(created_at).getTime() <= new Date(filterEndDate).getTime() ) {      
          is_filter_valid = true;
        } else {        
          is_filter_valid = false;
        }
      }     
    }

    return is_filter_valid ? true : false;
  } else {
    return true;
  }
}
    		
filteredOrders.map((data) => {
  items.push(data);
  return items;
})

const columns = [
{ dataField: 'strategy', text: 'Strategy', sort: true, headerStyle : { width: "10%" },formatter: (value, row) => {
  return <>
    {
      <div>
      <span className="text-capitalize">
        {row.strategy}
      </span>
        {row.admin_bot ? <div><Badge pill color="primary" className="me-1">Admin Bot</Badge></div> : ''}      
      </div>
    }    
  </>
} },
{ dataField: 'expiry_date', text: 'Expiry Date', sort: true, headerStyle : { width: "10%" } },   
{ dataField: 'direction', text: 'Side', formatter: (cell) => {return <>{<span className={cell === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{cell.toUpperCase()}</span>}</>},sort: true,headerStyle : { width: "5%" } },
{ dataField: 'symbol', text: 'Symbol', sort: true, headerStyle: { width: "28%" }, formatter: (value, row) => {
  return <>
    {row.strategy === 'strangles' || row.strategy === 'trend_following' ?
      <div>
        {
          <span className={row.direction === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{value}</span>}<div>
          <span className="text-danger font-size-11 ">{getStrangleMarketAction(row)}</span></div>
      </div> :
      <div>
        {
          <span className={row.direction === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{value}</span>
        }
        {row.market_action && row.market_action_order ?
            <div>
            <span className="text-danger font-size-11 ">{getMarketActionName(row)} {row.market_action_trigger ?
             <span className="text-danger font-size-11 ">({getMarketActionTriggerName(row)})</span>
              : ''
            } @ {formatUTCDateTime(row.market_action_at)}</span>
            
            </div>
            : 
          ''              
        }
      </div>
    }
  </>
} },
{ dataField: 'price', text: 'Price', sort: true, headerStyle : { width: "5%" }, formatter: (value, row) => {  return <>{<span className={row.direction === "buy" ? "text-success" : "text-danger"}>{value}</span>}</>} },

{ dataField: 'order_id', text: 'Order ID', sort: true,headerStyle : { width: "8%" }, formatter: (value, row) => { return <>{
  <span>{value?value:"..."}</span>}</>} },
{ dataField: 'quantity', text: 'Qty', sort: true, headerStyle: { width: "5%" }, formatter: (value, row) => {
  return <>
    {
      row.filled_quantity
    }
  </>
} },
{ dataField: 'status', text: 'Status', sort: true,headerStyle : { width: "6%" },formatter:(value, row) => { return <> <span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(row, "history") }}></span></>} },
{ dataField: 'booked_pnl', text: 'PNL', sort: true,headerStyle : { width: "7%" }, formatter: (value, row) => {
  let pnl; 

  if (row.strategy === 'strangles' || row.strategy=='trend_following') {
    pnl = row.booked_pnl ? row.booked_pnl * 0.001 * row.filled_quantity : row.realized_pnl ? row.realized_pnl : '';
  } else {
    pnl = row.booked_pnl ? row.booked_pnl : row.realized_pnl ? row.realized_pnl : '';
  } 
  
  return <>
    {
      <span title={pnl ? pnl : '' }>
        {pnl ? CustomParseFloat(pnl, 4) + ' USDT' : '...'}
      </span>
    }    
  </>
} },
{ dataField: 'created_at', text: 'Created Date', sort: true, headerStyle : { width: "13%" } },
];
  
const emptyDataMessage = () => { return form1_loader.loading ?'Loading...' : 'No Data Found'}
const defaultSorted = [
            {
                dataField: "created_at", // if dataField is not match to any column you defined, it will be ignored.
                order: "desc", // desc or asc
            },
        ];
const options = {
  page: 1,
  sizePerPage: 50,
  lastPageText: '>>',
  firstPageText: '<<',
  nextPageText: '>',
  prePageText: '<',
  showTotal: true,
  alwaysShowAllBtns: true,
  hideSizePerPage: true,    
  custom:true,
  totalSize: items.length
};

  return (
    <React.Fragment>
     <div className="page-content">
        <Container fluid>
            <Breadcrumbs title="DeriSnap" breadcrumbItem="Transactions" />
            <Row>
                <Col lg="12">
                    <Card>
                    <CardBody className="border-bottom">
                      
                         
                          <Row>
                              <Col xxl={1} lg={6} className="d-flex align-items-center">
                      <h5><b>Filter</b></h5>
                    </Col>
                              <Col xxl={2} lg={6}>   
                              <Label htmlFor="strategy">Strategy</Label>        
                      <select name="strategy" label="Strategy" className="form-select" onChange={(e)=>handleFilterChange(e)} value={filters.strategy}>                      
                        <React.Fragment key='all'>                       						  
                        <option value='all'>All</option>
                        </React.Fragment>
                        <React.Fragment key='strangles'>                       						  
                            <option value='strangles'>Strangles</option>
                        </React.Fragment>
                        <React.Fragment key='diagonals'>                       						  
                            <option value='diagonals'>Diagonals</option>
                        </React.Fragment>
                        <React.Fragment key='straddles'>                       						  
                            <option value='straddles'>Straddles</option>
                        </React.Fragment>
                        <React.Fragment key='trend_following'>                       						  
                            <option value='trend_following'>Trend Following</option>
                        </React.Fragment>
                      </select>                    
                   </Col>
                              <Col xxl={2} lg={6}>  
                              <Label htmlFor="created_by">Created by</Label>            
                      <select name="created_by" label="Created by" className="form-select" onChange={(e)=>handleFilterChange(e)} value={filters.created_by}>                      
                        <React.Fragment key='all'>                       						  
                        <option value='all'>All</option>
                        </React.Fragment>
                        <React.Fragment key='admin'>                       						  
                            <option value='admin'>Admin</option>
                        </React.Fragment>
                        <React.Fragment key='user'>                       						  
                            <option value='user'>Me</option>
                        </React.Fragment>
                      </select>                    
                    </Col>
                              <Col xxl={2} lg={6}>   
                              <Label htmlFor="status">Status</Label>     
                    <select name="status" label="Status" className="form-select" onChange={(e) => handleFilterChange(e)} value={filters.status}> 
                        <React.Fragment key='all'>                       						  
                          <option value='all'>All</option>
                        </React.Fragment> 
                        <React.Fragment key='closed'>                       						  
                          <option value='closed'>Closed</option>
                        </React.Fragment>
                        <React.Fragment key='cancelled'>                       						  
                          <option value='cancelled'>Cancelled</option>
                        </React.Fragment>                      
                      </select>
                  </Col>
                              <Col xxl={2} lg={6}>  
                              <Label htmlFor="status">Expiry date</Label>                   
                  <DatePicker
                        className="form-control mb-3 mb-xxl-0" placeholderText="Select Date" selected={selectDate} dateFormat="dd/MM/yyyy"
                        onChange={handleExpiryDateChange}
                    />
                  </Col>
                              <Col xxl={2} lg={6}>   
                              <Label htmlFor="Created">Created date</Label>  
                    <Flatpickr 
                  className="form-control d-block"
                  placeholder="Created Date"
                  options={{
                    mode: "range",
                    dateFormat: "d/m/Y",
                    defaultDate: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()]
                  }}
                  onChange={handleCreatedDateChange}
                />
                  </Col>
                            
               </Row>
                           
                      </CardBody>
                      <CardBody>
					  <PaginationProvider
						pagination={paginationFactory(options || [])}
						keyField="id"
						columns={columns || []}
						data={items || []}
					>
						{({ paginationProps, paginationTableProps }) => (
							<ToolkitProvider
								keyField="id"
								data={items}
								columns={columns || []}
								search
							>
								{toolkitProps => (
									<React.Fragment>
										<CardBody className="border-bottom px-0 pt-0">
											<Row className="g-2">
												{/* <Row className="mb-2"> */}
												<Col xxl="4" lg="6">
													<div className="search-box d-inline-block">
														<div className="position-relative">
															<SearchBar
																{...toolkitProps.searchProps}
															/>
															<i className="bx bx-search-alt search-icon" />
														</div>
													</div>
												</Col>
                        <Col>
                        <div className="pagination pagination-rounded justify-content-end">
											<PaginationListStandalone
												{...paginationProps}
											/>
										</div>
                        </Col>
												</Row>
											</CardBody>
                      
										<div className="table-responsive">
											<BootstrapTable
												{...toolkitProps.baseProps}
												{...paginationTableProps}
												responsive
												defaultSorted={defaultSorted}
												bordered={true}
												classes={
													"table align-middle table-nowrap table-check"
												}
												headerWrapperClasses={"table-light"}
                        noDataIndication={emptyDataMessage} 
											/>
										</div>
										<div className="pagination pagination-rounded justify-content-end mb-2">
											<PaginationListStandalone
												{...paginationProps}
											/>
										</div>
									</React.Fragment>
								)}
							</ToolkitProvider>
						)}
					</PaginationProvider>
					  
					  
					  
					  
          
        </CardBody>

                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
</React.Fragment>
  );
};

export default ListOptions;

 