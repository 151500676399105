import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody, CardTitle, FormGroup, Label, Button, Table
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts"
//i18n
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import api from '../../helpers/api_helper';
import firebase from "firebase/compat/app";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
const Dashboard = props => {
    firebase.auth();
    const firestoreDb = firebase.firestore();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    const { register, handleSubmit, setValue, reset, control, formState: { errors } } = useForm();
    const [form1_loader, setForm1Loader] = useState({ loading: false });
    const [rsiCheck, setRsiCheck] = useState([]);
    const [stvaluesnew, setStvaluesnew] = useState([]);
    const [series, setSeries] = useState([
        { name: "Short term", data: [] },
        { name: "Long term", data: [] },
    ]);
    const [maCross, setMaCross] = useState([]);
    const [trendSettings, setTrendSettings] = useState({})
    /*const series = [
      { name: "Short term", data:  [21212.5,21213.15,21208.65,21213.9,21216.5]},
      { name: "Long term", data:  [21212.5,21213.15,21208.65,21213.9,21216.5] },
    ]*/
    const options = {
        chart: {
            height: 380,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            animations: {
              enabled: false
            }
        },
        dataLabels: {
            enabled: false,
        },
       
        title: {
            text: 'Trend Checking',
            align: 'left',
            style: {
                fontWeight: '500',
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                formatter: function (value, timestamp) {
                    return new Date(timestamp).toLocaleString() // The formatter function overrides format property
                  }, 
                  format: 'dd/MM',
                show: true,
            }
        },
        yaxis: {
            labels: {
               
                show: true,
            },
            type: 'numeric'
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2
            },
            borderColor: '#f1f1f1'
        },

        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        }, plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
            }
        }]
    }
    const trendSettingSubmit = async () => {

        getTrendvalues(trendSettings)


    }
    const getTrendvalues = async (bodyParams) => {
        try {
            setForm1Loader({
                loading: true,
            });
            await api({ contentType: true, auth: true }).post('/delta/sma_values', bodyParams).then((result) => {
                let res = result.data.response.result;
                var conciseOhlcv = res.ohlcv.map((arr) => {
                    arr.pop();
                    return arr;
                  });

               
                if (trendSettings.trend_method == "supertrend") {
                    setMaCross(res.maCross);
                    var st_values = res.stvalues.map(function (el) { return el.value; });
                    let stvaluesn=[];let i=0;
                    let stvaluesn1=[];
                    res.stvalues.map(function (el) { 
                        if(el.trend=="short"){
                        stvaluesn[i]=[conciseOhlcv[i][0], el.value]
                        }else{
                            stvaluesn[i]=[conciseOhlcv[i][0], null]    
                        }
                        if(el.trend=="long"){
                            stvaluesn1[i]=[conciseOhlcv[i][0], el.value]
                            }else{
                                stvaluesn1[i]=[conciseOhlcv[i][0], null]    
                            }
                        i++;
                    
                    });
                    setStvaluesnew(res.stvalues);
                    setSeries([
                        { name: "Index", type: 'candlestick', data: conciseOhlcv },
                        { name: "Downtrend Line", type: 'line',color: "#e5403d", data: stvaluesn },
                        { name: "Uptrend Line", type: 'line',color:  "#149545", data: stvaluesn1 }
                    ]);
                    //
                } else if (trendSettings.trend_method == "macd") {
                    let macd=[]; let signal=[];  let i=0;
                    res.macdDatavalues.map(function (el) { 
                        macd[i]=[conciseOhlcv[i][0], el.MACD]
                        signal[i]=[conciseOhlcv[i][0], el.signal]
                        i++;
                    
                    });
                 //   var signal_values = res.macdDatavalues.map(function (el) { return el.signal; });
                 //   var hist_values = res.macdDatavalues.map(function (el) { return el.histogram; });
               
                    setSeries([
                       // { name: "Index", type: 'candlestick', data: conciseOhlcv },
                        { name: "MACD Line", type: 'line',color: "#E91E63", data: macd },
                       { name: "Signal Line", type: 'line', color: "#F44336", data: signal },
                        //{ name: "Histogram", type: 'bar',data: hist_values },
                    ]);
                    //setMaCross(res.maCross)
                }else if (trendSettings.trend_method == "ema") {
                    let smashort=res.smaData_short
                    let shortarr=[]; let i=0;
                    smashort.map((arr)=>{
                      shortarr[i]=[conciseOhlcv[i][0], arr]
                      i++;
                    })
                    let smalong=res.smaData_long
                    let longarr=[]; let j=0;
                    smalong.map((varr)=>{
                      longarr[j]=[conciseOhlcv[j][0], varr]
                      j++;
                    })
                    setSeries([

                        { name: "Index", type: 'candlestick', data: conciseOhlcv },
                           { name: "Short term", type: 'line',color: "#E91E63", data: shortarr},
                        { name: "Long term", type: 'line', color: "#F44336", data: longarr },
                    ]);
                    
                  //  setMaCross(res.maCross);
                } else {
                    let smashort=res.smaData_short
                    let shortarr=[]; let i=0;
                    smashort.map((arr)=>{
                      shortarr[i]=[conciseOhlcv[i][0], arr]
                      i++;
                    })
                    let smalong=res.smaData_long
                    let longarr=[]; let j=0;
                    smalong.map((varr)=>{
                      longarr[j]=[conciseOhlcv[j][0], varr]
                      j++;
                    })
                    setSeries([

                        { name: "Index", type: 'candlestick', data: conciseOhlcv },
                           { name: "Short term", type: 'line',color: "#E91E63", data: shortarr},
                        { name: "Long term", type: 'line', color: "#F44336", data: longarr },
                    ]);
                    
                  //  setMaCross(res.maCross);
                }
                setForm1Loader({
                    loading: false,
                });
             //  setRsiCheck(res.rsiCheck)

            }).catch(err => {
                throw err
            })
        }
        catch (err) {
            setForm1Loader({
                loading: false,
            });
           // toast.error(err.response.data.message);
        }
    }
    const getSettings = async () => {
        var get_site_settings = await firestoreDb.collection("site-settings").doc('trend-following-settings');
        get_site_settings.get().then(async (res) => {
            if (res.exists) {
                const site_settings = res.data();
                await setTrendSettings(site_settings);
                getTrendvalues(site_settings);
            } else {
                const site_settings = {
                    trend_method: "sma",
                    short_term: "10",
                    long_term: "100",
                    time_frame: "15m"
                }
                await setTrendSettings(site_settings);
                getTrendvalues(site_settings);
            }

        });

    }
    const handleTrendSettingsChange = (e) => {
        setTrendSettings({
            ...trendSettings,
            [e.target.name]: e.target.value
        })
        if (e.target.name == "trend_method") {
            setSeries([
                { name: "Short term", data: [] },
                { name: "Long term", data: [] },
            ]);
        }

    }

    useEffect(() => {
        getSettings();

        /* if(window.location.search){
            let params=new URLSearchParams(window.location.search)
             let trend_method=params.get('trend_method');
             if(trend_method!=""){
                 
                 if(trend_method=="sma"){
                     short_term=params.get('short_term');
                     long_term=params.get('long_term');
                     interval=params.get('interval');
                 }
             }
         } */
    }, []);

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>


                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Derisnap")}
                        breadcrumbItem={props.t("Trendview")}
                    />
                    <Row>

                        <Col xl="6">
                            <Card style={{ minHeight: '92%' }}>
                                <CardBody>

                                    <div>
                                        <CardTitle>Trendview Settings</CardTitle>

                                        <form key={1} onSubmit={handleSubmit(trendSettingSubmit)}>

                                            <>
                                                <FormGroup className="mb-4" row>
                                                    <Label
                                                        htmlFor="formrow-firstname-Input"
                                                        md="3"
                                                        className="col-form-label"
                                                    >
                                                        Method for Trend signal:
                                                    </Label>
                                                    <Col md="5">
                                                        <select name="trend_method" label="trend_method" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_method}>

                                                            <option value='sma'>SMA crossovers</option>
                                                            <option value='ema'>EMA crossovers</option>
                                                            <option value='macd'>MACD Signal Line cross </option>
                                                            <option value='supertrend'>Supertrend</option>
                                                        </select>
                                                    </Col>
                                                </FormGroup>
                                                {!trendSettings.trend_method || trendSettings.trend_method == "sma" ? <>

                                                    <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input"
                                                            md="3"
                                                            className="col-form-label"
                                                        >
                                                            Interval
                                                        </Label>
                                                        <Col md="9">
                                                            <select name="time_frame" label="time_frame" required className="form-select" value={trendSettings.time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                                                <option value='1m'>1 minute</option>
                                                                <option value='5m'>5 minutes</option>
                                                                <option value='15m'>15 minutes</option>
                                                                <option value='30m'>30 minutes</option>
                                                                <option value='1h'>1 hour</option>
                                                                <option value='2h'>2 hours</option>
                                                                <option value='4h'>4 hours</option>
                                                                <option value='1d'>1 day</option>
                                                                <option value='1w'>1 week</option>
                                                                <option value='1M'>1 month</option>
                                                            </select>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input"
                                                            md="3"
                                                            className="col-form-label"
                                                        >
                                                            Short length
                                                        </Label>
                                                        <Col md="9">
                                                            <input type="number" required min={1} step={1} value={trendSettings.short_term ? trendSettings.short_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="short_term" className="form-control" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input"
                                                            md="3"
                                                            className="col-form-label"
                                                        >
                                                            Long length
                                                        </Label>
                                                        <Col md="9">
                                                            <input type="number" required min={1} step={1} value={trendSettings.long_term ? trendSettings.long_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="long_term" className="form-control" />
                                                        </Col>
                                                    </FormGroup>
                                                </>: trendSettings.trend_method == "ema" ? <>
                                                    <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input"
                                                            md="3"
                                                            className="col-form-label"
                                                        >
                                                            Interval
                                                        </Label>
                                                        <Col md="9">
                                                            <select name="ema_time_frame" label="ema_time_frame" required className="form-select" value={trendSettings.ema_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                                                <option value='1m'>1 minute</option>
                                                                <option value='5m'>5 minutes</option>
                                                                <option value='15m'>15 minutes</option>
                                                                <option value='30m'>30 minutes</option>
                                                                <option value='1h'>1 hour</option>
                                                                <option value='2h'>2 hours</option>
                                                                <option value='4h'>4 hours</option>
                                                                <option value='1d'>1 day</option>
                                                                <option value='1w'>1 week</option>
                                                                <option value='1M'>1 month</option>
                                                            </select>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input"
                                                            md="3"
                                                            className="col-form-label"
                                                        >
                                                            Short length
                                                        </Label>
                                                        <Col md="9">
                                                            <input type="number" required min={1} step={1} value={trendSettings.ema_short_term ? trendSettings.ema_short_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_short_term" className="form-control" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input"
                                                            md="3"
                                                            className="col-form-label"
                                                        >
                                                            Long length
                                                        </Label>
                                                        <Col md="9">
                                                            <input type="number" required min={1} step={1} value={trendSettings.ema_long_term ? trendSettings.ema_long_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_long_term" className="form-control" />
                                                        </Col>
                                                    </FormGroup>
                                                    </>
                                                : trendSettings.trend_method == "macd" ?
                                                    <>

                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="formrow-firstname-Input"
                                                                md="3"
                                                                className="col-form-label"
                                                            >
                                                                MACD Interval
                                                            </Label>
                                                            <Col md="9">
                                                                <select name="macd_time_frame" required label="macd_time_frame" className="form-select" value={trendSettings.macd_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                                                    <option value='1m'>1 minute</option>
                                                                    <option value='5m'>5 minutes</option>
                                                                    <option value='15m'>15 minutes</option>
                                                                    <option value='30m'>30 minutes</option>
                                                                    <option value='1h'>1 hour</option>
                                                                    <option value='2h'>2 hours</option>
                                                                    <option value='4h'>4 hours</option>
                                                                    <option value='1d'>1 day</option>
                                                                    <option value='1w'>1 week</option>
                                                                    <option value='1M'>1 month</option>
                                                                </select>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="formrow-firstname-Input"
                                                                md="3"
                                                                className="col-form-label"
                                                            >
                                                                Short length
                                                            </Label>
                                                            <Col md="9">
                                                                <input type="number" required min={1} step={1} value={trendSettings.macd_short_term ? trendSettings.macd_short_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_short_term" className="form-control" />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="formrow-firstname-Input"
                                                                md="3"
                                                                className="col-form-label"
                                                            >
                                                                Long length
                                                            </Label>
                                                            <Col md="9">
                                                                <input type="number" required min={1} step={1} value={trendSettings.macd_long_term ? trendSettings.macd_long_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_long_term" className="form-control" />
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="formrow-firstname-Input"
                                                                md="3"
                                                                className="col-form-label"
                                                            >
                                                                Signal length
                                                            </Label>
                                                            <Col md="9">
                                                                <input type="number" required min={1} step={1} value={trendSettings.macd_signal_term ? trendSettings.macd_signal_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_signal_term" className="form-control" />
                                                            </Col>
                                                        </FormGroup>
                                                    </>
                                                    : trendSettings.trend_method == "supertrend" ? <>
                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="formrow-firstname-Input"
                                                                md="3"
                                                                className="col-form-label"
                                                            >
                                                                Interval
                                                            </Label>
                                                            <Col md="9">
                                                                <select name="st_time_frame" required label="st_time_frame" className="form-select" value={trendSettings.st_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                                                    <option value='1m'>1 minute</option>
                                                                    <option value='5m'>5 minutes</option>
                                                                    <option value='15m'>15 minutes</option>
                                                                    <option value='30m'>30 minutes</option>
                                                                    <option value='1h'>1 hour</option>
                                                                    <option value='2h'>2 hours</option>
                                                                    <option value='4h'>4 hours</option>
                                                                    <option value='1d'>1 day</option>
                                                                    <option value='1w'>1 week</option>
                                                                    <option value='1M'>1 month</option>
                                                                </select>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="formrow-firstname-Input"
                                                                md="3"
                                                                className="col-form-label"
                                                            >
                                                                ATR length
                                                            </Label>
                                                            <Col md="9">
                                                                <input type="number" required min={1} step={1} value={trendSettings.st_atr_length ? trendSettings.st_atr_length : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_atr_length" className="form-control" />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="formrow-firstname-Input"
                                                                md="3"
                                                                className="col-form-label"
                                                            >
                                                                Factor
                                                            </Label>
                                                            <Col md="9">
                                                                <input type="number" required min={1} step={0.1} value={trendSettings.st_factor ? trendSettings.st_factor : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_factor" className="form-control" />
                                                            </Col>
                                                        </FormGroup>


                                                    </> : ''}

                                            </>





                                            <div className="text-sm-end">
                                                <Button className="btn-label w-md" color="success" type="submit">
                                                    {form1_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                                    <i className="bx bx-smile label-icon"></i> Submit
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>

                        <Col xl="12">
                            <Card style={{ minHeight: '92%' }}>
                                {trendSettings.trend_method == "supertrend" ?
                                    <CardBody>
                                        Golden Cross (signals a bull market) : When Trend is &quot;short&quot;, it creates sell on puts side
                                        <br />Death Cross (signals a bear market) :When Trend is long&quot; , it creates sell on calls side

                                    </CardBody>
                                    : trendSettings.trend_method == "macd" ?
                                        <CardBody>
                                            Golden Cross (signals a bull market) : When MACD line crosses above the signal line, it creates sell on puts side
                                            <br />Death Cross (signals a bear market) : When MACD line crosses below the signal line , it creates sell on calls side
                                        </CardBody>
                                        : <CardBody>
                                            Golden Cross (signals a bull market) : When the short term moving average crosses above the long term moving average, it creates sell on puts side
                                            <br />Death Cross (signals a bear market) : When the short term moving average crosses below the long term moving average , it creates sell on calls side
                                        </CardBody>

                                }
                            </Card>
                        </Col>
                    </Row>
                    <Row>

                        <Col xl="12">
                            <Card style={{ minHeight: '92%' }}>
                                <CardBody>

                                    <ReactApexChart
                                        options={options}
                                        series={series}
                                        height="380"
                                        className="apex-charts"
                                        type="candlestick"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {trendSettings.trend_method == "supertrend" && stvaluesnew.length > 0 ? <>
                        <Row>

                            <Col xl="12">
                                <Card style={{ minHeight: '92%' }}>
                                    <CardBody>
                                        <table className="table table-bordered table align-middle table-nowrap table-check caption-top">
                                            <thead >
                                                <tr>
                                                    <th>Trend</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stvaluesnew.map((stval, index) => {
                                                    return (
                                                        <tr className="trow-hover" key={index} >
                                                            <td>{stval.trend}</td>
                                                            <td>{stval.value}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </> : ''}

                </Container>
            </div>
        </React.Fragment>
    );
};

Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);

