import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { userLogin, initiateWsConnect } from "../../../helpers/auth_helper"
import { toast } from "react-toastify"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (user.custom_token) {
       //login with custom token
    } else {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      if(response)
      {
        const login = yield call(userLogin, {
          email: user.email,
          password: user.password,
          captchaToken:user.captchaToken
        })
        if (login) {
          try {
            let user = JSON.parse(localStorage.getItem("user"));
            user.is_deltakey_updated = false;            
            const is_ws_connect = yield call(initiateWsConnect);
            yield put(loginSuccess(response));
            if (is_ws_connect) {
              user.is_deltakey_updated = true;
              localStorage.setItem("user", JSON.stringify(user)); 
              toast.success("Welcome "+(user.profile.first_name?user.profile.first_name:"Dood"));
              history.push("/dashboard");
            } else {                          
              localStorage.setItem("user", JSON.stringify(user));
              history.push("/delta-setup");
            }          
          } catch (error) {            
            let user = JSON.parse(localStorage.getItem("user"));
            user.is_deltakey_updated = false;
            localStorage.setItem("user", JSON.stringify(user));
            history.push("/delta-setup");
          }          
        }        
      }
    }            
  } catch (error) {
    window.grecaptcha.reset();
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("user")    
    const response = yield call(fireBaseBackend.logout)
    yield put(logoutUserSuccess(response))    
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {    
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))    
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
