import api from './api_helper';

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const userRegister = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: false }).post('/auth/register',data).then(res => {      
      resolve([200, "Register successfully"])
    }).catch(error => {
      reject(['', error.response.data ? error.response.data.message :"Something wrong for register"])
      throw error;
    })
  
  })
}

// Login Method
const userLogin = async(data)=>{
  return new Promise(async(resolve, reject) => {
  await api({ contentType: true, auth: true }).post('/abotex/login',data).then(res => {
    localStorage.setItem("user", JSON.stringify(res.data.response));
    resolve([200, "Login successfully"])
  }).catch(error => {    
    localStorage.removeItem("user")
    reject(['', error.response.data ? error.response.data.message :"Something wrong for login"])
    throw error;
  })

})
}

const forgotPassword = async(data)=>{
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: false }).post('/auth/password_reset',data).then(res => {
      resolve([200, "Password reset successfully"])
    }).catch(error => {
      reject(['', error.response.data ? error.response.data.message :"Something wrong for login"])
      throw error;
    })  
  })
}

// Initiate websocket connection
const initiateWsConnect = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).get('/abotex/delta/initiate-ws-connect').then(res => {
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}


// Initiate websocket connection
const updateAPIKey = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/delta/update-apikey-settings', data).then((res) => {
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

const getServerIP= async() => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).get('/abotex/delta/get-server-ip').then((res) => {
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

export {getServerIP,
  getLoggedInUser,
  isUserAuthenticated,
  userRegister,
  userLogin,  
  forgotPassword,
  initiateWsConnect,
  updateAPIKey
}
