import trailing_image from '../assets/images/trailing_image3.png'
export const telegramMsg = () => {

        let msg = `<p>
        <b>Step 1 :</b> Enter @Botfather in the search tab and choose the bot. <a target="_blank" href="https://prnt.sc/L_jEttmAZ56F">Check this</a><br>
        * Official Telegram bots have a blue checkmark beside their name.<br>        
    </p>
    <p>

    <b>Step 2 :</b> Click “Start” to activate BotFather bot. <a target="_blank" href="https://prnt.sc/CwouLGtf4qe0">Check this</a> <br>
        * In response, you receive a list of commands to manage bots.
    </p>

    <p>
        <b>Step 3 :</b> Choose or type the /newbot command and send it. <a target="_blank" href="https://prnt.sc/OCtNa1CZB5DA">Check this</a>
    </p>
    <p>
        <b>Step 4 :</b> Choose a name for your bot, and choose a username for your bot.  the bot can be found by its username in searches. The username must be unique and end with the word “bot.” <a target="_blank" href="https://prnt.sc/sYZ-InqQjheN">Check this</a><br>
        * After you choose a suitable name for your bot the bot is created. You will receive a message with a link to your bot t.me/<bot_username>, recommendations to set up a profile picture, description, and a list of commands to manage your new bot.
    </p>
    <p>
        <b>Step 5 :</b> To get Derisnap notification you need the token from Telegram. Copy the token value and paste it here(Token). <a target="_blank" href="https://prnt.sc/IWH4ftMSns2r">Check this</a>
    </p>
    `;
    return msg;
}

export const telegramIDMsg = () => {

    let msg = `<p>
    <b>Step 1 :</b> Enter @userinfobot in the search tab and choose the bot. <a target="_blank" href="https://prnt.sc/fk3HBGz_9GXT">Check this</a><br>    
</p>
<p>

<b>Step 2 :</b> By clicking Start, you’ve automatically entered “/start.” It will prompt the bot to display your user ID, as well as your selected first name, last name, and the language of choice. <a target="_blank" href="https://prnt.sc/DOFMvvQ-HuHk">Check this</a> <br>
    
</p>

<p>
    <b>Step 3 :</b> Copy your ID value and paste it here.
</p>
`;
return msg;
}

export const telegramChatIDMsg = () => {

    let msg = `
<p>
    <b>Step 1 :</b> Go to your group and click the add members icon. <a target="_blank" href="https://prnt.sc/_U6Q2fMa5pT2">Check this</a><br>
</p>

<p>
    <b>Step 2 :</b> Enter @username_to_id_bot in the search tab and choose the bot then add your group. <a target="_blank" href="https://prnt.sc/4k-0Sn4vBkG3">Check this</a><br>
</p>

<p>
    <b>Step 3 :</b> Copy chat ID and paste it here . <a target="_blank" href="https://prnt.sc/lsKB6sRNs1w8">Check this</a><br>
</p>
<p>
    <b>Step 4 :</b> Finally create your own group and add your bot in to your group using add members icon.
</p>
`;
return msg;
}
export const MinimumTakeProfit = () => {

    let msg = `
    <img src=${trailing_image} alt="Trailing Stop Price Percentage" style="width:100%;height:100%;" />
 <p>
    <b>Ex:</b> Trailing Take Profit Percentage = 10%, Trailing Stop Price Percentage = 5%.<br>
 </p>
 
 <p>
     <b>1.</b> When order entered into position with price as 600, then Trailing Take Profit will be 540 (-10% of 600) & Trailing Stop Price will be 570 (-5% of 600), that is Stop price = 570.<br>
 </p>
 
 <p>
     <b>2.</b>  When ASK price was in the range of 540 to 569, the Stop price will be 570.<br>
 </p>
 <p>
     <b>3.</b> When ASK price reached (Stop price) 570, we will close this order and get 5% profit.<br>
 </p>
 <p>
     <b>4.</b> When ASK price is 510 (that is going behind the Trailing Take Profit 540, now the profit% is 15), then the Stop price will get changed as 540 [600-10% = 540].<br>
 </p>
 <p>
     <b>5.</b> That is when Profit goes high, we will reduce the stop price as well.<br>
 </p>
`;
return msg;
}
export const TaillingTakeProfit = () => {

    let msg = `
    <img src=${trailing_image} alt="Trailing Stop Price Percentage" style="width:100%;height:100%;" />
 <p>
    <b>Ex:</b> Trailing Take Profit Percentage = 10%, Trailing Stop Price Percentage = 5%.<br>
 </p>
 
 <p>
     <b>1.</b> When order entered into position with price as 600, then Trailing Take Profit will be 540 (-10% of 600) & Trailing Stop Price will be 570 (-5% of 600), that is Stop price = 570.<br>
 </p>
 
 <p>
     <b>2.</b>  When ASK price was in the range of 540 to 569, the Stop price will be 570.<br>
 </p>
 <p>
     <b>3.</b> When ASK price reached (Stop price) 570, we will close this order and get 5% profit.<br>
 </p>
 <p>
     <b>4.</b> When ASK price is 510 (that is going behind the Trailing Take Profit 540, now the profit% is 15), then the Stop price will get changed as 540 [600-10% = 540].<br>
 </p>
 <p>
     <b>5.</b> That is when Profit goes high, we will reduce the stop price as well.<br>
 </p>
`;
return msg;
}
export const MidPrize = () => {

    let msg = `
<p>
    This field is to calculate the price for the limit order.
</p>
<p>This helps create limit orders with prices close to the current market price. If the value is 0 or empty, then the limit price will be the mid value of best bid and best ask. otherwise it will use the following method for limit price</p>
<p>
    <b>Ex:</b> If you entered 75% here, then the calculation for price as follows.<br>
</p>

<p>
    <b>for sell order-> </b> if the best bid is 100 and the best ask is 300, then the mid value is 200
    Difference between current best ask and mid value is 100 (300-200), 75% of value 100 is 75. so limit order price= 200 (mid price) + 75
    So the sell price will be 275.<br>
</p>

<p>
    <b>for buy order -> </b> Difference between mid value and current best bid is 100 (200-100), 75% of value 100 is 75. so limit order price= 200 (mid price) - 75, So the buy price will be 125.<br>
</p>

`;
return msg;
}