import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,Spinner, Table, Tooltip, Badge,Popover,
    PopoverHeader,
    PopoverBody,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";

import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import api from '../../../services/api.service';
import Swal from 'sweetalert2';
import {  getContractValue,formatUTCDate, formatUTCDateTime, capitalizeFirst, getStrangleMarketAction,timeFormat, getOrderStatusStyle, getPrices, counterRenderer,getOrderLogs } from "../../../services/common.service";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import Countdown from "react-countdown"
import HistoryModal from "../../../components/Modal/HistoryModal";

import "react-rangeslider/lib/index.css"
const ListOptions = (props) => {
    console.log("****************asdsad calling**************")
    firebase.auth();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    const contractValue = getContractValue('BTCUSDT');
    const ordersRef = firebase.firestore().collectionGroup("strangle_orders").where("settings.created_by", '==', uid).orderBy("strangle_id", "desc").orderBy("symbol", "desc");  
    const [orderData, setOrderData] = useState([]);
    const [stopStrangleFlag, setStopStrangleFlag] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [ttop, setttop] = useState(false);
    const [popovertop, setpopovertop] = useState(false);
    //for settings -end
    const popovertopchange = targetName => {
        if (!popovertop[targetName] || !popovertop[targetName].popover) {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: true
                }
            });
        } else {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: false
                }
            });
        }
    };
    const ispopoverOpen = targetName => {
        return popovertop[targetName] ? popovertop[targetName].popover : false;
    };
    const handleError = (err) => {
        if (err.response && err.response.data) {
            toast.error(err.response.data.message);
        }else if(err.data.message){
            toast.error(err.data.message);
        }else if (err.message) {
            toast.error(err.message);
        } else if (err.request) {
        } else {
        }

    }
    
    const getOrders = useCallback(() => {
console.log("****************getorders calling**************")
        ordersRef.onSnapshot((querySnapshot) => {

            const items = [];
            querySnapshot.forEach((doc) => {
                let item = doc.data();
                item.dbid = doc.id;
                if ((item.status.toLowerCase() === "closed" || item.filled_quantity > 1) && item.direction === "sell") {
                    let sold_price = item.price;
                    if (item.direction === 'buy') {
                        sold_price =(item.average_fill_price) ?(item.average_fill_price): (item.sold_price) 
                    }
                    //   let unrealized_pnl = parseFloat((Number(sold_price) - Number(item.lowest_ask_now)) * Number(contractValue) * Number(item.filled_quantity));
                    //   item.unrealized_pnl_exact = unrealized_pnl;
                    //   item.unrealized_pnl = Math.floor(unrealized_pnl * 100) / 100;
                    //   item.unrealized_pnl_currency = 'USDT';
                    } 
                    //  else {
                    //     item.unrealized_pnl = '...'
                    //  }
                items.push(item)
            });

            // Combine orders by admin strangle id
            const all_strangles = items.reduce(function (row, obj) {
                row[obj.admin_strangle_id] = row[obj.admin_strangle_id] || [];
                row[obj.admin_strangle_id][obj.strangle_id] = row[obj.admin_strangle_id][obj.strangle_id] || [];
                row[obj.admin_strangle_id][obj.strangle_id].push(obj);
                return row;
            }, []);    
            
            // Find strangle status - to enable market close
            const active_strangles = Object.keys(all_strangles).map(admin_strangle_index => {         
                return Object.keys(all_strangles[admin_strangle_index]).map(strangle_index => {          
                    const rows = all_strangles[admin_strangle_index][strangle_index];
                    if (stopStrangleFlag[rows[0].strangle_id] && stopStrangleFlag[rows[0].strangle_id] == 1 && rows[0].is_stop_triggered == 0 && rows[0].status.toLowerCase() !== 'cancelled') {
                        toast.error("Unable to stop the order, order may moved to position, please try again");
                        stopStrangleFlag[rows[0].strangle_id] = 0;
                        setStopStrangleFlag(stopStrangleFlag);
                    }
                    let market_filled_order;
    
                    const filled_order = rows.find(element => (element.direction.toLowerCase() === 'buy' && element.status.toLowerCase() === 'open') || (element.direction.toLowerCase() === 'sell' && (element.status.toLowerCase() === 'closed' || element.filled_quantity > 0)));
                    if (rows.length > 1) {
                        const filled_quantity_main = rows[0].filled_quantity;
                        const order_type_main = rows[0].order_type;
                        const direction_main = rows[0].direction;
                        const status_main = rows[0].status;
                        const filled_quantity_secondary = rows[1] ? rows[1].filled_quantity : 0;
                        const order_type_secondary = rows[1] ? rows[1].order_type : '';
                        const direction_secondary = rows[1] ? rows[1].direction : '';
                        const status_secondary = rows[1] ? rows[1].status : 'open';
    
                        if (order_type_main === "base" && status_main === "open" && (order_type_secondary !== 'base' || direction_secondary === "buy" || (filled_quantity_main > 0 && filled_quantity_main != filled_quantity_secondary))) {
                            market_filled_order = 1;
                        }
                        if (order_type_secondary === "base" && status_secondary === "open" && (order_type_main !== 'base' || direction_main === "buy" || (filled_quantity_main > 0 && filled_quantity_main != filled_quantity_secondary))) {
                            market_filled_order = 1;
                        }
                    } else {
                        market_filled_order = 0;
                    }
    
                    const strangle_status = (filled_order) ? "closed" : "open";
                    const market_fill_status = (rows[0].settings.autoMarketFill != 1 && (market_filled_order == 1)) ? "1" : "0";
                    return rows.map((row, index) => {
                        row.strangle_status = strangle_status;
                        row.market_fill_status = market_fill_status;
                        return row
                    });                    
                });
            });
            setOrderData(active_strangles);
            /* const calls_puts = items.reduce(function (rows, obj, index) {

                return (!rows.length || obj.strangle_id !== rows[rows.length - 1][0].strangle_id ? rows.push([obj])
                    : rows[rows.length - 1].push(obj)) && rows;
            }, []);
          });
      
          const strangles = calls_puts.map(strangles_rows => {
            return strangles_rows.map(rows => {
              if (stopStrangleFlag[rows[0].strangle_id] && stopStrangleFlag[rows[0].strangle_id] == 1 && rows[0].is_stop_triggered == 0 && rows[0].status.toLowerCase() !== 'cancelled') {
                toast.error("Unable to stop the order, order may moved to position, please try again");
                stopStrangleFlag[rows[0].strangle_id] = 0;
                setStopStrangleFlag(stopStrangleFlag);
              }
              const filled_order = rows.find(element => (element.direction.toLowerCase() === 'buy' && element.status.toLowerCase() === 'open') || (element.direction.toLowerCase() === 'sell' && (element.status.toLowerCase() === 'closed' || element.filled_quantity > 0)));
             
    
              const strangle_status = (filled_order) ? "closed" : "open";
    
              const strangle = rows.map((row, index) => {
                row.strangle_status = strangle_status;
                return row
              })
              return strangle;
            });


            setOrderData(strangles); */
        });
    
      }, [ordersRef, contractValue]);
 
    useEffect(() => {
        console.log("****************useEffect calling**************")
        getOrders();
    }, []);
    const marketCloseOrder = async (uid,order) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to remove the order?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            let type;
            if (result.isConfirmed) {

                stopStrangleFlag[order.symbol] = 1;
                setStopStrangleFlag(stopStrangleFlag);
                if ((order.direction.toLowerCase() == "buy" && order.status == "open") || (order.direction.toLowerCase() === 'sell' && order.filled_quantity > 0)) {
                    type = "market_close"
                } else {
                    type = "stop"
                }
                const params = {
                    uid: uid,
                    strangle_id: order.strangle_id,
                    symbol: order.symbol,
                    type: type
                  };
                await api({ contentType: true, auth: true }).post('/abotex/delta/market-close-strangle', params).then((res) => {
                    if (res.data !== "") {
                        handleError(res);
                        //setStopped(res.data.message);
                        stopStrangleFlag[order.symbol] = 0;
                        setStopStrangleFlag(stopStrangleFlag);
                    }
                });
            }
        });
    }
    const stopReentry =async (strangleId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to stop reentry ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const params = {
                        strangle_id: strangleId
                    };
                    await api({ contentType: true, auth: true }).post('/abotex/delta/stop-reentry', params).then((res) => {
                        if (res.data !== "") {
                            toast.error(res.data.message);
                        }
                    });
                } catch (e) {

                    toast.error(e.message);
                }
            }
        })
    }
    const marketCloseStrangle = async (uid, strangleId, type) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to close the bot ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (type == 'stop') {
                    stopStrangleFlag[strangleId] = 1;
                    setStopStrangleFlag(stopStrangleFlag);
                } else {
                    stopStrangleFlag[strangleId] = 2;
                    setStopStrangleFlag(stopStrangleFlag);
                }

                try {
                    const params = {
                        uid: uid,
                        strangle_id: strangleId,
                        type: type
                      };
                      await api({ contentType: true, auth: true }).post('/abotex/delta/market-close-strangle', params).then((res) => {
                        if (res.data !== "") {
                            handleError(res);
                        }
                    });
                } catch (e) {

                    toast.error(e.message);
                }
            }
        })
    };
    const CloseAdminBot = async (admin_strangle_id) => {
        try {
          Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to close the bot ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then(async (result) => {
            if (result.isConfirmed) {
              toast.success('Closing bot was initiated.');
              const params = {
                admin_strangle_id: admin_strangle_id
              };
              stopStrangleFlag[admin_strangle_id] = 1;
              setStopStrangleFlag(stopStrangleFlag);
              await api({ contentType: true, auth: true }).post('/abotex/delta/close-admin-bot', params).then((res) => {
                if (res.data !== "") {
                  toast.success(res.data.message);
                }
              });
            }
          })
        } catch (err) {
          if (err.response) {
            toast.error(err.response.data.message);
          } else if (err.message) {
            toast.error(err.message);
          } else if (err.request) {
            //console.log(err.message);
          } else {
            //console.log(err.message);
          }
        }
      };
      const getHistoryBysymbol = async (uid,dbid,symbol) => {
        try {  
          var body =  {
            uid : uid,
            strategy : "strangles",
            dbid : dbid
          }  
          let order_history= await getOrderLogs(body);
          if(order_history){
              setModalShow({
                symbol: symbol,
                show: true,
              });
              setHistoryData(order_history);
            } else {
                setHistoryData([]);
              setModalShow({
                symbol: symbol,
                show: true,
              });
            }
        } catch (err) {
          if (err.response) {
            toast.error(err.response.data.message);        
          } else if (err.message) {
            toast.error(err.message);         
          } else if (err.request) {
            //console.log(err.message);
          } else {        
            //console.log(err.message);
          }
        }
      };
      // Stop
    // const getHistoryBysymbol = async (uid,symbol) => {
    //     try {

    //         await firebase.firestore().collection("users").doc(uid)
    //             .collection("historysymbol")
    //             .doc(symbol)
    //             .get()
    //             .then(async (doc) => {
    //                 if (doc.exists) {

    //                     let order_history = [];

    //                     if (doc.data().order_history) {
    //                         order_history = doc.data().order_history;
    //                         order_history.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    //                     }

    //                     setModalShow({
    //                         symbol: symbol,
    //                         show: true,
    //                     });
    //                     setHistoryData(order_history);
    //                 } else {
    //                     setHistoryData([]);
    //                     setModalShow({
    //                         symbol: symbol,
    //                         show: true,
    //                     });
    //                 }
    //             });
    //     } catch (e) {
    //         //console.log("History symbol --: ", e);
    //     }
    // };  
    const sendMsg = async (user_id) => {
        const { value: text } = await Swal.fire({
          input: 'textarea',
          title: 'Message',
          buttonsStyling: false,
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-secondary",
          reverseButtons: true,
          inputPlaceholder: 'Type your message here...',
          inputAttributes: {
            'aria-label': 'Type your message here'
          },
          showCancelButton: true
        })
    
        if (text) {
          const bodyparams = {
            user_id: user_id,
            msg: text
          }
          await api({ contentType: true, auth: true }).post('/abotex/user/send-user-msg', bodyparams).then((res) => {
            toast.success('Message sent successfully');
          }).catch(err => {
            toast.error('Chat Not Found');
            throw err;
          });
        }
    
    
      }      
    return (
        <React.Fragment>
            <div className="page-content">
                
                <Container fluid>
                <Breadcrumbs title="DeriSnap" breadcrumbItem="Active Strangles" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-3">Orders</h4>

                                        <div className="table-responsive">
                                                {/*********/}
                                                <Table className="table table-bordered table align-middle table-nowrap table-check  caption-top-dark">
                                                    <thead >
                                                        <tr>
                                                            <th>Expiry Date</th>
                                                            <th>User</th>
                                                            <th>Side</th>
                                                            <th>Symbol</th>
                                                            <th>Sell/Buy Price</th>
                                                            <th>Trigger/SL/Take Profit</th>
                                                            <th>Qty</th>
                                                            <th>Order ID</th>
                                                            <th>Status</th>
                                                            <th>LTP</th>
                                                            <th className="text-decoration-underline" >
                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={ttop}
                                                                    target="TooltipTop"
                                                                    toggle={() => {
                                                                        setttop(!ttop);
                                                                    }}
                                                                >
                                                                    Unrealised PNL = price - latest ask
                                                                </Tooltip>
                                                                <span id="TooltipTop">UPNL</span>
                                                            </th>
                                                            <th  className="text-center" colSpan={2}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    {orderData.length > 0 ? (
                                                        orderData.map((users, index) => {
                                                        const orders_count = users.reduce((a, c) => a + c.length, 0);
                                                        return (
                                                                <tbody key={index}>
                                                                   {users.map((row, row_index) => {
                                                                        return (
                                                                            row.map((order, index, arr) => {
                                                                                let market_action = getStrangleMarketAction(order);
                                                                                const roll_time_clock =(order.settings.roll_status==1 && order.settings.Roll_time_clock)?order.settings.Roll_time_clock.split(":"):"";
                                                                            return (
                                                                                <tr className="trow-hover" key={index}>
                                                                                 {row_index === 0 && index === 0 ?
                                                                                    <td className=" p-2 " rowSpan={orders_count}>
                                                                                        <div className="border p-2">
                                                                                        <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                                                        <div style={{ "float": "right" }}><a
                                                                                                id={"Popovertop-" + order.strangle_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`${order.strangle_id}`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                                            </a>
                                                                                                <Popover
                                                                                                    placement="right"
                                                                                                    isOpen={ispopoverOpen(`${order.strangle_id}`)}
                                                                                                    target={"Popovertop-" + order.strangle_id}
                                                                                                    toggle={() => {
                                                                                                        popovertopchange(`${order.strangle_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}><b>Info</b></PopoverHeader>
                                                                                                    <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                                        Index %: <b>{order.settings.index_percentage}</b> <br />
                                                                                                        {!order.admin_bot? <>
                                                                                                        Stop Loss Activate {order.settings.combined_sl?" (combined)":""}%: <b>{order.settings.trigger_percentage}</b> <br /></>:""}
                                                                                                        Market Stop Loss %: <b>{order.settings.stop_loss_percentage}</b> <br />
                                                                                                        Index Price Range % for Stop Loss: <b>{order.settings.stop_loss_index_percentage}</b> <br />
                                                                                                        Minimum Take Profit{order.settings.combined_trailing_profit?" (combined)":""}%: <b>{order.settings.min_take_profit_percentage ? order.settings.min_take_profit_percentage : 0}</b> <br />
                                                                                                        Trailing Stop Price %: <b>{order.settings.trailing_take_profit_percentage ? order.settings.trailing_take_profit_percentage : 0}</b> <br />
                                                                                                        Take Profit %: <b>{order.settings.take_profit_percentage}</b> <br />
                                                                                                        Quantity: <b>{order.settings.quantity}</b> <br />
                                                                                                        Waiting time to fill Qty (H : M): <b>{order.settings.waitTimeForFillAllQty ? timeFormat(order.settings.waitTimeForFillAllQty) : '--'}</b> <br />
                                                                                                        {
                                                                                                            order.settings.roll_status  === 1 ?
                                                                                                            <>
                                                                                                        When to Roll? (H : M): <b>{(order.settings.roll_status==1 && order.settings.Roll_time_clock) ? (roll_time_clock[0]+ 'h ' +roll_time_clock[1] + 'm') : '--'}</b> <br />
                                                                                                        Distance from index (Roll): <b>{order.settings.index_distance}</b> <br />
                                                                                                        Premium Roll % (Roll): <b>{order.settings.roll_percentage}</b> <br />
                                                                                                            </>: '' 
                                                                                                        }
                                                                                                        Automatic market fill: <b>{order.settings.autoMarketFill === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Universal stop: <b>{order.settings.universal_stop === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        {order.settings.reentry_enabled ? (
                                                                                                                <>Re-Entry Enabled: <b>true</b><br/>Waiting time for Re-Entry: <b>{order.settings.wait_time_reentry ?timeFormat(order.settings.wait_time_reentry) :"--" }</b></>
                                                                                                            ) : <>Re-Entry Enabled: <b>false</b><br/></>}
                                                                                                    {order.settings.combined_sl_percentage ? <>Combined stop loss: <b>{Number(order.settings.combined_sl_percentage)}%</b><br/></>: ''} 
                                                                                                    {order.settings.combined_trailing_profit_percentage ? <>Combined trailing take profit: <b>{Number(order.settings.combined_trailing_profit_percentage)}%</b><br/></> : ''} 
                                                                                                    {order.settings.combined_trailing_stop_price_percentage ? <>Combined trailing stop price: <b>{Number(order.settings.combined_trailing_stop_price_percentage)}%</b><br/></> : ''} 
                                                                                                    {order.settings.combined_take_profit_percentage ? <>Combined take profit: <b>{Number(order.settings.combined_take_profit_percentage)}%</b><br/></> : ''} 
                                                                                                    </PopoverBody>
                                                                                                </Popover>
                                                                                            </div>
                                                                                        <div className="text-primary">Expiry: <span className="expiry-countdown font-size-11" ><Countdown date={order.expiry_date} renderer={counterRenderer} /></span></div>
                                                                                       
                                                                                        
                                                                                        {order.settings.current_index_price ? <div className="align-text-bottom created-date">Index Price: {order.settings.current_index_price}</div> : ''}
                                                                                        <div className=" font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.settings.created_at)}</div>
                                                                                        {(order.settings.automatic_bot && order.settings.automatic_bot ==1) ?  <Badge pill color="warning" className="me-1">Automated Admin Bot</Badge> : ''}
                                                                                        {order.settings.reentry_order?<><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> :''}
                                                                                        <div className="bg-transparent border-top p-2" style={{ marginTop: "13px" }}>
                                                                                            <div className="contact-links d-flex font-size-20">
                                                                                                <div className="flex-fill text-center">
                                                                                                <button
                                                                                                            type="button"
                                                                                                            onClick={() =>
                                                                                                                CloseAdminBot(order.admin_strangle_id)
                                                                                                            }
                                                                                                            className="btn btn-soft-danger btn-sm"
                                                                                                        >
                                                                                                            Close Bot
                                                                                                        </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </td>
                                                                                    : ''}
                                                                                    {index === 0 ?
                                                                                    <td  rowSpan={row.length}>
                                                                                        {order.profile ?
                                                                                        <p className="font-size-11">{capitalizeFirst(order.profile.first_name) } <br /> {capitalizeFirst(order.profile.last_name)}</p> : <p></p>}
                                                                                        <p>{order.profile.role == 'USER' ? <i className="bx bxl-telegram" onClick={() => sendMsg(order.profile.uid)} hidden={order.profile.telegram_updated == 1 ? false : true} style={{ cursor: "pointer" }}></i>  : ''}</p>

                                                                                    </td>
                                                                                    : ''}
                                                                                <td>
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                </td>
                                                                                <td>
                                                                                <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                                                                    <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                    {order.symbol}
                                                                                    </Badge></a>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-danger font-size-11 "> {market_action} </span>
                                                                                        </div>
                                                                                        : ''}
                                                                                        {order.reentry_stopped ? <div><span className=" font-size-10 ">Re-Entry Stopped</span></div> : ""}
                                                                                </td>

                                                                                <td>
                                                                                    <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{(order.average_fill_price && order.status=='closed' && order.direction !== "buy") ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6>
                                                                                    {order.highest_bid_now || order.lowest_ask_now ?
                                                                                        <div>
                                                                                            <span className=" font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                                                                            <span className="">, </span>
                                                                                            <span className=" font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                                                                        </div>
                                                                                        : ""
                                                                                    }
                                                                                </td>
                                                                                <td><div className="  font-size-10">
                                                                                <span className=""><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop Loss Activate: {getPrices(order, "trigger")}</span><br />
                                                                                <span className=""><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Market Stop Loss{order.settings.combined_sl?" (Combined)":""}: {getPrices(order, "stop_loss")}</span><br />
                                                                                <span className=""><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Take profit Price: {getPrices(order, "take_profit")}</span><br />
                                                                                <span className=""><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Min Take profit Price: {getPrices(order, "min_take_profit")}</span>
                                                                                 
                                                                                </div>
                                                                                </td>
                                                                                <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)}</td>
                                                                                <td className="font-size-11">{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(order) }}></span></td>
                                                                                <td>{order.last_trade_price ? order.last_trade_price.toFixed(1) : '...'}</td>
                                                                                <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}><small>{order.unrealized_pnl && order.unrealized_pnl!='' ? Math.floor(order.unrealized_pnl * 100) / 100 : '...'}</small></td>
                                                                                <td>
                                                                                <div className="d-flex gap-2">
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                getHistoryBysymbol(order.profile.uid,order.dbid,order.symbol)
                                                                                            }
                                                                                            className="text-info"
                                                                                            disabled={order.status.toLowerCase() === "waiting" ? true : false}
                                                                                        >
                                                                                            <i className="mdi mdi-history font-size-18"></i>
                                                                                        </a>
                                                                                        {stopStrangleFlag[order.symbol] && stopStrangleFlag[order.symbol] == 1 ?
                                                                                            <a className="btn btn-outline-danger btn-sm" style={{ "marginLeft": "10px" }} >
                                                                                                <div className="spinner-border text-danger  spinner-border-sm" role="status">
                                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                                </div>
                                                                                            </a>
                                                                                            : <a onClick={() =>
                                                                                                marketCloseOrder(order.profile.uid,order)
                                                                                            }
                                                                                                className="text-danger" 
                                                                                            >
                                                                                                <i className="mdi mdi-delete font-size-18"></i>
                                                                                            </a>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                {/*console.log(stopStrangleFlag[order.strangle_id])*/}
                                                                                {index === 0 ?
                                                                                    !stopStrangleFlag[order.strangle_id] ?
                                                                                        order.strangle_status.toLowerCase() === 'closed' ?
                                                                                            <td className=" align-middle  " rowSpan={row.length}>
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexDirection: "row",
                                                                                                        justifyContent: "space-evenly",
                                                                                                        alignItems: "center",
                                                                                                        textAlign: "center"
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={() =>
                                                                                                                marketCloseStrangle(order.profile.uid,order.strangle_id, 'market_close')
                                                                                                            }
                                                                                                            className="btn btn-soft-danger btn-sm"
                                                                                                        >
                                                                                                            Market Close
                                                                                                        </button></div>
                                                                                                   
                                                                                                </div>
                                                                                                {order.settings.reentry_enabled && order.settings.reentry_enabled==1 ? <div style={{ 'marginTop': "10px" }}> <button type="button" onClick={() => stopReentry(order.strangle_id)} className="btn btn-soft-danger  btn-sm">Stop Re-Entry</button> </div> : ''}
                                                                                            </td>
                                                                                            : stopStrangleFlag[order.strangle_id] && stopStrangleFlag[order.strangle_id] == 1 ? <td rowSpan={row.length}><div style={{ "color": "red" }}><Spinner animation="border" size="sm" variant="danger" />Stopping the order(s)..</div></td> : <td className=" align-middle" rowSpan={row.length}>
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexDirection: "row",
                                                                                                        justifyContent: "space-evenly",
                                                                                                        alignItems: "center",
                                                                                                        textAlign: "center"
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={() =>
                                                                                                                marketCloseStrangle(order.profile.uid,order.strangle_id, 'stop')
                                                                                                            }
                                                                                                            className="btn btn-soft-danger btn-sm "
                                                                                                        >
                                                                                                            Stop
                                                                                                        </button>
                                                                                                    </div>
                                                                                                  
                                                                                                </div>
                                                                                            </td>
                                                                                        : stopStrangleFlag[order.strangle_id] && <td rowSpan={row.length}><div style={{ "color": "red" }}><Spinner animation="border" size="sm" variant="danger" /> Closing</div> 											</td>
                                                                                    : ''}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                  )
                                                                })}
                                                                </tbody>
                                                            )
                                                        })
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    No orders found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                                {/*********/}
                                            </div>
                                     
                                   
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*Listing orders */}
            <div className="page-content">

            </div>
            <HistoryModal
                show={modalShow.show}
                onHide={() => setModalShow({ symbol: "", show: false })}
                data={historyData}
                symbolname={modalShow.symbol}
            />
        </React.Fragment>
    );
}
export default ListOptions;
