import React, { useCallback, useEffect, useState } from "react"

import {
  Badge,  
  Card,
  CardBody,  
  Col,
  Container,  
  Row,  
  Table,
  Tooltip  
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { formatUTCDate, CustomParseFloat, getCalendarTypeName, getFirstPositionName, getFillTypeName, formatUTCDateTime, getOrderStatusNameStyle, counterRenderer, getMarketActionTriggerName, getOrderLogs,timeFormat } from "services/common.service"
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import Swal from "sweetalert2"
import HistoryModal from "components/Modal/HistoryModal"
import { closeAdminBot, marketClose, marketCloseOrderAPI, stopBot,stopReentryAPI } from "helpers/straddle_helper"

const ActiveStraddles = () => {
 
  firebase.auth();
  //Current User  
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  //meta title
  //document.title="Active Straddles";
  const firestoreDb = firebase.firestore();
  const [ttop, setttop] = useState(false);
  //Active Straddles
  const [activeStraddles, setActiveStraddles] = useState([]);
  const [modalShow, setModalShow] = useState(false);	
  const [orderHistoryData, setOrderHistoryData] = useState([]);

  const getActiveStraddles = useCallback(() => {	     
    const activeStraddlesResult = firestoreDb.collectionGroup("straddle_orders").where("admin_bot", '==', true).where("admin_id", '==', uid).orderBy("created_at", "desc");
    
    activeStraddlesResult.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.dbid = doc.id;
        items.push(item);
        // Display if any notifications
        let currentdate = new Date().toISOString()
        let currentdateTime = new Date(currentdate).getTime();
        if (item.notification && item.notification.timestamp >= currentdateTime) {      
          if (item.notification.type === 'success') {          
            toast.success(item.notification.message, { toastId: item.notification.toast_id });
          } else {
            toast.error(item.notification.message, { toastId: item.notification.toast_id });    
          }        
        }            
      });
      
      // Combine orders by admin straddle id
      const all_straddles = items.reduce(function (row, obj) {
        row[obj.admin_straddle_id] = row[obj.admin_straddle_id] || [];
        row[obj.admin_straddle_id][obj.straddle_id] = row[obj.admin_straddle_id][obj.straddle_id] || [];
        row[obj.admin_straddle_id][obj.straddle_id].push(obj);
        return row;
      }, []);    
      
      // Find straddle status - to enable market close
      const active_straddles = Object.keys(all_straddles).map(admin_straddle_index => {         
        return Object.keys(all_straddles[admin_straddle_index]).map(straddle_index => {          
          const rows = all_straddles[admin_straddle_index][straddle_index];
          const filled_order = rows.find(element => element.status.toLowerCase() === 'closed' || element.filled_quantity > 0);
          let is_market_close = rows.find(element => element.market_action && element.market_action.toLowerCase() === 'market_close');
          if (!is_market_close) {
            const market_closed_rows = rows.filter(element => element.market_action && (element.market_action.toLowerCase() === 'market_close' || element.market_action.toLowerCase() === 'market_close_single'));          
            if (rows.length === market_closed_rows.length) {
              is_market_close = true;
            }
          }
          const straddle_status = (filled_order) ? "closed" : "open";      
          return rows.map((row,index) => {
            row.straddle_status = straddle_status;
            row.is_market_close = (is_market_close)?true:false;
            return row
          })          
        });
      });
      
      setActiveStraddles(active_straddles);		  
    });  
    
  }, []); 
  const getLogsBysymbol = async (uid,dbid,symbol) => {
    try {  
      var body =  {
        uid : uid,
        strategy : "straddles",
        dbid : dbid
      }  
      let order_history= await getOrderLogs(body);
      if(order_history){
          setModalShow({
            dbid: dbid,
            show: true,
            symbol:symbol
          });
          setOrderHistoryData(order_history);
        } else {
          setOrderHistoryData([]);
          setModalShow({
            dbid: dbid,
            show: true,
            symbol:symbol
          });
        }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);        
      } else if (err.message) {
        toast.error(err.message);         
      } else if (err.request) {
        //console.log(err.message);
      } else {        
        //console.log(err.message);
      }
    }
  };
  // const getLogsBysymbol = async (uid,symbol) => {
  //   try {    
  //     var firestoreDb1 = firestoreDb.collection("users").doc(uid);
  //     await firestoreDb1
  //       .collection("straddle_orders_log")
  //       .doc(symbol)
  //       .get()
  //       .then(async (doc) => {          
  //         if (doc.exists) {
  
  //           let order_history = [];
  
  //           if (doc.data().order_history) {
  //             order_history = doc.data().order_history;
  //             order_history.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
  //           } 
  
  //           setModalShow({
  //             symbol: symbol,
  //             show: true,
  //           });
  //           setOrderHistoryData(order_history);
  //         } else {
  //           setOrderHistoryData([]);
  //           setModalShow({
  //             symbol: symbol,
  //             show: true,
  //           });
  //         }
  //       });
  //   } catch (err) {
  //     if (err.response) {
  //       toast.error(err.response.data.message);        
  //     } else if (err.message) {
  //       toast.error(err.message);         
  //     } else if (err.request) {
  //       //console.log(err.message);
  //     } else {        
  //       //console.log(err.message);
  //     }
  //   }
  // };
  // Stop straddle
  const stopStraddleBot = async (uid,straddle_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async(result) => {
        if (result.isConfirmed) {
        toast.success('Stop straddle was initiated.');
        const params = {
          uid: uid,
          straddle_id: straddle_id        
        };
        await stopBot(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);                  
            }
        }).catch(err => {
          handleError(err);
        });
        }
      })
    } catch (err) {      
      handleError(err)
    }
  };

  const marketCloseOrder = async (uid,order) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          toast.success('Close order was initiated.',{ toastId: 'close_order_initiated_'+order.symbol });
          const params = {  
            uid: uid,
            symbol: order.symbol,
            dbid:order.id
          };
          await marketCloseOrderAPI(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err); 
    }
  };
  
  const marketCloseStraddle = async (uid,straddle_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async(result) => {
        if (result.isConfirmed) {
          toast.success('Market close was initiated.');
          const params = {
            uid: uid,
            straddle_id: straddle_id        
          };
          await marketClose(params).then((res) => {
              if (res.data !== "") {
                toast.success(res.data.message);          
              }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err)  
    }
  };
  const stopReentry = async (straddle_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to stop reentry ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          toast.success('Stop reentry was initiated.',{ toastId: 'stop_reentry_initiated_'+straddle_id });
          const params = {
            straddle_id: straddle_id
          };
          await stopReentryAPI(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err);
    }
  };

  const CloseAdminBot = async (admin_straddle_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async(result) => {
        if (result.isConfirmed) {
          toast.success('Closing bot was initiated.');
          const params = {        
            admin_straddle_id: admin_straddle_id        
          };
          await closeAdminBot(params).then((res) => {
              if (res.data !== "") {
                toast.success(res.data.message);          
              }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err)
    }
  };

  const sendMsg = async (user_id) => {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Message',
      buttonsStyling: false,
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-secondary",
      reverseButtons: true,
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true
    })
    
    if (text) {
      const bodyparams = {
        user_id :user_id,
        msg : text
      }
      await sendUserMessage(bodyparams).then((res) => {
        toast.success('Message sent successfully');
      }).catch(err => {
        toast.error('Chat Not Found');
        throw err;
      });    
    }
  }

  useEffect(() => { 
    let mounted = true;
    if (mounted) {
      getActiveStraddles();
    }         
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  },[]);

  return (
    <React.Fragment>
      <div className="page-content">        
        <Container fluid>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Active Straddles" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Orders</h4>
                  <div className="table-responsive">
                    {/*********/}
                    <Table className="table table-bordered table align-middle table-nowrap table-check">
                        <thead >
                            <tr>
                                <th>Expiry Date</th>
                                <th>User</th>                                                       
                                <th>Side</th>
                                <th>Symbol</th>
                                <th>Sell Price</th>
                                <th>Stop Price</th>
                                <th>Filled/Qty</th>
                                <th>Order ID</th>
                                <th>Status</th>
                                <th>LTP</th>
                                <th className="text-decoration-underline" >
                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop}
                                    target="TooltipTop"
                                    toggle={() => {
                                      setttop(!ttop);
                                    }}
                                  >
                                    Sell order: Price - Lowest ask <br /> Buy order: Highest bid - Price
                                  </Tooltip>
                                  <span id="TooltipTop">UPNL</span>
                                </th>                                
                                <th className="text-center" colSpan={2}>Action</th>
                            </tr>
                        </thead>
                        {activeStraddles.length > 0 ? (
                            activeStraddles.map((users, index) => {
                                return (
                                    <tbody key={index}>
                                    {users.map((row, row_index) => {
                                      return (
                                        row.map((order, index, arr) => {
                                            
                                            return (
                                                <tr className="trow-hover" key={index}>
                                                    {row_index===0 && index === 0 ?
                                                        <td rowSpan={(users.length * row.length)}>
                                                            <div className="p-2">
                                                            <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                            <div className="text-primary">
                                                              Expiry: 
                                                              <span className="expiry-countdown font-size-11" >
                                                                <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                                              </span>
                                                            </div>                                              
                                                            {order.order_settings.current_index_price ? <div className="font-size-12">Index Price: {Number(order.order_settings.current_index_price).toFixed(2)}</div> : ''}
                                                            {order.order_settings.trigger_percentage ? <div className="font-size-12">Stop Loss Activate: {Number(order.order_settings.trigger_percentage)}%</div> : ''}
                                                            {order.order_settings.stop_loss_percentage ? <div className="font-size-12">Market Stop Loss{order.order_settings.combined_sl?" (Combined)":""}: {Number(order.order_settings.stop_loss_percentage)}%</div> : ''}
                                                            {order.order_settings.stop_loss_index_percentage ? <div className="font-size-12">Index Price Range for Stop Loss: {Number(order.order_settings.stop_loss_index_percentage)}%</div> : ''}
                                                            {order.order_settings.minimum_take_profit_percentage ? <div className="font-size-12">Minimum take profit: {Number(order.order_settings.minimum_take_profit_percentage)}%</div> : ''}
                                                            {order.order_settings.trailing_take_profit_percentage ? <div className="font-size-12">Trailing take profit{order.order_settings.combined_trailing_profit?" (Combined)":""}: {Number(order.order_settings.trailing_take_profit_percentage)}%</div> : ''}
                                                            {order.order_settings.reentry_enabled ? (<div className="font-size-12">Waiting time for Re-Entry: {order.order_settings.wait_time_reentry ?timeFormat(order.order_settings.wait_time_reentry) :"--"}</div>): ''} 
                                                            {order.order_settings.combined_sl_percentage ? <div className="font-size-12">Combined stop loss: {Number(order.order_settings.combined_sl_percentage)}%</div> : ''} 
                                                            {order.order_settings.combined_trailing_take_profit_percentage ? <div className="font-size-12">Combined trailing take profit: {Number(order.order_settings.combined_trailing_take_profit_percentage)}%</div> : ''} 
                                                            {order.order_settings.combined_trailing_stop_price_percentage ? <div className="font-size-12">Combined trailing stop price: {Number(order.order_settings.combined_trailing_stop_price_percentage)}%</div> : ''} 
                                                            
                                                            <div className="text-muted font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.created_at)}</div>
                                                            {order.reentry_order?<><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> :''}
                                                            <div className="bg-transparent border-top border-white p-2 mt-3">
                                                              <div className="contact-links d-flex font-size-20">
                                                                <div className="flex-fill text-center">
                                                                  <button type="button"
                                                                  onClick={() => CloseAdminBot(order.admin_straddle_id)}className="btn btn-soft-danger btn-sm">
                                                                    Close Bot
                                                                    </button>
                                                                  </div>
                                                              </div>
                                                              </div>                                                         
                                                            </div>
                                                        </td>
                                                    : ''}
                                                    {index === 0 ?
                                                    <td className="td-vertical-align" rowSpan={row.length}>
                                                        {order.profile ?
                                                        <p className="font-size-11 text-captialize">{order.profile.first_name} {order.profile.last_name}</p> : <p></p>}
                                                        <p>{order.profile.role == 'USER' ? <i className="bx bxl-telegram" onClick={() => sendMsg(order.profile.uid)} hidden={order.profile.telegram_updated == 1 ? false : true} style={{ cursor: "pointer" }}></i>  : ''}</p>

                                                    </td>
                                                    : ''}                                                    
                                                    <td>
                                                      <Badge
                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                        style={{ "textTransform": "uppercase" }}
                                                      >
                                                        {order.direction}
                                                      </Badge>
                                                    </td>
                                                    <td>
                                                      
                                                    <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                                      <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                        {order.symbol}
                                                      </Badge>
                                                      </a>
                                                      {order.market_action_trigger ?
                                                        <div>
                                                          <span className="text-muted font-size-10 ">{getMarketActionTriggerName(order)} </span>
                                                        </div>
                                                        : 
                                                        order.is_min_take_profit_reached ?
                                                        <div>
                                                          <span className="text-muted font-size-10 d-block">Minimum Take Profit reached: {order.minimum_take_profit_price}</span>
                                                          <span className="text-muted font-size-10 d-block">Trailing Take Profit Activated</span>
                                                        </div>
                                                          :
                                                        order.minimum_take_profit_price ?
                                                        <div>
                                                          <span className="text-muted font-size-10 d-block">Minimum Take Profit Price: {order.minimum_take_profit_price}</span>                                             
                                                        </div>
                                                         :     
                                                        ""
                                                      }
                                                    </td>

                                                    <td>
                                                      <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6>
                                                      {order.highest_bid_now || order.lowest_ask_now ?
                                                          <div>
                                                            <span className="text-muted font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                                            <span className="text-muted">, </span>
                                                            <span className="text-muted font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                                          </div>
                                                          : ""
                                                      }
                                                    </td>
                                                    <td>{order.stop_loss_price ? Number(order.stop_loss_price).toFixed(2) : "..."}</td>
                                                    <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}</td>
                                                    <td>{order.order_id ? order.order_id : "Nil"}</td>
                                                    <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span></td>
                                                    <td>{order.last_trade_price ? order.last_trade_price.toFixed(1) : '...'}</td>
                                                    <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}><small>{order.unrealized_pnl ? CustomParseFloat(order.unrealized_pnl, 2) + ' USDT' : '...'}</small></td>
                                                    <td>
                                                      <div className="d-flex gap-2 justify-content-center">
                                                        <a
                                                          onClick={() =>
                                                            getLogsBysymbol(order.profile.uid,order.dbid)
                                                          }
                                                          className="text-info"
                                                          disabled={order.status.toLowerCase() === "pending" ? true : false}
                                                        >
                                                          <i className="mdi mdi-history font-size-18"></i>
                                                        </a>
                                                        {order.market_action && order.market_action === "market_close_single" && order.market_action_status === 'pending' ?
                                                          <a className="text-danger"><span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span></a> 
                                                          :
                                                          order.market_action && order.market_action === "market_close_single" && order.market_action_status !== 'pending' ? ''
                                                          :
                                                          order.is_market_close? '' :
                                                          <a onClick={() => marketCloseOrder(order.profile.uid,order)} className="text-danger">
                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                          </a>
                                                        }
                                                      </div>
                                                    </td>
                                                    {index === 0 ?
                                                      order.straddle_status.toLowerCase() === 'closed' || order.is_market_close ?
                                                        <td className="align-middle" rowSpan={row.length}>
                                                          <div
                                                            style={{
                                                              
                                                              flexDirection: "row",
                                                              justifyContent: "space-evenly",
                                                              alignItems: "center",
                                                              textAlign: "center"
                                                            }}
                                                          >
                                                            {order.is_market_close ? 
                                                              <span className="text-sm">Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span>
                                                              :
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  marketCloseStraddle(order.profile.uid,order.straddle_id)
                                                                }
                                                                className="btn btn-soft-danger btn-sm"
                                                              >
                                                                Market Close
                                                              </button>                                                  
                                                            }
                                                            {order.order_settings.reentry_enabled==1 && order.step!==3?
                                                            <div><br /><button type="button" onClick={() => stopReentry(order.straddle_id)} className="btn btn-soft-danger btn-sm">Stop Re-Entry</button></div>
                                                            :''}
                                                          </div>
                                                        </td>
                                                        :
                                                        <td className="align-middle" rowSpan={row.length}>
                                                          <div
                                                            style={{
                                                             
                                                              flexDirection: "row",
                                                              justifyContent: "space-evenly",
                                                              alignItems: "center",
                                                              textAlign: "center"
                                                            }}
                                                          >
                                                            {!order.is_stop_triggered ?
                                                            <>
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  stopStraddleBot(order.profile.uid,order.straddle_id)
                                                                }
                                                                className="btn btn-danger btn-sm"
                                                              >
                                                                Stop
                                                              </button>
                                                              { order.order_settings.reentry_enabled==1 && order.step!==3 && order.status.toLowerCase() == "open"?
                                                                <div><br /><button type="button" onClick={() => stopReentry(order.straddle_id)} className="btn btn-soft-danger btn-sm">Stop Re-Entry</button></div>
                                                                :''}
                                                                </>
                                                              :
                                                              <span className="text-sm">Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span>
                                                            }
                                                          </div>
                                                        </td>
                                                      : ''}
                                                </tr>
                                            )
                                        })

                                      )})}
                                    </tbody>
                                )
                            })
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={13} style={{ textAlign: "center" }}>
                                        No orders found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                    {/*********/}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <HistoryModal
                show={modalShow.show}
                onHide={() => setModalShow({ symbol: "", show: false })}
                data={orderHistoryData}
                symbolname={modalShow.symbol}
        />
      </div>
    </React.Fragment>
  )
}

export default ActiveStraddles