import React, { useState } from "react";
import {
  Modal,
  Button,
  Table,
  Badge,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { formatUTCDateTime } from "../../services/common.service";

function HistoryModal(props) {
  //console.log('historyyyyyy...',props);
  const [orderBook, setOrderBook] = useState({ ask: "", bid: "" });

  const getParsedData = (values) => {    
    let asks = values.map((val, index) => {
      return (<p key={index}>{val.price?val.price:val.limit_price}</p>);
    });

    return asks;
  };
  
  const popover = (
    <Popover id="popover-basic" className="bg-dark">
    <Popover.Header as="h3">Order Book Snapshots</Popover.Header>
    <Popover.Body>
      <div className="row">
        <div className="col-md-6">
          <h5 className="text-info">Ask</h5>
          {orderBook.ask && getParsedData(JSON.parse(orderBook.ask))}
        </div>

        <div className="col-md-6">
          <h5 className="text-info">Bid</h5>
          {orderBook.bid && getParsedData(JSON.parse(orderBook.bid))}
        </div>
      </div>
    </Popover.Body>
  </Popover>
  );  

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="strangle-box"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Order History ({props.symbolname})</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="panel panel-default">
            <div className="panel-body table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Side</th>
                    <th>Symbol</th>
                    <th>Sell/Buy Price</th>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Ask & Bids</th>
                  </tr>
                </thead>
                <tbody>
                  { props.data.length > 0 ? (
                    props.data.map((history, index) => {
                   //let history=hist.data[0]
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Badge
                            bg={
                              history.side === "buy" ? "success" : "danger"
                            }
                            style={{ textTransform: "uppercase" }}
                          >
                            {history.side}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            bg={
                              history.side === "buy" ? "success" : "danger"
                            }
                          >
                            {history.product_symbol}
                          </Badge>
                        </td>
                        <td>{parseFloat(history.limit_price).toFixed(2)}</td>
                        <td>
                          {history.id !== "" ? history.id : "Nil"}
                        </td>
                        <td>{history.state.toLowerCase()==='open' ? 'Open Order' : history.state.toLowerCase()==='cancelled' ? 'Cancelled' : history.state.toLowerCase()==='closed' ? 'Open Position': history.state}</td>
                        <td>
                          {/* {moment(history.updated_at).format(
                            "DD/MM/YYYY hh:mm:ss a"
                          )} */}
                          {formatUTCDateTime(history.updated_at)}
                        </td>
                        <td colSpan={2}>
                          
                        <OverlayTrigger
                            rootClose={true}
                            trigger="click"
                            placement="right"
                            overlay={popover}
                          >
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() =>
                                setOrderBook({
                                  ask: JSON.stringify(history.orderBookAsks),
                                  bid: JSON.stringify(history.orderBookBids),
                                })
                              }
                            >
                              Ask & Bids
                            </button>
                          </OverlayTrigger>
                          
                        </td>
                      </tr>
                    )
                  })
                  ) : (
                    <tr>
                      <td colSpan={8} style={{ textAlign: "center" }}>
                        No history found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "rgb(0 207 190)",
            border: "1px solid rgb(0 207 190 / 50%)",
            boxShadow: "none",
            borderRadius: "8px",
            textTransform: "none",
            cursor: "pointer",
          }}
        >
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}
export default HistoryModal;
