import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form, 
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardHeader,
    CardText,
    UncontrolledTooltip,
    CardTitle, Spinner, Alert,Modal,FormGroup
} from "reactstrap";
import parse from 'html-react-parser';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { Link } from "react-router-dom";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import api from '../../../services/api.service';
import { formatUTCDate,getSecondsFromTime, get_actual_index,counterRenderer,capitalizeFirst,getExpiryDates } from "../../../services/common.service";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import Countdown from "react-countdown"
import Switch from "react-switch";
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import Select from "react-select"
import DatePicker from "react-flatpickr";
// import ToolTipData from '../../Settings/Settings.json'
const { default: axios } = require("axios");
import { MinimumTakeProfit,TaillingTakeProfit } from "../../../helpers/help-msg";
const ListOptions = (props) => {
    firebase.auth();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
      //tooltip
   const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
   const [ToolTipData, setToolTipData] = useState([]);
   useEffect(()=>{
     try{
       const gettoottipData = async () => {
       
           const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
           // console.log(response)
             setToolTipData(JSON.parse(JSON.stringify(response.data)))
         
         }
 
         gettoottipData()
       }catch(error){
           console.log(error)
       }
 
   },[apiUrl])
   //tooltip
    let uid = currentUser.uid;
    const [activeTab, setActiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [expiryDates, setExpiryDates] = useState([]);
    const [bestStrikes, setBestStrikes] = React.useState("");
    const [selectedExpiryDate, setSelectedExpiryDate] = useState("")
    const [validateStrikes, setValidateStrikes] = useState("")
    //for settings
    const [settings, setSettings] = useState([]);
    const [stranglebot, setStranglebot] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [selectionType, setSelectionType] = useState('all-users');
    const [selectedUsers, setSelectedUsers] = useState([]);	
    const [failedUserDetails,setfailedUserDetails]= useState([]);
    const [visibleMin, setVisibleMin] = useState(false);
    const [visibleTailling, setVisibleTailling] = useState(false);
    const [MintakeProfitMsg,setMintakeProfitMsg] = useState();
    const [TaillingTakeProfitMsg,setTaillingTakeProfitMsg] = useState();
    const [SlTriggerStrangles, setSlTriggerStrangles]=useState(true);
    const [TraillingStrangles,setTraillingStrangles]=useState(false);
    //const [balancearr, setBalancearr] = useState([]);	
    //for settings -end
    const [strangleswait_time_reentry, setstrangleswait_time_reentry] = useState(0);
    const [reentry_enabled, setreentry_enabled] = useState(false);
    const [time_to_close_orders_before_expiry, settime_to_close_orders_before_expiry] = useState(0);
    function toSeconds(hours, minutes, seconds) {
        return hours * 3600 + minutes * 60 + seconds;
      }
    const [loading, setLoading] = useState({
        loading: false
    });
    const Offsymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            No
          </div>
        );
      }; 
      
      const OnSymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            Yes
          </div>
        );
      };
      function toHoursAndMinutes(totalSeconds) {
        const totalMinutes = Math.floor(totalSeconds / 60);
      
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        return hours + ":" + minutes ;
      }
      const handleDH = (e,strategy = '') => {
        var time = e[0]
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var H_M = toSeconds(hours, minutes, 0);
        if (strategy == 'strangleswait_time_reentry'){
          setstrangleswait_time_reentry(H_M)
        }else if (strategy == 'time_to_close_orders_before_expiry'){
            settime_to_close_orders_before_expiry(H_M)
          }
      }
    const handleError = (err) => {
        if (err.response && err.response.data) {
            toast.error(err.response.data.message);
        } else if (err.message) {
            toast.error(err.message);
        } else if (err.request) {
        } else {
        }

    }
    function toggleTab(tab) {
        if (activeTab == 1) {
            if(selectionType === 'selected-users' && selectedUsers.length ===0) {
                toast.error('No users selected');
                   return false;
            }
            setValidateStrikes("");
            setLoading({
                loading: false
            });
            setBestStrikes("")
            //get best strikes
            let exp_date = document.getElementById('exp_date').value;
            let index_percentage = document.getElementById('index_percentage').value;
            if (index_percentage > 0) {
                setLoading({
                    ...loading,
                    best_strikes: true,
                });

                const bodyParams = {
                    settlement_time: exp_date,
                    index_percentage: index_percentage
                };
                setSelectedExpiryDate(exp_date)
                api({ contentType: true, auth: true }).post('/delta/get-best-strikes', bodyParams).then(res => {
                    setBestStrikes(res.data.response.best_strikes)
                    // setLoading({
                    //     ...loading,
                    //     best_strikes: false,
                    // });
                }).catch(err => {
                    handleError(err)
                    // setLoading({
                    //     ...loading,
                    //     best_strikes: false,
                    // });
                }).finally(() => {
                    setLoading({
                        ...loading,
                        best_strikes: false,
                    });
                });
               
                var modifiedSteps = [...new Set(passedSteps), tab];
                 modifiedSteps = modifiedSteps.filter(element => element > tab);
                setActiveTab(tab);
                setPassedSteps(modifiedSteps)
            } else {
                toast.error('Enter Index Percentage');
                return false;

            }
        } else if (activeTab == 3 && tab > activeTab) {
            //start bot
            startBot(tab);


        } else if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                var modifiedSteps = [...new Set(passedSteps), tab];
                setActiveTab(tab);
                modifiedSteps = modifiedSteps.filter(element => element > tab);
                setPassedSteps(modifiedSteps)

            }
        }
    }
    const startBot = async (tab) => {
        if (stranglebot) {            
            setfailedUserDetails([]);
            //settings
         
              
                    setLoading({
                        ...loading,
                        start_bot: true,
                    });
                    //console.log("loading.best_strikes_validation", loading.best_strikes_validation)

                    settings.roll_status = settings.roll_status ? 1 : 0;
                    settings.universal_stop = settings.universal_stop ? 1 : 0;
                    if ((validateStrikes != '' && !loading.best_strikes_validation)) {
                        //console.log("symbol is already running")
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    } else if (settings.quantity == '') {
                        toast.error('Please enter quantity');
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    } else if (settings.roll_status==1 && ((!settings.roll_percentage && !settings.index_distance) || (!settings.roll_percentage && settings.index_distance) || (settings.roll_percentage && !settings.index_distance))) {
                        toast.error('Must Enter Roll percentage and distance from index');
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    } else {
                        //starting bot
                        const calls_margin = Number(bestStrikes.calls.order_margin);
                        const puts_margin = Number(bestStrikes.puts.order_margin);
                        const required_margin = Number(calls_margin) + Number(puts_margin);
                        settings.roll_percentage=(settings.roll_status==1)?settings.roll_percentage:'0';
                        settings.required_margin = required_margin;
                        settings.current_index_price = Number(bestStrikes.index_price).toFixed(2);
                        settings.expiry_date = bestStrikes.expiry_date;
                        settings.calls_amount = bestStrikes.calls.best_bid;
                        settings.puts_amount = bestStrikes.puts.best_bid;
                        settings.call_symbol = bestStrikes.calls.symbol;
                        settings.call_symbol_id = bestStrikes.calls.product_id;
                        settings.put_symbol = bestStrikes.puts.symbol;
                        settings.put_symbol_id = bestStrikes.puts.product_id;
                        settings.waitTimeForFillAllQty = getSecondsFromTime(settings.Qty_time_clock);
                        settings.waitTimeForRoll = getSecondsFromTime(settings.Roll_time_clock);
                        settings.combined_trailing_profit = TraillingStrangles == true ? 1 : 0;
                        settings.reentry_enabled = reentry_enabled == true ? 1 : 0;
                        settings.wait_time_reentry = strangleswait_time_reentry; 
                        settings.time_to_close_orders_before_expiry = time_to_close_orders_before_expiry;
                        let selected_qty={};
                        
                            //Selected users
                            let user_selection_type = selectionType;
                            let selected_user_uids;
                            if(user_selection_type === 'selected-users') {
                            let selected_user_uids_arr = selectedUsers.map(selected_user => selected_user.value);
                            if (selected_user_uids_arr.length) {
                                selected_user_uids = selected_user_uids_arr.join(",")
                            }          
                            }
                            let start_for_all;
                            let selected_users;
                            if (selectionType=='all-users') {
                                start_for_all =1;
                                selected_users='';
                            }else{
                                start_for_all =0;
                                selected_users=selected_user_uids;
                        
                            } 
                            settings.selected_users=selected_users;
                            settings.selected_qty=selected_qty;
						    settings.start_for_all=start_for_all;
                            settings.created_by=uid;
                            //////
                            await api({ contentType: true, auth: true }).post('/abotex/delta/start-bot', settings).then( async (res) => {                
                                toast.success('Orders Placed successully');  
                                if (res.data.response.failed_user_details && res.data.response.failed_user_details.length > 0) {  
                                    setfailedUserDetails(res.data.response.failed_user_details);
                                } 
                                var modifiedSteps = [...new Set(passedSteps), tab];
                                setActiveTab(tab);
                                modifiedSteps = modifiedSteps.filter(element => element > tab);
                                setPassedSteps(modifiedSteps)
                                setLoading({
                                    ...loading,
                                    start_bot: false,
                                });
                                
                                setLoading({
                                    ...loading,
                                    start_bot: false,
                                });
                              
                            }).catch(err => {                        
                              handleError(err)
                                      if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.failed_user_details && err.response.data.data.failed_user_details.length) { 
                                        setfailedUserDetails(res.data.response.failed_user_details);
                                        var modifiedSteps = [...new Set(passedSteps), tab];
                                        setActiveTab(tab);
                                        modifiedSteps = modifiedSteps.filter(element => element > tab);
                                        setPassedSteps(modifiedSteps)
                                        setLoading({
                                            ...loading,
                                            start_bot: false,
                                        });
                                    }else{
                                        setfailedUserDetails([]);
                                    }
                                    setLoading({
                                        ...loading,
                                        start_bot: false,
                                    });
                            })

                            //////
                    }
                
           
        } 
    }
    const getExpiryDateValues = useCallback(async () => {
        try {
            const bodyParams = {
              currency: 'BTC'
            };
            await getExpiryDates(bodyParams).then((res) => {
                setExpiryDates(res.data.response.expiry_dates);  
            }).catch(e => {
              throw e
            })
          } catch (e) {
            handleError(e);
          }
    });
    const get_settings = async () => {
        await api({ contentType: true, auth: true }).get('/delta/get-site-settings?type=bot-settings').then(res => {
            if(res.data.response && res.data.response.settings) {
                setSettings(res.data.response.settings);
                setSlTriggerStrangles(res.data.response.settings && res.data.response.settings.Sl_trigger_take_profit !== undefined ? res.data.response.settings.Sl_trigger_take_profit : true);
                setreentry_enabled(res.data.response.settings.reentry_enabled? (res.data.response.settings.reentry_enabled == 1 ? true : false):false);
              setstrangleswait_time_reentry(res.data.response.settings.wait_time_reentry? res.data.response.settings.wait_time_reentry : "0");
              settime_to_close_orders_before_expiry(res.data.response.settings &&  res.data.response.settings.time_to_close_orders_before_expiry ? res.data.response.settings.time_to_close_orders_before_expiry : "0");
            }
        });
        // var docRef = firebase.firestore().collection("site-settings").doc('bot-settings');
        // await docRef.get().then((doc) => {
        //     if (doc.exists) {
        //         const order_settings = doc.data();
        //         setSettings(order_settings);
        //     }
        // })
    }
    const get_users = async()=>{  
        try {
          const res = await api({ contentType: true, auth: true }).post('/abotex/delta/get-users-list');
          if (res.data.response.users_list) {
            let usersList=res.data.response.users_list
            let userids=[];
            usersList.forEach(async (doc) => {
				let userobj={}
				userobj.value=doc.uid
				userobj.label=capitalizeFirst(doc.display_text); 
				userids.push(userobj)
			});
            setUsersList(userids)
            setSelectedUsers(userids)
          }
          /*  const balance_query = await firebase.firestore().collectionGroup("balance_details");
            let balancearr=[]
            await balance_query.get().then((balances) => {
              balances.forEach(function(doc) {
                 
                  if (doc.exists) {
                    const balance = doc.data();
                   // console.log(balance,doc.ref.path.split('/')[1])
                    const uid = doc.ref.path.split('/')[1];        
                    balancearr[uid]=balance;
                  }
               });
            });
           
            setBalancearr(balancearr); */
        } catch (e) {
          handleError(e);
        }    
      }
    const getSiteSettings = async () => {
        setLoading({
            ...loading,
            site_setting: true,
        });
        await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
            if(res.data.response && res.data.response.settings) {
                setStranglebot(res.data.response.settings.enable_strangles);
            }
          }).catch(err => {
            throw err;
          })
          setLoading({
            ...loading,
            site_setting: false,
        })

        // var get_site_settings = await firebase.firestore().collection("site-settings").doc('data');
        // get_site_settings.get().then((res) => {
        //     if (res.exists) {
        //         const site_settings = res.data();
        //         setStranglebot(site_settings.enable_strangles)
        //     }
        //     setLoading({
        //         ...loading,
        //         site_setting: false,
        //     });
        // });
    }
    const handleSelectionType = (e) => {
        setSelectedUsers("")
    if (e.target.value === 'all-users') {
       setSelectedUsers(usersList);
     }
     setSelectionType(e.target.value);
     
   }
   function failedUserInfoDisplay(){
      let failedUserInfo=  failedUserDetails.map((detail,index) => { 
        return (<li  key={index}><span className="py-2 d-block"><i className="mdi mdi-chevron-right"></i> {detail.profile.first_name+' '+detail.profile.last_name} - <a className="text-danger">{detail.error}</a></span></li>)
        }) 
        return failedUserInfo;
   }
   function handleSelect(selectedList, selectedItem){
    setSelectedUsers(selectedList);
    }
    useEffect(() => {
        getSiteSettings();
        getExpiryDateValues();
        get_settings();
        get_users();
        setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit())   
    }, []);
    
       
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="DeriSnap" breadcrumbItem="Create Strangle" />
                    {loading.site_setting ? 
                     <Row>
                     <Col lg="12">
                         <Card>
                             <CardBody>
                    <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-hourglass bx-spin me-2" />
                          Loading
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  </CardBody>
                  </Card>
                  </Col>
                  </Row>
                    :<>
                    {stranglebot ? (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">Create Strangles</h4>
                                        <div className="wizard clearfix">
                                            <div className="steps clearfix">
                                                <ul>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 1 || (loading.best_strikes || loading.best_strikes_validation),
                                                        })}>
                                                        <NavLink
                                                            className={classnames({
                                                                active: activeTab === 1,
                                                            })}
                                                            onClick={() => {
                                                                toggleTab(1);
                                                            }}
                                                        >
                                                            <span className="number">1.</span>{" "}
                                                            Choose Expiry
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 2 && !(loading.best_strikes || loading.best_strikes_validation),
                                                        })}>
                                                        <NavLink
                                                            disabled={!(passedSteps || []).includes(2)}
                                                            className={classnames({
                                                                active: activeTab === 2,
                                                            })}
                                                            onClick={() => {
                                                                toggleTab(2);
                                                            }}
                                                        >
                                                            <span className="number">2.</span>{" "}
                                                            <span>Confirm Strikes</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 3,
                                                        })}>
                                                        <NavLink
                                                            disabled={!(passedSteps || []).includes(3)}
                                                            className={classnames({
                                                                active: activeTab === 3
                                                            }), 'done'}
                                                            onClick={() => {
                                                                toggleTab(3);
                                                            }}
                                                        >
                                                            <span className="number">3.</span>{" "}
                                                            Bot Setup
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 4,
                                                        })}>
                                                        <NavLink
                                                            disabled={!(passedSteps || []).includes(4)}
                                                            className={classnames({
                                                                active: activeTab === 4,
                                                            }), 'done'}
                                                            onClick={() => {
                                                                toggleTab(4);
                                                            }}
                                                        >
                                                            <span className="number">4.</span>{" "}
                                                            Bot Success
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            </div>
                                            <div className="content clearfix">
                                                <TabContent
                                                    activeTab={activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation) ? 1 : activeTab}
                                                    className="body"
                                                >
                                                    <TabPane tabId={1}>

                                                        <Row>
                                                            <Col >
                                                                <div className="mb-3">
                                                                    <Row className="mb-3">
                                                                    <Col lg="6">
                                                                    <label className="form-label">Users</label>{" "}<i className="bx bx-info-circle" id ="users" />
                                                                        <UncontrolledTooltip placement="top" target="users"> 
                                                                            {ToolTipData.users}
                                                                        </UncontrolledTooltip>
                                                                    <select className="form-select" id="selection_type"  onChange={(e)=>handleSelectionType(e)} value={selectionType}>
                                                                        <option value='all-users'> All Users </option>
                                                                        <option value='selected-users'> Specific Users </option>
                                                                    </select>
                                                                   
                                                                    </Col>
                                                                    {selectionType === 'selected-users'?
                                                                    <Col lg="6">
                                                                        <label className="form-label">Select users</label>  
                                                                        {/*<Multiselect options={usersList} displayValue="display_text" onSelect={handleSelect} onRemove={handleSelect} hidePlaceholder="true" emptyRecordMsg="No users available" /> */}
                                                                        <Select
                                                                            value={selectedUsers}
                                                                            isMulti={true}
                                                                            onChange={handleSelect}
                                                                            options={usersList}
                                                                            classNamePrefix="select2-selection"
                                                                            />
                                                                    </Col>                  
                                                                    :''}
                                                                    </Row>
                                                                    <Row>
                                                                     <Col sm="6" xl="3">   
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Expiration date
                                                                    </Label>{" "}<i className="bx bx-info-circle" id ="SED" />
                                                                        <UncontrolledTooltip placement="top" target="SED"> 
                                                                            {ToolTipData.expiration_date}
                                                                        </UncontrolledTooltip>
                                                                    <select className="form-select" id="exp_date">
                                                                        {
                                                                            expiryDates.map((index) => (
                                                                                <React.Fragment key={index}>
                                                                                    <option value={index}>{formatUTCDate(index)}</option>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    </Col>
                                                                    <Col sm="6" xl="3">   
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Select Index Percentage
                                                                    </Label>{" "}<i className="bx bx-info-circle" id ="Index" />
                                                                    <UncontrolledTooltip placement="top" target="Index"> 
                                                                        {ToolTipData.index_percentage}
                                                                    </UncontrolledTooltip>
                                                                    <div className="input-group">
                                                                        <Input autoComplete="off" 
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="index_percentage"
                                                                            placeholder="Enter Index %"  value={settings.index_percentage} onChange={(event) => setSettings({
                                                                                ...settings,
                                                                                index_percentage: event.target.value
                                                                            })}
                                                                        />
                                                                        <Label className="input-group-text" htmlFor="index_percentage">%</Label>
                                                                    </div>
                                                                    </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>

                                                        </Row>


                                                    </TabPane>
                                                    <TabPane tabId={2}>
                                                        {!loading.best_strikes ? (
                                                            <>
                                                                {bestStrikes ?
                                                                    <div>

                                                                        <Row>
                                                                            <Col lg="4">
                                                                                <Card outline color="primary" className="border">
                                                                                    <CardHeader className="bg-transparent">
                                                                                        <h5 className="my-0 text-primary">
                                                                                            <i className="mdi mdi-arrow-up-bold-outline me-1" />Calls
                                                                                        </h5>
                                                                                    </CardHeader>
                                                                                    <CardBody>
                                                                                        <CardTitle className="mt-0">{bestStrikes.calls.symbol}</CardTitle>
                                                                                        <CardText>
                                                                                            <ul className="list-group">
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{bestStrikes.calls.best_ask}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{bestStrikes.calls.best_bid}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {bestStrikes.calls.sell_price}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(bestStrikes.calls.order_margin).toFixed(2)} (1 cont)</li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {bestStrikes.calls.leverage} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Actual Index Percentage</b> : {get_actual_index(bestStrikes.calls.strike_price, bestStrikes.index_price)}</li></ul>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>

                                                                            <Col lg="4">
                                                                                <Card outline color="primary" className="border">
                                                                                    <CardHeader className="bg-transparent">
                                                                                        <h5 className="my-0 text-primary">
                                                                                            <i className="mdi mdi-arrow-down-bold-outline me-1" />Puts
                                                                                        </h5>
                                                                                    </CardHeader>
                                                                                    <CardBody>
                                                                                        <CardTitle className="mt-0">{bestStrikes.puts.symbol}</CardTitle>
                                                                                        <CardText >
                                                                                        <ul className="list-group">
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{bestStrikes.puts.best_ask}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{bestStrikes.puts.best_bid}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {bestStrikes.puts.sell_price}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(bestStrikes.puts.order_margin).toFixed(2)} (1 cont)</li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {bestStrikes.puts.leverage} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Actual Index Percentage</b> : {get_actual_index(bestStrikes.puts.strike_price, bestStrikes.index_price)}</li></ul>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                            <Col lg="4">
                                                                                <Card outline color="primary" className="border" style={{ "height": "94%" }}>
                                                                                    <CardHeader className="bg-transparent">
                                                                                        <h5 className="my-0 text-primary">
                                                                                            <i className="mdi mdi-information-outline me-1" />Other Informations
                                                                                        </h5>
                                                                                    </CardHeader>
                                                                                    <CardBody>
                                                                                        <CardTitle className="mt-0"></CardTitle>
                                                                                        <CardText>
                                                                                            <ul className="list-group">
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Selected Index Percentage</b> : {bestStrikes.index_percentage}% </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Contract Index</b> : {bestStrikes.contract_index} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Current Index Price</b>: {Number(bestStrikes.index_price).toFixed(2)} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Required Margin</b>: {Number(Number(bestStrikes.puts.order_margin) + Number(bestStrikes.calls.order_margin)).toFixed(2)} (1 cont)</li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Time to expire</b>:  <Countdown date={selectedExpiryDate} renderer={counterRenderer} /></li>

                                                                                            </ul>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>


                                                                    </div> : <Alert color="danger" role="alert">
                                                                        No strikes found, please check with some other expiry date / index percentage
                                                                    </Alert>}</>
                                                        ) : <Spinner className="ms-2" color="primary" />}
                                                    </TabPane>
                                                    <TabPane tabId={3}>
                                                        <div>
                                                            <Form>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                <Row>
                                                                                    <Col lg="6" style={{"borderRight":"1px dashed #556ee6"}}>
                                                                                       
                                                                                  
                                                                                        <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                            Market Stop Loss Percentage
                                                                                            {" "}<i className="bx bx-info-circle" id ="stoploss" />
                                                                                                <UncontrolledTooltip placement="top" target="stoploss"> 
                                                                                                    {ToolTipData.stop_loss}
                                                                                                </UncontrolledTooltip>
                                                                                                </Label>
                                                                                            </Col>
                                                                                            <Col>
                                                                                            <Slider value={settings.stop_loss_percentage?settings.stop_loss_percentage:0} min={0} max={100} onChange={value => setSettings({
                                                                                                ...settings,
                                                                                                stop_loss_percentage: value
                                                                                            })}/>
                                                                                            <div className="input-group">

                                                                                                <Input
                                                                                                    type="number" step="0.5" min="0" max="100"
                                                                                                    className="form-control"
                                                                                                    id="stop_loss" value={settings.stop_loss_percentage}
                                                                                                    onChange={(e) => { 
                                                                                                        const value = e.target.value;
                                                                                                        let newValue = 100; // Default value
                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                            newValue = '';
                                                                                                        } else if (value <= 100) {
                                                                                                            newValue = value;
                                                                                                        }
                                                                                                        setSettings({
                                                                                                            ...settings,
                                                                                                            stop_loss_percentage: newValue
                                                                                                        });
                                                                                                        }}
                                                                                                />
                                                                                                <Label className="input-group-text" htmlFor="stop_loss">%</Label>

                                                                                            </div>

                                                                                            {bestStrikes && settings.stop_loss_percentage && settings.stop_loss_percentage > 0 ? 
                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call market stop loss value<span className="fw-bold"> {((bestStrikes.calls.sell_price * (settings.stop_loss_percentage/100)) + Number(bestStrikes.calls.sell_price)).toFixed(2)}</span></p>
                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put market stop loss value <span className="fw-bold">{((bestStrikes.puts.sell_price * (settings.stop_loss_percentage/100)) + Number(bestStrikes.puts.sell_price)).toFixed(2)}</span></p></>:null}

                                                                                            {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                                                                                <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="combined_sl"
                                                                                                checked={settings.combined_sl ? settings.combined_sl : false}
                                                                                                onChange={(event) => setSettings({ ...settings, combined_sl: event.target.checked })}
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor="combined_sl">
                                                                                                Combined Stop Loss
                                                                                                </label>{" "}<i className="bx bx-info-circle" id ="Stop_loss_combined" />
                                                                                                <UncontrolledTooltip placement="top" target="Stop_loss_combined"> 
                                                                                                    {ToolTipData.stop_loss_combined}
                                                                                                </UncontrolledTooltip>
                                                                                            </div> */}
                                                                                            </Col>
                                                                                        </Row> 
                                                                                        <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                Index Price Range Percentage for Stop Loss <i className="bx bx-info-circle" id ="IPRPFSL" />
                                                                                                <UncontrolledTooltip placement="top" target="IPRPFSL"> 
                                                                                                    {ToolTipData.index_price_range}
                                                                                                </UncontrolledTooltip>
                                                                                            </Label>
                                                                                            </Col>
                                                                                            <Col>
                                                                                            <Slider value={settings.stop_loss_index_percentage?settings.stop_loss_index_percentage:0} min={0} max={100} onChange={value => setSettings({
                                                                                                ...settings,
                                                                                                stop_loss_index_percentage: value
                                                                                            })}/>
                                                                                            <div className="input-group">

                                                                                                <Input
                                                                                                    type="number" step="0.5" min="0" max="100"
                                                                                                    className="form-control"
                                                                                                    id="stop_loss_index" value={settings.stop_loss_index_percentage} 
                                                                                                    onChange={(e) => { 
                                                                                                        const value = e.target.value;
                                                                                                        let newValue = 100; // Default value
                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                            newValue = '';
                                                                                                        } else if (value <= 100) {
                                                                                                            newValue = value;
                                                                                                        }
                                                                                                        setSettings({
                                                                                                            ...settings,
                                                                                                            stop_loss_index_percentage: newValue
                                                                                                        });
                                                                                                        }}
                                                                                                />
                                                                                                <Label className="input-group-text" htmlFor="stop_loss_index">%</Label>
                                                                                            </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="mt-3">
                                                                                                        <Col lg="4">
                                                                                                        <Label className="mt-3">Trailing Take Profit Percentage</Label>{" "}<i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}/> 
                                                                                                        
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Slider value={settings.min_take_profit_percentage ? settings.min_take_profit_percentage : 0} step={0.5} min={0} max={100} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                min_take_profit_percentage: value
                                                                                                            })} />
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="0.5" min="0" max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="take_profit" value={settings.min_take_profit_percentage} 
                                                                                                                    onChange={(e) => { 
                                                                                                                        const value = e.target.value;
                                                                                                                        let newValue = 100; // Default value
                                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                                            newValue = '';
                                                                                                                        } else if (value <= 100) {
                                                                                                                            newValue = value;
                                                                                                                        }
                                                                                                                        setSettings({
                                                                                                                            ...settings,
                                                                                                                            min_take_profit_percentage: newValue
                                                                                                                        });
                                                                                                                        }}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                            </div>
                                                                                                            {bestStrikes && settings.min_take_profit_percentage && settings.min_take_profit_percentage > 0 ? 
                                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call trailing profit value<span className="fw-bold"> {(Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (settings.min_take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put trailing profit value <span className="fw-bold">{(( Number(bestStrikes.puts.sell_price))- bestStrikes.puts.sell_price * (settings.min_take_profit_percentage/100)).toFixed(2)}</span></p></>:null}
                                                                                                            <div className="form-check form-checkbox-outline form-check-success mt-3">
                                                                                                                <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input"
                                                                                                                id="StarangleMin"
                                                                                                                checked={SlTriggerStrangles}
                                                                                                                onChange={(event) => {setSlTriggerStrangles(event.target.checked);setSettings({
                                                                                                                    ...settings,
                                                                                                                    Sl_trigger_take_profit: event.target.checked
                                                                                                                })}}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="StarangleMin">
                                                                                                                Activate trailing take profit when stop loss triggered on one leg
                                                                                                                </label>{" "}
                                                                                                                {/* <i className="bx bx-info-circle" id ="SSLTMTP" />
                                                                                                                <UncontrolledTooltip placement="top" target="SSLTMTP"> 
                                                                                                                    {ToolTipData.stop_loss_trigger_market_take_profit}
                                                                                                                </UncontrolledTooltip> */}
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row className="mt-3">
                                                                                                        <Col lg="4">
                                                                                                        <Label className="mt-3">Trailing Stop Price Percentage</Label>{" "}<i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)} />
                                                                                                       
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Slider value={settings.trailing_take_profit_percentage ? settings.trailing_take_profit_percentage : 0} step={0.5} min={0} max={100} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                trailing_take_profit_percentage: value
                                                                                                            })} />
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="0.5" min="0" max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="take_profit" value={settings.trailing_take_profit_percentage}    onChange={(e) => { 
                                                                                                                        const value = e.target.value;
                                                                                                                        let newValue = 100; // Default value
                                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                                            newValue = '';
                                                                                                                        } else if (value <= 100) {
                                                                                                                            newValue = value;
                                                                                                                        }
                                                                                                                        setSettings({
                                                                                                                            ...settings,
                                                                                                                            trailing_take_profit_percentage: newValue
                                                                                                                        });
                                                                                                                        }}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                            </div>
                                                                                                            {bestStrikes && settings.trailing_take_profit_percentage && settings.trailing_take_profit_percentage > 0 ? 
                                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call trailing stop value<span className="fw-bold"> {(Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (settings.trailing_take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put trailing stop value <span className="fw-bold">{(( Number(bestStrikes.puts.sell_price))- bestStrikes.puts.sell_price * (settings.trailing_take_profit_percentage/100)).toFixed(2)}</span></p></>:null}
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                        <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                Take Profit Percentage
                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="TPP" />
                                                                                                <UncontrolledTooltip placement="top" target="TPP"> 
                                                                                                    {ToolTipData.take_profit}
                                                                                                </UncontrolledTooltip>
                                                                                            </Col>
                                                                                            <Col>
                                                                                            <Slider value={settings.take_profit_percentage?settings.take_profit_percentage:0} min={0} max={100} onChange={value => setSettings({
                                                                                                ...settings,
                                                                                                take_profit_percentage: value
                                                                                            })}/>
                                                                                            <div className="input-group">

                                                                                                <Input
                                                                                                    type="number" step="0.5" min="0" max="100"
                                                                                                    className="form-control"
                                                                                                    id="take_profit" value={settings.take_profit_percentage} 
                                                                                                    onChange={(e) => { 
                                                                                                        const value = e.target.value;
                                                                                                        let newValue = 100; // Default value
                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                            newValue = '';
                                                                                                        } else if (value <= 100) {
                                                                                                            newValue = value;
                                                                                                        }
                                                                                                        setSettings({
                                                                                                            ...settings,
                                                                                                            take_profit_percentage: newValue
                                                                                                        });
                                                                                                        }}
                                                                                                />
                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                            </div>
                                                                                            {bestStrikes && settings.take_profit_percentage && settings.take_profit_percentage > 0 ? 
                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call take profit value<span className="fw-bold"> {(Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (settings.take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put take profit value <span className="fw-bold">{(( Number(bestStrikes.puts.sell_price))- bestStrikes.puts.sell_price * (settings.take_profit_percentage/100)).toFixed(2)}</span></p></>:null}
                                                                                            {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                                                                                <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="StarangleTrailling"
                                                                                                checked={TraillingStrangles}
                                                                                                onChange={(event) => setTraillingStrangles(event.target.checked)}
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor="StarangleTrailling">
                                                                                                Combined Profit
                                                                                                </label>{" "}
                                                                                               
                                                                                            </div>  */}
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="mt-3">
                                                                                                <Col  lg="4">
                                                                                                <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                    Quantity
                                                                                                </Label>{" "}<i className="bx bx-info-circle" id ="QTY2" />
                                                                                                    <UncontrolledTooltip placement="top" target="QTY2"> 
                                                                                                        {ToolTipData.quantity}
                                                                                                    </UncontrolledTooltip>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                <Slider value={settings.quantity?settings.quantity:1} min={1} max={1000} onChange={value => setSettings({
                                                                                                    ...settings,
                                                                                                    quantity: value
                                                                                                })}/>
                                                                                                <div className="input-group">

                                                                                                    <Input
                                                                                                        type="number" step="1" min="1" max="100"
                                                                                                        className="form-control"
                                                                                                        id="qty" value={settings.quantity?settings.quantity:1} onChange={(event) => setSettings({
                                                                                                            ...settings,
                                                                                                            quantity: event.target.value
                                                                                                        })}
                                                                                                    />
                                                                                                    <Label className="input-group-text" htmlFor="qty">Cont</Label>
                                                                                                </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                                <Label htmlFor="formrow-combined-sl" className="mt-3">
                                                                                                Combined Stop Loss %
                                                                                                {" "}
                                                                                                <i className="bx bx-info-circle" id ="combined_sl_trigger" />
                                                                                                    <UncontrolledTooltip placement="top" target="combined_sl_trigger">
                                                                                                        {ToolTipData.stop_loss_combined}
                                                                                                    </UncontrolledTooltip>
                                                                                                </Label>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Slider value={settings.combined_sl_percentage?settings.combined_sl_percentage:0} min={0} max={100} step={0.5} onChange={value => {setSettings({
                                                                                                    ...settings,
                                                                                                    combined_sl_percentage: value
                                                                                                })}} />
                                                                                                <div className="input-group">
                                                                                                <input  type="number"  max={100} step={0.5} value={settings.combined_sl_percentage} onChange={(e) => { 
                                                                                                const value = e.target.value;
                                                                                                let newValue = 100; // Default value
                                                                                                if (value === null || value === '' || value < 0) {
                                                                                                    newValue = '';
                                                                                                } else if (value <= 100) {
                                                                                                    newValue = value;
                                                                                                }
                                                                                                setSettings({
                                                                                                    ...settings,
                                                                                                    combined_sl_percentage: newValue
                                                                                                });
                                                                                                }} onBlur={(e) => { 
                                                                                                    const value = e.target.value;
                                                                                                    let newValue = 100; // Default value
                                                                                                    if (value === null || value === '' || value < 0) {
                                                                                                        newValue = '';
                                                                                                    } else if (value <= 100) {
                                                                                                        newValue = value;
                                                                                                    }
                                                                                                    setSettings({
                                                                                                        ...settings,
                                                                                                        combined_sl_percentage: newValue
                                                                                                    });
                                                                                                    } } name="combined_sl_percentage" className="form-control" />
                                                                                                     <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                    </div>
                                                                                                  
                                                                                                     {bestStrikes && settings.combined_sl_percentage && settings.combined_sl_percentage > 0 ? 
                                                                                                    <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined stop loss value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))+((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_sl_percentage/100))).toFixed(2)}</span></p>
                                                                                                    :null}
                                                                                            </Col>
                                                                                            </Row>
                                                                                            
                                                                                            <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label htmlFor="formrow-combined-trail-profit" className="mt-3">
                                                                                                Combined Trailing Profit %   {" "}
                                                                                            <i className="bx bx-info-circle" id ="combined_trail_pro_trigger" />
                                                                                                <UncontrolledTooltip placement="top" target="combined_trail_pro_trigger">
                                                                                                    {ToolTipData.combined_trailing_profit_percentage}
                                                                                                </UncontrolledTooltip>
                                                                                                </Label>
                                                                                            </Col>
                                                                                            <Col>
                                                                                            <Slider value={settings.combined_trailing_profit_percentage?settings.combined_trailing_profit_percentage:0} min={0} max={100} step={0.5} onChange={value => {setSettings({
                                                                                                    ...settings,
                                                                                                    combined_trailing_profit_percentage: value
                                                                                                })}} />
                                                                                                <div className="input-group">

                                                                                            <input  type="number"    max={100} step={0.5} value={settings.combined_trailing_profit_percentage} onChange={(e) => { 
                                                                                                        const value = e.target.value;
                                                                                                        let newValue = 100; // Default value
                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                            newValue = '';
                                                                                                        } else if (value <= 100) {
                                                                                                            newValue = value;
                                                                                                        }
                                                                                                        setSettings({
                                                                                                            ...settings,
                                                                                                            combined_trailing_profit_percentage: newValue
                                                                                                        });
                                                                                                        }  } onBlur={(e) => { 
                                                                                                            const value = e.target.value;
                                                                                                            let newValue = 100; // Default value
                                                                                                            if (value === null || value === '' || value < 0) {
                                                                                                                newValue = '';
                                                                                                            } else if (value <= 100) {
                                                                                                                newValue = value;
                                                                                                            }
                                                                                                            setSettings({
                                                                                                                ...settings,
                                                                                                                combined_trailing_profit_percentage: newValue
                                                                                                            });
                                                                                                            } } name="combined_trailing_profit_percentage" className="form-control" />
                                                                                                             <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                             </div>
                                                                                                               {bestStrikes && settings.combined_trailing_profit_percentage && settings.combined_trailing_profit_percentage > 0 ? 
                                                                                                    <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined trailing profit value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))-((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_trailing_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                    :null}
                                                                                            </Col>
                                                                                            </Row>

                                                                                            <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label htmlFor="formrow-combined-trail-sl" className="mt-3">
                                                                                                Combined Trailing Stop Price %
                                                                                                {" "}
                                                                                            <i className="bx bx-info-circle" id ="combined_trail_sl_trigger" />
                                                                                                <UncontrolledTooltip placement="top" target="combined_trail_sl_trigger">
                                                                                                    {ToolTipData.combined_trailing_stop_price_percentage}
                                                                                                </UncontrolledTooltip>
                                                                                                    </Label>
                                                                                            </Col>
                                                                                            <Col>
                                                                                            <Slider value={settings.combined_trailing_stop_price_percentage?settings.combined_trailing_stop_price_percentage:0} min={0} max={100} step={0.5} onChange={value => {setSettings({
                                                                                                    ...settings,
                                                                                                    combined_trailing_stop_price_percentage: value
                                                                                                })}} />
                                                                                                <div className="input-group">

                                                                                            <input  type="number"  max={100} step={0.5} value={settings.combined_trailing_stop_price_percentage} onChange={(e) => { 
                                                                                            const value = e.target.value;
                                                                                            let newValue = 100; // Default value
                                                                                            if (value === null || value === '' || value < 0) {
                                                                                                newValue = '';
                                                                                            } else if (value <= 100) {
                                                                                                newValue = value;
                                                                                            }
                                                                                            setSettings({
                                                                                                ...settings,
                                                                                                combined_trailing_stop_price_percentage: newValue
                                                                                            });
                                                                                            } } onBlur={(e) => { 
                                                                                                const value = e.target.value;
                                                                                                let newValue = 100; // Default value
                                                                                                if (value === null || value === '' || value < 0) {
                                                                                                    newValue = '';
                                                                                                } else if (value <= 100) {
                                                                                                    newValue = value;
                                                                                                }
                                                                                                setSettings({
                                                                                                    ...settings,
                                                                                                    combined_trailing_stop_price_percentage: newValue
                                                                                                });
                                                                                                } } name="combined_trailing_stop_price_percentage" className="form-control" />
                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                </div>
                                                                                                  {bestStrikes && settings.combined_trailing_stop_price_percentage && settings.combined_trailing_stop_price_percentage > 0 ? 
                                                                                                    <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined trailing stop value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) - ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_trailing_stop_price_percentage/100))).toFixed(2)}</span></p>
                                                                                                    :null}
                                                                                            </Col>
                                                                                            </Row>

                                                                                            <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label htmlFor="formrow-combined-take-profit" className="mt-3">
                                                                                                Combined Take Profit %    {" "}
                                                                                            <i className="bx bx-info-circle" id ="combined_take_pro_trigger" />
                                                                                                <UncontrolledTooltip placement="top" target="combined_take_pro_trigger">
                                                                                                    {ToolTipData.combined_take_profit_percentage}
                                                                                                </UncontrolledTooltip>
                                                                                                </Label>

                                                                                            </Col>
                                                                                            <Col>
                                                                                            <Slider value={settings.combined_take_profit_percentage?settings.combined_take_profit_percentage:0} min={0} max={100} step={0.5} onChange={value => {setSettings({
                                                                                                    ...settings,
                                                                                                    combined_take_profit_percentage: value
                                                                                                })}} />
                                                                                                <div className="input-group">

                                                                                            <input  type="number"   max={100} step={0.5} value={settings.combined_take_profit_percentage} onChange={(e) => { 
                                                                                            const value = e.target.value;
                                                                                            let newValue = 100; // Default value
                                                                                            if (value === null || value === '' || value < 0) {
                                                                                                newValue = '';
                                                                                            } else if (value <= 100) {
                                                                                                newValue = value;
                                                                                            }
                                                                                            setSettings({
                                                                                                ...settings,
                                                                                                combined_take_profit_percentage: newValue
                                                                                            });
                                                                                            } } onBlur={(e) => { 
                                                                                                const value = e.target.value;
                                                                                                let newValue = 100; // Default value
                                                                                                if (value === null || value === '' || value < 0) {
                                                                                                    newValue = '';
                                                                                                } else if (value <= 100) {
                                                                                                    newValue = value;
                                                                                                }
                                                                                                setSettings({
                                                                                                    ...settings,
                                                                                                    combined_take_profit_percentage: newValue
                                                                                                });
                                                                                                } } name="combined_take_profit_percentage" className="form-control" />
                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                </div>
                                                                                                   {bestStrikes && settings.combined_take_profit_percentage && settings.combined_take_profit_percentage > 0 ? 
                                                                                                    <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined take profit value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))- ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                    :null}
                                                                                            </Col>
                                                                                            </Row>
                                                                                       
                                                                                    </Col>
                                                                                    <Col>
                                                                                        
                                                                                            <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label for="basicpill-namecard-input11">
                                                                                                Waiting time to fill Qty<br />(H : M) <i className="bx bx-info-circle" id ="QTTFQTY" />
                                                                                                <UncontrolledTooltip placement="top" target="QTTFQTY"> 
                                                                                                    {ToolTipData.waiting_time_to_fill_qty}
                                                                                                </UncontrolledTooltip>
                                                                                                </Label>
                                                                                            </Col>
                                                                                            <Col>
                                                                                            <div className="mb-3">
                                                                                            <Flatpickr
                                                                                                className="form-control d-block"
                                                                                                placeholder="Select time" value={settings.Qty_time_clock} onChange={(e) => setSettings({
                                                                                                    ...settings,
                                                                                                    Qty_time_clock: new Date(e).getHours()+':'+new Date(e).getMinutes()
                                                                                                })}
                                                                                                options={{
                                                                                                    enableTime: true,
                                                                                                    noCalendar: true,
                                                                                                    dateFormat: "H:i",
                                                                                                    time_24hr: true
                                                                                                }}
                                                                                                />
                                                                                                </div>         
                                                                                            </Col>
                                                                                            </Row>
                                                                                            <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label for="basicpill-namecard-input11">
                                                                                            Universal stop
                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="USS" />
                                                                                                <UncontrolledTooltip placement="top" target="USS"> 
                                                                                                    {ToolTipData.universal_stop}
                                                                                                </UncontrolledTooltip>
                                                                                            </Col>
                                                                                            <Col  lg="4">
                                                                                            <div className="mb-3">
                                                                                            <Switch
                                                                                                uncheckedIcon={<Offsymbol />}
                                                                                                checkedIcon={<OnSymbol />}
                                                                                                className="me-1 mb-sm-8 mb-2" 
                                                                                                checked= {Boolean(Number(settings.universal_stop))}                                   
                                                                                                onChange={(e) => setSettings({
                                                                                                    ...settings,
                                                                                                    universal_stop: !settings.universal_stop
                                                                                                })}
                                                                                                />
                                                                                                </div>         
                                                                                            </Col>
                                                                                            </Row>
                                                                                            <Row className="mt-3">
                                                                                            <Col  lg="4">
                                                                                            <Label for="basicpill-namecard-input11">
                                                                                            Enable Roll
                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="ERS" />
                                                                                                <UncontrolledTooltip placement="top" target="ERS"> 
                                                                                                    {ToolTipData.roll_enable}
                                                                                                </UncontrolledTooltip>
                                                                                            </Col>
                                                                                            <Col  lg="4">
                                                                                            <div className="mb-3">
                                                                                            <Switch
                                                                                                uncheckedIcon={<Offsymbol />}
                                                                                                checkedIcon={<OnSymbol />}
                                                                                                className="me-1 mb-sm-8 mb-2" 
                                                                                                checked= {Boolean(Number(settings.roll_status))}                                   
                                                                                                onChange={(e) => setSettings({
                                                                                                    ...settings,
                                                                                                    roll_status: !settings.roll_status
                                                                                                })}
                                                                                                />
                                                                                                </div>         
                                                                                            </Col>
                                                                                            </Row>
                                                                                            {settings.roll_status ? 
                                                                                            <>
                                                                                            <Row>
                                                                                                <Col>
                                                                    <Row className="mt-3">
                                                                    <Col  lg="4">
                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                Premium Roll Percentage
                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="PRS" />
                                                                            <UncontrolledTooltip placement="top" target="PRS"> 
                                                                                {ToolTipData.roll_premium_min_percentage}
                                                                            </UncontrolledTooltip>
                                                                            </Col>
                                                                            <Col>
                                                                            <Slider value={settings.roll_percentage?settings.roll_percentage:1} min={1} max={100} onChange={value => setSettings({
                                                                                                ...settings,
                                                                                                roll_percentage: value
                                                                                            })}/>
                                                                            <div className="input-group">

                                                                                <Input
                                                                                    type="number" step="0.5" min="0" max="100"
                                                                                    className="form-control"
                                                                                    id="roll_percentage" value={settings.roll_percentage} onChange={(event) => setSettings({
                                                                                        ...settings,
                                                                                        roll_percentage: event.target.value
                                                                                    })}
                                                                                />
                                                                                <Label className="input-group-text" htmlFor="roll_percentage">%</Label>
                                                                            </div>
                                                                        
                                                                    </Col>
                                                                    </Row>
                                                                    <Row className="mt-3">
                                                                    <Col lg="4">
                                                                       
                                                                            <Label for="basicpill-namecard-input11" className="mb-3">
                                                                                Distance from Index
                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="DFIS" />
                                                                            <UncontrolledTooltip placement="top" target="DFIS"> 
                                                                                {ToolTipData.roll_index_min_distance}
                                                                            </UncontrolledTooltip>
                                                                            </Col>
                                                                            <Col>
                                                                            <div className="input-group">
                                                                            
                                                                                <Input
                                                                                    type="number" step="100" min="0"
                                                                                    className="form-control"
                                                                                    id="roll_index_distance" value={settings.index_distance} onChange={(event) => setSettings({
                                                                                        ...settings,
                                                                                        index_distance: event.target.value
                                                                                    })}
                                                                                />

                                                                            </div>
                                                                            
                                                                    </Col>
                                                                    </Row>
                                                                    
                                                                    <Row className="mt-3">
                                                                    <Col  lg="4">
                                                                    <Label for="basicpill-namecard-input11">
                                                                    When to Roll? (H : M)
                                                                    </Label>{" "}<i className="bx bx-info-circle" id ="WTR" />
                                                                    <UncontrolledTooltip placement="top" target="WTR"> 
                                                                        {ToolTipData.roll_wait_time}
                                                                    </UncontrolledTooltip>
                                                                    </Col>
                                                                    <Col>
                                                                    <div className="mb-3">
                                                                    <Flatpickr
                                                                        className="form-control d-block"
                                                                        placeholder="Select time" value={settings.Roll_time_clock} onChange={(e) => setSettings({
                                                                            ...settings,
                                                                            Roll_time_clock: new Date(e).getHours()+':'+new Date(e).getMinutes()
                                                                        })}
                                                                        options={{
                                                                            enableTime: true,
                                                                            noCalendar: true,
                                                                            dateFormat: "H:i",
                                                                            time_24hr: true
                                                                        }}
                                                                        />
                                                                        </div>         
                                                                    </Col>
                                                                    </Row>
                                                                    </Col>
                                                                    </Row>  </> : '' }
                                                                    <h5 className="mt-5 mb-3 font-size-15">
                                                                                                    Re-entry Settings
                                                                                                        </h5>
                                                                                                        <div className="p-4 border">
                                                                                                          <Row>
                                                                                                      <Col lg="4">
                                                                                                        <FormGroup className="mt-4 mb-0">
                                                                                                          <Label htmlFor="formrow-firstname-Input">
                                                                                                          Enable Re-Entry
                                                                                                          </Label>{" "}
                                                                                                          <i className="bx bx-info-circle" id ="reentry_enabledreentry_enabled" />
                                                                                                            <UncontrolledTooltip placement="top" target="reentry_enabledreentry_enabled">
                                                                                                                {ToolTipData.reentry_enabled}
                                                                                                            </UncontrolledTooltip>
                                                                                                            {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                                                                                              <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                                                                                         </FormGroup>
                                                                                                      </Col>
                                                                                                      <Col md="2">
                                                                                                              <Switch
                                                                                                              uncheckedIcon={<Offsymbol />}
                                                                                                              checkedIcon={<OnSymbol />}
                                                                                                              className=" mt-4 mb-sm-8 mb-0"                                      
                                                                                                              onChange={(e) => setreentry_enabled(e)} 
                                                                                                              checked={reentry_enabled}
                                                                                                            />
                                                                                                      </Col>
                                                                                                      </Row>
                                                                                                      <Row>
                                                                                                          <Col lg="4" className="mb-3">
                                                                                                          {/* <FormGroup className="mt-4 mb-0"> */}
                                                                                                                <Label htmlFor="formrow-firstname-Input">
                                                                                                                Waiting time for Re-Entry (H : M)
                                                                                                                </Label> {" "} 
                                                                                                                
                                                                                                      <i className="bx bx-info-circle" id ="DHINTERVALSTRANGLESwait_time_reentry" />
                                                                                                            <UncontrolledTooltip placement="top" target="DHINTERVALSTRANGLESwait_time_reentry"> 
                                                                                                                {ToolTipData.wait_time_reentry }
                                                                                                            </UncontrolledTooltip> </Col><Col lg="4" className="mb-3">
                                                                                                                <Flatpickr
                                                                                                                  className="form-control d-block"
                                                                                                                  placeholder="Select time"
                                                                                                                  name="wait_time_reentry"
                                                                                                                  onChange={(e) => handleDH(e,'strangleswait_time_reentry')}
                                                                                                                  value={toHoursAndMinutes(strangleswait_time_reentry)} 
                                                                                                                  options={{                                      
                                                                                                                    enableTime: true,
                                                                                                                    noCalendar: true,
                                                                                                                    dateFormat: "H:i",
                                                                                                                    time_24hr: true,
                                                                                                                  }}
                                                                                                                />
                                                                                                            {/* </FormGroup>   */}
                                                                                                          </Col>
                                                                                                          </Row>
                                                                                                          <Row><Col lg="4" className="mb-3">
                                                                                                          {/* <FormGroup className="mt-4 mb-0"> */}
                                                                                                                <Label htmlFor="formrow-firstname-Input">
                                                                                                                Time to Close Orders Before Expiry (H : M)
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="time_to_close_orders_before_expiry1" />
                                                                                                            <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry1"> 
                                                                                                                {ToolTipData.time_to_close_orders_before_expiry }
                                                                                                            </UncontrolledTooltip> </Col>
                                                                                                            <Col lg="4" className="mb-3">
                                                                                                                <Flatpickr
                                                                                                                  className="form-control d-block"
                                                                                                                  placeholder="Select time"
                                                                                                                  name="wait_time_reentry"
                                                                                                                  onChange={(e) => handleDH(e,'time_to_close_orders_before_expiry')}
                                                                                                                  value={toHoursAndMinutes(time_to_close_orders_before_expiry)} 
                                                                                                                  options={{                                      
                                                                                                                    enableTime: true,
                                                                                                                    noCalendar: true,
                                                                                                                    dateFormat: "H:i",
                                                                                                                    time_24hr: true,
                                                                                                                    maxTime: "12:00"
                                                                                                                  }}
                                                                                                                />
                                                                                                            {/* </FormGroup>   */}
                                                                                                          </Col></Row>
                                                                                                          <Row>
                                                                                                          <Col lg="4" className="mb-3">
                                                                                                       
                                                                                                          <Label htmlFor="formrow-firstname-Input">
                                                                                                                 Date to stop reentry
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="stopdate_reentry" />
                                                                                                            <UncontrolledTooltip placement="top" target="stopdate_reentry"> 
                                                                                                                {ToolTipData.stop_date_reentry }
                                                                                                            </UncontrolledTooltip> 
                                                                                                            {/*must select only future date*/   }
                                                                                                            </Col>
                                                                                                            <Col lg="4"> <Flatpickr
                                                                                                                className="form-control d-block"
                                                                                                                placeholder="Select date"
                                                                                                                name="stopdate_reentry"
                                                                                                                onChange={(val) => {
                                                                                                                    const date = new Date(val);
                                                                                                                    const dateOnly = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
                                                                                                                    setSettings({
                                                                                                                        ...settings,
                                                                                                                        stopdate_reentry: dateOnly,
                                                                                                                    });
                                                                                                                }}
                                                                                                                value={settings.stopdate_reentry}
                                                                                                                options={{
                                                                                                                    minDate: tomorrow // Set to current date to disable past dates
                                                                                                                }}
                                                                                                            />

                                                                                                        
                                                                                                          </Col>
                                                                                                          </Row>
                                                                                                          </div>
                                                                 
                                                                                    </Col>

                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    <Row>
                                                                    
                                                                    <Col lg="10">
                                                                    
                                                                    </Col>
                                                                    </Row>

                                                                        
                                                                        
                                                                            
                                                                            
                                                                            
                                                                        
                                                                    </Col>
                                                                </Row>

                                                            </Form>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId={4}>
                                                        <div className="row justify-content-center">
                                                            <Col lg="6">
                                                                <div className="text-center">
                                                                    <div className="mb-4">
                                                                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                    </div>
                                                                    
                                                                    <div>
                                                                        <h5>Bot started successfully</h5>
                                                                        {failedUserDetails.length > 0 ?
                                                                            <p>
                                                                            <Alert className="alert-danger" role="alert">
                                                                                <h4 className="alert-heading">Orders failed for below user(s):</h4>
                                                                                <ul className="list-unstyled fw-medium">                                                                        { failedUserInfoDisplay() }
                                                                                <span className="badge badge-soft-danger badge-pill float-end ms-1 font-size-12">{failedUserDetails.length > 9 ? failedUserDetails.length : '0' + failedUserDetails.length}</span>
                                                                                </ul>
                                                                            </Alert>
                                                                            </p>
                                                                            : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                                {(activeTab == 2 && (!loading.best_strikes_validation && validateStrikes != '')) && <>
                                                    <Alert color="danger" role="alert">
                                                        {validateStrikes}
                                                    </Alert></>}
                                            </div>
                                            <div className="actions clearfix">

                                                <ul>
                                                {   activeTab !== 1 && !(activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation)) ?
                                                    <li
                                                        className={
                                                            activeTab === 1
                                                                ? "previous disabled"
                                                                : "previous"
                                                        }
                                                    >
                                                        <Link
                                                            to="#"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1);
                                                            }}
                                                        >
                                                            Previous
                                                        </Link>
                                                    </li>
                                                        : '' }
                                                    <li
                                                        className={
                                                            activeTab === 4 || (activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation || validateStrikes != '' || !bestStrikes)) || (activeTab == 3 && loading.start_bot)
                                                                ? "next disabled"
                                                                : "next"
                                                        }
                                                    >
                                                       
                                                            {(activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation)) || (activeTab == 3 && loading.start_bot) ?(<Link><span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing</span></Link> ) :
                                                            <Link
                                                            to="#"
                                                            onClick={() => {
                                                                (activeTab === 4 || (activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation || validateStrikes != '' || !bestStrikes)) || (activeTab == 3 && loading.start_bot)) ? '' :
                                                                    toggleTab(activeTab + 1);
                                                            }}
                                                        >
                                                            {activeTab === 3 ? "Start bot" : "Next"}
                                                            </Link>
                                                        }
                                                       
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>




                        </Row>
                    ):  <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                   <Row>
                   <Col xs="12">
                     <div className="text-center my-3">
                     <Alert color="danger" role="alert">
                     <i className="mdi mdi-block-helper me-2"></i>Strangles in disabled status. You can enable it using <a href="/settings">Settings</a>
                      </Alert>
                     </div>
                   </Col>
                 </Row>
                 </CardBody>
                 </Card>
                 </Col>
                 </Row> } </> }
                </Container>
            </div>
            {/*Listing orders */}
            <Modal
        isOpen={visibleMin}
        toggle={() => {
        setVisibleMin(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
        setVisibleTailling(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
           
        </React.Fragment>
    );
}
export default ListOptions;
