import React, { useCallback, useEffect, useState } from "react"

import {
  Badge,
  Card,
  CardBody,    
  Col,
  Container,  
  Label,  
  Row,  
  Table,
  Tooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { formatUTCDate, getCalendarTypeName, getFirstPositionName, formatUTCDateTime, getOrderStatusNameStyle, counterRenderer, getMarketActionTriggerName,timeFormat } from "services/common.service"
import firebase from "firebase/compat/app";

import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import Flatpickr from "react-flatpickr";
import api from '../../../services/api.service';

const CompletedStraddles = () => {
 
  firebase.auth();
  //Current User  
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;

  //meta title
  // document.title="Completed Straddles";
  const firestoreDb = firebase.firestore();
  
  const [ttop1, setttop1] = useState(false);
  //Completed Straddles
  const [completedStraddles, setCompletedStraddles] = useState([]);
  const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 29)).toISOString();
  const defaultEndDate = new Date(new Date()).toISOString();
  const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
  const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const handleCreatedDateChange = (selectedDates, datestr) => {    
    if (selectedDates && selectedDates.length) {
      let converted_date = new Date(convert(selectedDates[0]));
      converted_date.setHours(0, 0, 0, 0);
      setFilterStartDate(converted_date.toISOString()); 
      if(selectedDates[1]){
        let converted_end_date = new Date(convert(selectedDates[1]));
        converted_end_date.setHours(23, 59, 59, 999);
        setFilterEndDate(converted_end_date.toISOString()); 
      }
    } else {
      setFilterStartDate(defaultStartDate); 
      setFilterEndDate(defaultEndDate);       
    }
  }

  // const completedStraddlesResult = firestoreDb.collectionGroup("history").where("strategy", '==', 'straddles').where("admin_bot", '==', true).where("admin_id", '==', uid).where("created_at", '>=', filterStartDate).where("created_at", '<=', filterEndDate).orderBy("created_at", "desc");

  const getCompletedStraddles = async() => { 
    var body =  {
      uid : uid,
      filterStartDate : filterStartDate,
      filterEndDate : filterEndDate
    }
    await api({ contentType: true, auth: true }).post('/abotex/straddles/straddles-history-data',body).then(async(res)  => {
    var completedResult = res.data.response.data;
      const items = [];    
      completedResult.forEach((doc) => {
        let item = doc.data;     
        items.push(item)
      });
      
      const all_straddles = items.reduce(function (row, obj) {
        const index =obj.admin_straddle_id+'_sta';
        const st_index =obj.admin_straddle_id+'_str';
        row[index] = row[index] || [];
        row[index][st_index] = row[index][st_index] || [];
        row[index][st_index].push(obj);
        return row;
      }, []);    
      
      const completed_straddles = Object.keys(all_straddles).map(admin_straddle_index => {         
        return Object.keys(all_straddles[admin_straddle_index]).map(straddle_index => {          
          return all_straddles[admin_straddle_index][straddle_index];
        });
      });
      
      setCompletedStraddles(completed_straddles);	    
    });     
    
  }

  useEffect(() => { 
    let mounted = true;
    if (mounted) {      
      getCompletedStraddles();
    }         
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  },[filterStartDate,filterEndDate]);

  return (
    <React.Fragment>
      <div className="page-content">        
        <Container fluid>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Completed Straddles" />
          <Row>
            <Col lg="12">
              <Card className="border">
                <CardBody>
                  <Row>
                    <Col xxl={1} lg={6} className="d-flex align-items-center">
                      <h5><b>Filter</b></h5>
                    </Col>
                    <Col xxl={2} lg={6}>
                      <Label htmlFor="Created">Created date</Label>
                      <Flatpickr className="form-control d-block"
                        placeholder="Created Date"
                        options={{
                          mode: "range",
                          dateFormat: "d/m/Y",
                          defaultDate: [filterStartDate, filterEndDate]
                        }}
                        onChange={handleCreatedDateChange}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Orders</h4>
                  <div className="table-responsive">
                    {/*********/}
                    {filterStartDate && filterEndDate ? <p className="fw-bold">Showing data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</p>:''}
                    <Table className="table table-bordered table align-middle table-nowrap table-check caption-top-dark">
                        <thead >
                            <tr>
                                <th>Expiry Date</th>
                                <th>User</th>
                                <th>Side</th>
                                <th>Symbol</th>
                                <th>Sold Price</th>
                                <th>Stop Price</th>
                                <th>Filled/Qty</th>
                                <th>Order ID</th>
                                <th>Status</th>                              
                                <th className="text-decoration-underline" >
                                  <Tooltip
                                      placement="top"
                                      isOpen={ttop1}
                                      target="TooltipTop"
                                      toggle={() => {
                                          setttop1(!ttop1);
                                      }}
                                  >
                                      Booked PNL = sell price - buy price
                                  </Tooltip>
                                  <span id="TooltipTop">PNL</span>
                              </th> 
                            </tr>
                        </thead>
                        {completedStraddles.length > 0 ? (
                            completedStraddles.map((users, index) => {
                                return (
                                    <tbody key={index}>
                                    {users.map((row, row_index) => {
                                      return (
                                        row.map((order, index, arr) => {
                                            
                                            return (
                                                <tr className="trow-hover" key={index}>
                                                    {row_index===0 && index === 0 ?
                                                        <td rowSpan={(users.length * row.length)}>
                                                          <div className="p-2 border">
                                                          <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                          <div className="text-primary">
                                                            Expiry:
                                                            <span className="expiry-countdown font-size-11" >
                                                              <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                                            </span>
                                                          </div>
                                                          {order.order_settings.current_index_price ? <div className="font-size-12">Index Price: {Number(order.order_settings.current_index_price).toFixed(2)}</div> : ''}
                                                          {order.order_settings.trigger_percentage ? <div className="font-size-12">Stop Loss Activate: {Number(order.order_settings.trigger_percentage)}%</div> : ''}
                                                          {order.order_settings.stop_loss_percentage ? <div className="font-size-12">Market Stop Loss{order.order_settings.combined_sl?" (Combined)":""}: {Number(order.order_settings.stop_loss_percentage)}%</div> : ''}
                                                          {order.order_settings.stop_loss_index_percentage ? <div className="font-size-12">Index Price Range % for Stop Loss: {Number(order.order_settings.stop_loss_index_percentage)}</div> : ''}
                                                          {order.order_settings.minimum_take_profit_percentage ? <div className="font-size-12">Minimum take profit {order.order_settings.combined_trailing_profit?" (combined)":""}: {Number(order.order_settings.minimum_take_profit_percentage)}%</div> : ''}
                                                          {order.order_settings.trailing_take_profit_percentage ? <div className="font-size-12">Trailing take profit: {Number(order.order_settings.trailing_take_profit_percentage)}%</div> : ''}
                                                          {order.order_settings.reentry_enabled ? (<div className="font-size-12">Waiting time for Re-Entry: {order.order_settings.wait_time_reentry ?timeFormat(order.order_settings.wait_time_reentry) :"--"}</div>): ''} 
                                                          {order.order_settings.combined_sl_percentage ? <div className="font-size-12">Combined stop loss: {Number(order.order_settings.combined_sl_percentage)}%</div> : ''} 
                                                          {order.order_settings.combined_trailing_take_profit_percentage ? <div className="font-size-12">Combined trailing take profit: {Number(order.order_settings.combined_trailing_take_profit_percentage)}%</div> : ''} 
                                                          {order.order_settings.combined_trailing_stop_price_percentage ? <div className="font-size-12">Combined trailing stop price: {Number(order.order_settings.combined_trailing_stop_price_percentage)}%</div> : ''} 
                                                          <div className="text-muted font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.created_at)}</div>
                                                          </div>
                                                        </td>
                                                    : ''}
                                                    {index === 0 ?
                                                    <td className="td-vertical-align" rowSpan={row.length}>
                                                        {order.profile ?
                                                        <p className="font-size-11 text-captialize">{order.profile.first_name} {order.profile.last_name}</p> : <p></p>}
                                                    </td>
                                                    : ''}
                                                    <td>
                                                      <Badge
                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                        style={{ "textTransform": "uppercase" }}
                                                      >
                                                        {order.direction}
                                                      </Badge>
                                                    </td>
                                                    <td>
                                                      <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                        {order.symbol}
                                                      </Badge>
                                                      {order.market_action_trigger ?
                                                        <div>
                                                          <span className="text-muted font-size-10 ">{getMarketActionTriggerName(order)} </span>                                                          
                                                        </div>
                                                        : ""}
                                                    </td>
                                                    <td><h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6></td>
                                                    <td>{order.stop_loss_price ? Number(order.stop_loss_price).toFixed(2) : "..."}</td>
                                                    <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}</td>
                                                    <td>{order.order_id ? order.order_id : "Nil"}</td>
                                                    <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span></td>                                        
                                                    <td title={order.booked_pnl ? order.booked_pnl : order.realized_pnl ? order.realized_pnl : ''}><small>{order.booked_pnl ? parseFloat(order.booked_pnl).toFixed(2) + ' USDT' : order.realized_pnl ? parseFloat(order.realized_pnl).toFixed(2) + ' USDT' : '...'}</small></td>                                                    
                                                </tr>
                                            )
                                        })

                                      )})}
                                    </tbody>
                                )
                            })
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={13} style={{ textAlign: "center" }}>
                                        No orders found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                    {/*********/}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompletedStraddles