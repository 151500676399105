import api from './api_helper';


const UpdateProfile = async(data)=>{
    return new Promise(async(resolve, reject) => {        
    await api({ contentType: true, auth: true }).post('/abotex/profile',data).then(res => {
      let user_details = JSON.parse(localStorage.getItem('user'));
      user_details.profile = res.data.response.user;
      localStorage.setItem("user", JSON.stringify(user_details));
      resolve(['', "Profile Updated Successfully"])
    }).catch(error => {
      reject(['', error.response.data ? error.response.data.message : error.message])
      throw error;
    })
  
  })
  }

  //const replace = (old_details, old_profile, new_profile) => (old_details[old_details.indexOf(old_profile)] = new_profile, old_details)


  export{
    UpdateProfile,
  }