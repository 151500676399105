import api from './api_helper';

// Gets the logged in user data from local session

// Get Expiry Dates
const getStraddleExpiryDates = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/delta/get-expiry-dates', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Get Common strikes
const getStraddleStrikes = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/get-strikes', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Start Bot
const startBot = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/start-bot', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Stop Bot
const stopBot = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/stop-bot', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Market close single order
const marketCloseOrderAPI = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/market-close-order', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Market Close
const marketClose = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/market-close-straddle', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}
const stopReentryAPI = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/stop-reentry', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Close admin bot
const closeAdminBot = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/close-admin-bot', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Send user message
const sendUserMessage = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/user/send-user-msg', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Users List
const getUsersList = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/abotex/straddles/get-users-list').then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}


export {
  getStraddleExpiryDates,
  getStraddleStrikes,
  startBot,
  stopBot,
  marketCloseOrderAPI,
  marketClose,
  closeAdminBot,
  sendUserMessage,
  getUsersList,
  stopReentryAPI
}
